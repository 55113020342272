import React from 'react';
import { PolicyActionContext } from './PolicyContext';
import { IconAdd } from 'components/icons';
import { Button } from 'components/buttons';
import { PoliciesIds } from './utils';

interface PolicyListProps {
	policyList: OliverProductResponseDTO[];
	isSpouse?: boolean;
	onClick: (e: any) => void;
	spouseDependentLife?: boolean;
	cpaDependentLife?: boolean;
}

const PolicyList: React.FC<PolicyListProps> = (props) => {
	const PolicyAction = React.useContext(PolicyActionContext);
	console.log('props', props);
	const policies = props.policyList
		.filter(product =>
			!(props.isSpouse && (PolicyAction.spouseProductTitle(product.id) === 'Long Term Disability' || PolicyAction.spouseProductTitle(product.id) === 'Office Overhead'))
			&& !(props.isSpouse && props.cpaDependentLife && product.id === PoliciesIds.deplife)
			&& !(props.isSpouse === false && props.spouseDependentLife && product.id === PoliciesIds.deplife)
			&& product.id !== 'CICH');
	return (
		<div>
			{policies.map((product, index) => (
				<div className={`p-3 ${index !== 0 ? 'border-top' : ''}`} key={`productitem-${product.id}`}>
					<div className="d-flex justify-content-between align-items-center">
						<h6 className="text-primary pr-md-2 text-wrap">{props.isSpouse ? PolicyAction.spouseProductTitle(product.id) : PolicyAction.productTitle(product.id)}</h6>
						<Button onClick={(e) => {
							e.preventDefault();
							e.key = product.id;
							props.onClick(e);
						}} className="btn-primary" style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
							<IconAdd />
						</Button>
					</div>
				</div>
			))}
		</div>
	);
};

export default PolicyList;
