import * as React from 'react';
import * as GoogleAnalytics from 'react-ga';

GoogleAnalytics.initialize(
	process.env.REACT_APP_ANALYTICS_TRACKING_ID!,
	{ debug: false }
);
// process.env.NODE_ENV === 'development' ? true : false
GoogleAnalytics.plugin.require(process.env.REACT_APP_OPTIMIZE_ID!, null);

const withTracker = (WrappedComponent: any, options = {}) => {
	const trackPage = (page: any) => {
		GoogleAnalytics.set({
			page,
			...options,
		});
		GoogleAnalytics.pageview(page);
	};

	const HOC = class extends React.Component<any> {
		public componentDidMount() {
			const page = this.props.location.pathname;
			trackPage(page);
		}

		public componentWillReceiveProps(nextProps: any) {
			const currentPage = this.props.location.pathname;
			const nextPage = nextProps.location.pathname;

			if (currentPage !== nextPage) {
				trackPage(nextPage);
			}
		}

		public render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};

export default withTracker;