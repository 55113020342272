import React from 'react';
import classNames from 'classnames';
import { ApplyToChildrenToggle } from './ApplyToChildrenToggle';
import { CoverageAmountField } from './CoverageAmountField';
import { CoverageAmountRadio } from './CoverageAmountRadio';
import { Policy, PolicyFields, coverageAmountRadioOptions, waitingPeriodRadioOptions, applyToChildrenRadioOptions, MyPolicy, PoliciesIds, getFormattedValue } from './utils';
import { WaitingPeriodRadio } from './WaitingPeriodRadio';
import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { ReactComponent as IconSubtract } from 'assets/icons/icon-subtract.svg';
import { TogglePolicyButton } from './TogglePolicyButton';
import AnimateHeight from 'react-animate-height';
import { Controller, useFormContext, ValidationValueMessage } from 'react-hook-form';
import { PolicyActionContext, PolicyDataContext } from './PolicyContext';
import { FormContext } from 'quote/FormContext';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { NavRoutes, ValidationMessages } from 'common/utils';
import { LearnMore } from './LearnMore';
import { Link } from 'react-router-dom';

interface IProps {
	product: OliverProductResponseDTO;
	settings: Policy;
	onClick: (myPolicy: MyPolicy) => void;
	selectedPolicies: MyPolicy[];
	index: number;
	debouncedRequest: () => Promise<void>;
	isSpouse?: boolean;
	spouseChildrenCI?: boolean;
	cpaChildrenCI?: boolean;
	loadPolicies?: () => void;
}

export const PolicyCard: React.FC<IProps> = (props: IProps) => {
	const filter = props.selectedPolicies.filter((val) =>
		val.policyId === props.product.id &&
		val.policyId !== PoliciesIds.cich);
	const PolicyAction = React.useContext(PolicyActionContext);
	const PolicyData = React.useContext(PolicyDataContext);
	const [warning, setWarning] = React.useState<string | undefined>(undefined);
	const [readMore, setReadMore] = React.useState<boolean>(false);
	const isSelected = filter.length === 1;
	const currentItem = filter[0];
	const methods = useFormContext();
	const { control, errors } = methods;

	const FormCtx = React.useContext(FormContext);
	const { settings } = props;
	const { requirements } = settings;
	// const displayIndex = () => {
	// 	let returnVal = (props.index + 1).toString();
	// 	if (props.index < 10) {
	// 		returnVal = '0' + returnVal;
	// 	}
	// 	return returnVal;
	// };

	React.useEffect(() => {
		if (warning !== undefined) {
			setWarning(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedPolicies]);

	const onChangeField = () => {
		if (PolicyData.isDirty === false && props.isSpouse === false) {
			PolicyAction.setIsDirty(true);
		}
		if (PolicyData.spouseIsDirty === false && props.isSpouse === true) {
			PolicyAction.setSpouseIsDirty(true);
		}
		props.debouncedRequest();
		props.loadPolicies?.();
	};

	const getMax = (): ValidationValueMessage<React.ReactText> | undefined => {
		if (requirements.spouseMax === undefined && requirements.cpaMax === undefined && requirements.max === undefined) {
			return undefined;
		}
		if (currentItem.policyId === PoliciesIds.add) {
			const userIsSpouse = FormCtx.personalData.cpaStatus === 'spouse' ? true : props.isSpouse;
			const values = methods.getValues();
			const lifeVal = values.policy?.['LIFE']?.coverageAmount;
			const max = userIsSpouse ? requirements.spouseMax : requirements.cpaMax;
			if (lifeVal && lifeVal <= max!) {
				return {
					value: lifeVal,
					message: 'The maximum coverage cannot exceed the Life Term Benefit amount'
				} as ValidationValueMessage<React.ReactText>;
			}
			return userIsSpouse ?
				{
					value: requirements.spouseMax,
					message: `The maximum coverage amount for the Spouse of a CPA is $${getFormattedValue(requirements?.spouseMax)}`
				} as ValidationValueMessage<React.ReactText> :
				{
					value: requirements.cpaMax,
					message: `The maximum coverage amount is $${getFormattedValue(requirements?.cpaMax)}`
				} as ValidationValueMessage<React.ReactText>;
		}
		return {
			value: requirements.max,
			message: `The maximum coverage amount is $${getFormattedValue(requirements?.max)}`
		} as ValidationValueMessage<React.ReactText>;
	};

	const title = props.isSpouse ? PolicyAction.spouseProductTitle(props.product.id) : PolicyAction.productTitle(props.product.id);
	const isTermLife = title === 'Term Life';
	const isADD = title === 'Accidental Death and Dismemberment';
	const isDisablity = title === 'Long Term Disability';

	const coverageAmountField = props.isSpouse ? `spousePolicy[${props.settings.policyId}].coverageAmount` : `policy[${props.settings.policyId}].coverageAmount`;
	const waitingPeriodField = props.isSpouse ? `spousePolicy[${props.settings.policyId}].waitingPeriod` : `policy[${props.settings.policyId}].waitingPeriod`;
	const applyToChildrenField = props.isSpouse ? `spousePolicy[${props.settings.policyId}].applyToChildren` : `policy[${props.settings.policyId}].applyToChildren`;
	const policyErrors = props.isSpouse ? errors?.spousePolicy : errors?.policy;

	const showRequirements = (): boolean => {
		if(isDisablity){
			return false;
		}

		if (isADD) { // Ignoring Accidental Death & Dismemberment for now because of conditional maxes
			return true;
		}

		const { min, max, spouseMax, cpaMax, multiplesOf, ...displayedReqs } = requirements;
		if (Object.keys(displayedReqs).length > 0) {
			return true;
		}

		return false;
	};

	if (!isSelected) {
		return null;
	}

	return (
		<div className="border-bottom p-3">
			<div className="m-2">
				<div className="row mb-3">
					<div className="col d-flex flex-column justify-content-center">
						<h4 className="mb-0">{title}</h4>
					</div>
					<div className="col-auto d-flex flex-column align-items-stretch">
						<TogglePolicyButton isSelected={isSelected} onClick={validateToggle} />
					</div>
				</div>
				<ErrorMessage className="my-3">{warning}</ErrorMessage>
				<AnimateHeight duration={350} height={isSelected ? 'auto' : 0}>
					{isSelected && (
						<React.Fragment>
							<div className="row">
								<div className={classNames(showRequirements() ? 'col-lg-7' : 'col-lg-9')}>
									{settings.fields.includes(PolicyFields.coverageAmountField) &&
										<Controller
											name={coverageAmountField}
											control={control}
											defaultValue={currentItem.parameters?.coverageAmount || ''}
											rules={{
												required: ValidationMessages.required,
												//min: requirements.min && { value: requirements.min, message: `The minimum coverage amount is $${getFormattedValue(requirements.min)}` },
												max: getMax(),
												validate: value => requirements.multiplesOf ?
													value % requirements.multiplesOf === 0 || `Must be multiples of $${getFormattedValue(requirements.multiplesOf)}` : undefined,
											}}
											render={(controllerprops) =>
												<React.Fragment>
													<CoverageAmountField
														value={controllerprops.value}
														onChange={(val) => { controllerprops.onChange(val); onChangeField(); }}
														error={policyErrors?.[props.settings.policyId]?.coverageAmount?.message}
														description={!isADD ?
															`Enter multiples of $${getFormattedValue(requirements.multiplesOf)} up to $${getFormattedValue(Number(getMax()?.value))}.` : undefined
														}
													/>
												</React.Fragment>
											}
										/>
									}
									{isTermLife && !props.isSpouse &&
										<div className="mb-3">
											<p className="mb-3">Need help calculating the amount?</p>
											<Link to={NavRoutes.QuoteNeedsAssessmentStep1} className="btn btn-sm btn-outline-primary py-2">Take the Needs Assessment</Link>
										</div>
									}
									{settings.fields.includes(PolicyFields.coverageAmountRadio) &&
										<Controller
											name={coverageAmountField}
											control={control}
											defaultValue={currentItem.parameters?.coverageAmount || ''}
											rules={{
												required: ValidationMessages.required,
											}}
											render={(controllerprops) =>
												<CoverageAmountRadio
													value={controllerprops.value}
													onChange={(val) => { controllerprops.onChange(val); onChangeField(); }}
													options={coverageAmountRadioOptions}
													error={policyErrors?.[props.settings.policyId]?.coverageAmount?.message}
												/>
											}
										/>
									}

									{settings.fields.includes(PolicyFields.waitingPeriod) && <Controller
										name={waitingPeriodField}
										control={control}
										defaultValue={currentItem.parameters?.waitingPeriod || waitingPeriodRadioOptions[4].value}
										rules={{
											required: ValidationMessages.required,
										}}
										render={(controllerprops) =>
											<WaitingPeriodRadio
												options={waitingPeriodRadioOptions}
												value={controllerprops.value}
												onChange={(val) => { controllerprops.onChange(val); onChangeField(); }}
												error={policyErrors?.[props.settings.policyId]?.waitingPeriod?.message}
											/>
										}
									/>}
									{settings.fields.includes(PolicyFields.applyToChildren)
										&& ((props.isSpouse && !props.cpaChildrenCI)	// if is spouse and cpa does not have children selected for CI
											|| (!props.isSpouse && !props.spouseChildrenCI))	// or if not spouse and spouse has children selected for CI
										&&
										<Controller
											name={applyToChildrenField}
											control={control}
											defaultValue={currentItem.parameters?.applyToChildren || ''}
											rules={{
												required: ValidationMessages.required,
											}}
											render={(controllerprops) =>
												<ApplyToChildrenToggle
													options={applyToChildrenRadioOptions}
													value={controllerprops.value}
													onChange={(val) => { controllerprops.onChange(val); onChangeField(); }}
													error={policyErrors?.[props.settings.policyId]?.applyToChildren?.message}
												/>
											}
										/>
									}
								</div>
								{showRequirements() && (
									<div className="col-lg-5 order-first order-md-last mb-3 mb-md-0">
										<p className="mb-1 font-weight-bold">Requirements:</p>
										<ul className="bullet-dash pl-3">
											{requirements.min !== undefined && requirements.min > 0 && isADD && // Ignoring Accidental Death & Dismemberment for now because of conditional maxes
												<li className="mb-1">Enter multiples of ${getFormattedValue(requirements.multiplesOf)}</li>
											}
											{requirements.max && isADD &&
												<li className="mb-1">CPA Max: ${getFormattedValue(requirements.max)}</li>
											}
											{requirements.cpaMax && isADD &&
												<li className="mb-1">CPA Max: ${getFormattedValue(requirements.cpaMax)}</li>
											}
											{requirements.spouseMax && isADD &&
												<li className="mb-1">Non-CPA Max: ${getFormattedValue(requirements.spouseMax)}</li>
											}
											{/* {requirements.multiplesOf && isADD &&
												<li className="mb-1">Multiples of: ${getFormattedValue(requirements.multiplesOf)}</li>
											} */}
											{requirements.requiresLTD &&
												<li className="mb-1">Requires Long Term Disability Benefit</li>
											}
											{requirements.equalOrLowerThanTLB &&
												<li className="mb-1">Must be lower or equal to Term Life Benefit</li>
											}
											{props.settings.policyId === PoliciesIds.deplife &&
												<li className="mb-1">
													Dependent Child must be above the age of 14 days old and under the age of 23 or under 25 years of age (unmarried and financially dependent)
													and attending school full-time or is suffering from a functional impairment.
												</li>
											}
											{props.settings.policyId === PoliciesIds.deplife &&
												<li className="mb-1">Flat amount chosen will apply to all children.</li>
											}
										</ul>
									</div>
								)}
							</div>
						</React.Fragment>
					)}
				</AnimateHeight>
				<AnimateHeight duration={350} height={readMore ? 'auto' : 0}>
					<button className="btn btn-link ml-n3 learn-more align-self-start mb-3" onClick={() => setReadMore(false)}>Learn More<IconSubtract /></button>
					<LearnMore product={settings.policyId} />
				</AnimateHeight>
				{readMore ? (
					<button className="btn btn-link ml-n3 learn-more align-self-start" onClick={() => setReadMore(false)}>Learn More<IconSubtract /></button>
				) : (
					<button className="btn btn-link ml-n3 learn-more align-self-start" onClick={() => setReadMore(true)}>Learn More<IconAdd /></button>
				)}
			</div>
		</div>
	);
	function validateToggle() {
		if (props.product.id === PoliciesIds.officeOH && isSelected === false) {
			const getLTDProduct = props.selectedPolicies.filter((val) => val.policyId === PoliciesIds.ltd);
			const ltdIsActive = getLTDProduct.length === 1;
			if (!ltdIsActive) {
				setWarning('Must add Long Term Disability first.');
				return;
			}
		}

		if (props.product.id === PoliciesIds.ltd && isSelected === true) {
			const getOfficeOHProduct = props.selectedPolicies.filter((val) => val.policyId === PoliciesIds.officeOH);
			const officeOHIsActive = getOfficeOHProduct.length === 1;
			if (officeOHIsActive) {
				setWarning('Must remove Office Overhead first.');
				return;
			}
		}

		if (props.product.id === PoliciesIds.add && isSelected === false) {
			const getLifeProduct = props.selectedPolicies.filter((val) => val.policyId === PoliciesIds.life);
			const lifeIsActive = getLifeProduct.length === 1;
			if (!lifeIsActive) {
				setWarning('Must add Life Term first.');
				return;
			}
		}

		if (props.product.id === PoliciesIds.life && isSelected === true) {
			const getAddProduct = props.selectedPolicies.filter((val) => val.policyId === PoliciesIds.add);
			const addIsActive = getAddProduct.length === 1;
			if (addIsActive) {
				setWarning('Must remove Accidental Death and Dismemberment first.');
				return;
			}
		}

		props.onClick({ policyId: settings.policyId });
		props.debouncedRequest();
		props.loadPolicies?.();
	}
};
