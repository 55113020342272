import * as React from 'react';

import { Page } from 'components/common';

class FourOhOnePage extends React.Component<{}, {}> {
	public render() {
		return (
			<Page title="401">
				<section className="features-1">
					<h1 className="display-1 text-secondary">401</h1>
					<h2 className="text-secondary">Unauthorized</h2>
					<p className="mt-4">Sorry, you do not have access.</p>
				</section>
			</Page>
		);
	}
}

export default FourOhOnePage;
