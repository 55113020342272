import React from 'react';
import { FormContext } from 'quote/FormContext';
import { IUnit, Units, UnitsTypeInput } from './UnitsTypeInput';

interface IProps {
	name: string;
	id?: string;
	value: string; // always metric (cm)
	onChange: (value: string) => void; // always sends in metric (cm) value
}

export const HeightInput: React.FC<IProps> = (props: IProps) => {
	const formCxt = React.useContext(FormContext);
	const { heightUnit: unit, setHeightUnit: setUnit } = formCxt;
	const [displayValue, setDisplayValue] = React.useState(props.value);
	const [displayValueFtIn, setDisplayValueFtIn] = React.useState<{ft: string, in: string}>({ft: '', in: ''});


	React.useEffect(() => {
		if (unit === Units.ftin) {
			setDisplayValue(convertToFT(displayValue));
		} else {
			// double check to see if display value isn't in cm yet
			if ( props.value !== displayValue ) {
				const newValue = convertToCM(displayValue);
				setDisplayValue(newValue);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unit]);

	React.useEffect(() => {
		if (displayValueFtIn.ft !== '' && displayValueFtIn.in !== '') {
			const valueFt = parseInt(displayValueFtIn.ft);
			const valueIn = parseInt(displayValueFtIn.in);
			if ( isNaN(valueFt) || isNaN(valueIn) ) {
				props.onChange('NaN');
			} else {
				handleOnChange(`${valueFt}'${valueIn}"`);
			}
		} else {
			const newValue = convertToCM(displayValue);
			setDisplayValue(newValue);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayValueFtIn]);

	const units = [
		{
			label: Units.ftin,
			value: Units.ftin
		},
		{
			label: Units.cm,
			value: Units.cm
		}
	] as IUnit[];

	const handleOnChange = (value: string) => {
		setDisplayValue(value);
		var height = value;
		if (unit === Units.ftin) {
			height = convertToCM(height);
		}

		props.onChange(height);
	};

	const convertToFT = (value: string) => {
		if(value.length <= 0) { return value; };
		var cm = Number(value);

		if(isNaN(cm)) { return value; };
		var cmInInches = cm / 2.54;
		var feet = Math.floor(cm / 30.48);
		var inches = (cmInInches - (feet * 12)).toFixed(0);
		setDisplayValueFtIn({ft: `${feet}`, in: `${inches}`});
		return `${feet}'${inches}"`;
	};

	const convertToCM = (value: string) => {
		if(value.length <= 0) { return value; }

		var height = value.trim();

		var ftinSplit = null;
		var footPart = null;
		var inchPart = null;
		if (height.includes("'") && height.includes('"')) {
			ftinSplit = height.split("'");
			footPart = ftinSplit[0].trim();
			inchPart = ftinSplit[1].replace('"', '').trim();
		} else if (height.includes('ft') && height.includes('in')) {
			ftinSplit = height.split('ft');
			footPart = ftinSplit[0].trim();
			inchPart = ftinSplit[1].replace('in', '').trim();
		} else if (height.includes(' ') && height.length > 2) {
			ftinSplit = height.split(' ');
			footPart = ftinSplit[0].trim();
			inchPart = ftinSplit[1].trim();
		} else { return value; }

		var feet = Number(footPart);
		var inches = Number(inchPart);

		if(isNaN(feet) || isNaN(inches)) { return value; };

		var feetInCM = (feet * 30.48);
		var inchesInCM = inches * 2.54;
		var total = feetInCM + inchesInCM;

		return total.toFixed(0);
	};

	const updateUnits = (newUnit: Units) => {
		setUnit(newUnit);

		if (newUnit === Units.ftin) {
			setDisplayValue(convertToFT(displayValue));
		} else {
			var newValue = convertToCM(displayValue);
			setDisplayValue(newValue);
			props.onChange(newValue);
		}
	};

	return (
		<React.Fragment>
			{unit === Units.ftin && (
				<div className="d-flex flex-row mb-1">
					<div className="input-group mr-1">
						<input
							className="form-control"
							name={`${props.name}-ft`}
							value={displayValueFtIn.ft}
							onChange={(e) =>
								setDisplayValueFtIn({
									...displayValueFtIn,
									ft: e.target.value
								})
							}
						/>
						<div className="input-group-append">
							<span className="input-group-text">ft</span>
						</div>
					</div>
					<div className="input-group ml-1">
						<input
							className="form-control"
							name={`${props.name}-in`}
							value={displayValueFtIn.in}
							onChange={(e) =>
								setDisplayValueFtIn({
									...displayValueFtIn,
									in: e.target.value
								})
							}
						/>
						<div className="input-group-append">
							<span className="input-group-text">in</span>
						</div>
					</div>
				</div>
			)}
			<UnitsTypeInput
				name={props.name}
				units={units}
				id={props.id}
				value={displayValue}
				selectedUnit={unit}
				onChange={handleOnChange}
				onUnitChange={updateUnits}
			/>
		</React.Fragment>
	);
};

