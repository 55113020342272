import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const oliverAPI = {
	getOliverProducts: async () => {
		return axios.get(`${apiUrl}/anon/oliver/products`)
			.then((response) => {
				return response.data as OliverProductResponseDTO[];
			});
	},
	getQuote: async (request: QuoteRequest) => {
		return axios.post(`${apiUrl}/anon/oliver/quote`, request)
			.then((response) => {
				return response.data as OliverQuoteResponseDTO;
			});
	},
	postApplicant: async (request: ApplicantRequest) => {
		return axios.post(`${apiUrl}/anon/oliver/applicant`, request)
			.then((response) => {
				return response.data as ApplicantDTO;
			});
	},
	postApplication: async (request: ApplicationRequest) => {
		return axios.post(`${apiUrl}/anon/oliver/application`, request)
			.then((response) => {
				return response.data as string;
			});
	},
	getSignatureRequest: (request: SignatureLinkRequest) => {
		return axios.get(`${apiUrl}/anon/oliver/signlink?code=${request.code}`)
			.then(response => response.data as SignatureDTO);
	},
	postSignerInformation: (request: SignatureRequest) => {
		return axios.post(`${apiUrl}/anon/oliver/signature`, request)
			.then(response => response.data);
	},
	postReport: async (request: ReportRequest | ReportWithSpouseRequest) => {
		return axios.post(`${apiUrl}/anon/oliver/report`, request)
			.then((response) => {
				return response.data as boolean;
			});
	},
	sendApplyEvent: async (request: ReportWithSpouseRequest) => {
		return axios.post(`${apiUrl}/anon/oliver/apply`, request)
			.then((response) => {
				return response.data as boolean;
			});
	},
	postExportApplicants: async (request: any) => {
		return axios.get(`${apiUrl}/anon/oliver/exportapplicants`, request)
			.then((response) => {
				return response.data as boolean;
			});
	},
	postAddTagToContact: async (request: {tagName: string, email: string}) => {
		return axios.post(`${apiUrl}/anon/oliver/addtagtocontact`, request)
			.then((response) => {
				return response.data as boolean;
			});
	},
	postAddNeedsAssessmentToContact: async (request: ACNeedsAssessmentRequest) => {
		return axios.post(`${apiUrl}/anon/oliver/addneedsassessmenttocontact`, request)
			.then((response) => {
				return response.data as boolean;
			});
	}
};

export default oliverAPI;