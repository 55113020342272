import React from 'react';
import { QuoteContainer } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { NavRoutes } from 'common/utils';
import { Button } from 'antd';
import oliverAPI from 'api/OliverAPI';
import { FormContext } from 'quote/FormContext';

type buttonType = 'personal' | 'group' | 'home';
interface IProps {
}

const SelectInsuranceType: React.FC<IProps> = (props: IProps) => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);

	const handleClick = (type: buttonType) => {
		if (type === 'personal') {
			oliverAPI.postAddTagToContact({tagName: 'API Tag for Quoter', email: FormCtx.initialUserData?.email || ''});
			history.push(NavRoutes.QuoteYourDetails);
		} else if (type === 'group') {
			oliverAPI.postAddTagToContact({tagName: 'Group Benefits Lead', email: FormCtx.initialUserData?.email || ''});
			window.open('https://www.cpaipw.ca/firm-products/', '_blank');
		} else if (type === 'home') {
			oliverAPI.postAddTagToContact({tagName: 'TPIC Lead', email: FormCtx.initialUserData?.email || ''});
			window.open('https://www.cpaipw.ca/home-insurance/', '_blank');
		}
	};

	return (
		<QuoteContainer>
			<h1>What type of quote are you looking for?</h1>

			<div className="insurance-type p-3 mb-3 mt-2">
				<h4>Personal Insurance</h4>
				<p>Term Life Insurance, Long Term Disability, Critical Illness, Accidental Death & Dismemberment, Dependent Life, or Office Overhead for you and your spouse.</p>
				<Button className="btn-primary mr-2" onClick={() => handleClick('personal')}>Select</Button>
			</div>

			<div className="insurance-type p-3 mb-3">
				<h4>Group Employee Benefits for CPA Firms</h4>
				<p>Group Benefits offerings including Extended Healthcare & Dental, Term Life, Long Term Disability and Critical Illness.</p>
				<Button className="btn-primary mr-2" onClick={() => handleClick('group')}>Select</Button>
			</div>

			<div className="insurance-type p-3">
				<h4>Home and Auto Insurance</h4>
				<p>See more information on our Home & Auto Insurance offerings.</p>
				<Button className="btn-primary mr-2" onClick={() => handleClick('home')}>Select</Button>
			</div>
		</QuoteContainer>
	);

};

export default SelectInsuranceType;
