import { randomString, ValidationMessages } from 'common/utils';
import { Button } from 'components/buttons';
import { Divider } from 'components/cpaipw';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BeneficiaryDataContext } from '../BeneficiaryContext';
import { ICorporation } from '../BeneficiaryTypes';
import { IBeneficiariesProps } from './beneficiariesProps';

export const Corporations: React.FC<IBeneficiariesProps> = ( props: IBeneficiariesProps ) => {
	const [corporationIds, setCorporationIds] = React.useState<string[]>([randomString(7)]);
	const methods = useFormContext();
	const beneficaryDataConext = React.useContext(BeneficiaryDataContext);
	const { corporations } = beneficaryDataConext.beneficiaryData;

	const { control, errors } = methods;

	React.useEffect(() => {
		if(corporations !== undefined && corporations.length > 0) {
			const newIds = corporations.map((corporation: ICorporation) => {
				return corporation.id || randomString(7);
			});
			setCorporationIds(newIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="m-3">
			{corporationIds.map((id: string, index: number) => {
				const defaultCorporation = corporations?.find((c: ICorporation) => c.id === id);
				return (
					<React.Fragment key={`corporation-${id}-${index}`}>
						<div className="form-group">
							<label htmlFor={`corporations.${id}.firstName`}>Name of Corporation</label>
							<Controller
								name={`corporations.${id}.nameOfCorporation`}
								control={control}
								rules={{
									required: ValidationMessages.required,
								}}
								defaultValue={defaultCorporation !== undefined ? defaultCorporation.name : ''}
								render={({ onChange, value }) =>
									<input
										name={`corporations.${id}.nameOfCorporation`}
										onChange={onChange}
										value={value}
										className="form-control"
									/>
								}
							/>
							<ErrorMessage>{errors?.corporations?.[id]?.nameOfCorporation?.message}</ErrorMessage>
						</div>
						{((index + 1) <= corporationIds.length && corporationIds.length > 1) && (
							<React.Fragment>
								<Button className="btn-outline-primary mb-2" onClick={() => handleToggle(id)}>Remove Corporation -</Button>
								<Divider className="my-3" />
							</React.Fragment>
						)}
						{((index + 1) === corporationIds.length || corporationIds.length === 1) && (
							<Button className="btn-primary mb-3" onClick={() => handleToggle(undefined)}>Add Corporation + </Button>
						)}
					</React.Fragment>
				);
			})}
		</div>

	);
	function handleToggle( id?: string ) {
		// console.log(id);
		const oldState = [...corporationIds];
		if ( id === undefined ) {
			oldState.push(randomString(7));
			setCorporationIds(oldState);
		} else {
			const newState = oldState.filter((v: string) => v !== id);
			setCorporationIds(newState);
		}
	}
};
