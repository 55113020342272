import React from 'react';

export interface IChild {
	id: string,
	relationship?: string,
	firstName?: string,
	middleInitial?: string,
	lastName?: string,
	dateOfBirth?: string,
	gender?: string,
	height?: string,
	weight?: string,
	weightOneYearAgo?: string,
	weightChangeReason?: string
}

export interface IEmployer {
	name: string;
	address: string;
	city: string;
	province: string;
	postalCode: string;
};

export interface IAboutYouApplication {
	applicationId?: string,
	applicantId?: string,
	quoteId?: string,
	firstName?: string,
	lastName?: string,
	cpaNumber?: string,
	email?: string,
	address?: string,
	city?: string,
	province?: string,
	postalCode?: string,
	homePhone?: string,
	birthCountry?: string,
	birthProvinceOrState?: string,
	height?: string,
	weight?: string,
	weightOneYearAgo?: string,
	weightChangeReason?: string,
	occupation?: string,
	isWorking?: string,
	reasonForNotWorking?: string,
	workPhone?: string,
	workedHours?: string,
	employer?: IEmployer,
	children?: IChild[]
}

interface IApplicationDataContext {
	applicationData: IAboutYouApplication;
	getAccordionUsers: () => AccordionUser[];
}

interface IApplicationActionContext {
	setApplicationData: React.Dispatch<React.SetStateAction<IAboutYouApplication>>;
	extractChildData: (vals: any) => void;
}

const ApplicationDataContext = React.createContext<IApplicationDataContext>({
	applicationData: {},
	getAccordionUsers: () => [],

});

const ApplicationActionContext = React.createContext<IApplicationActionContext>({
	setApplicationData: () => null,
	extractChildData: () => null
});


export type AccordionUser = {
	displayName: string;
	userId: string;
};

const ApplicationProvider = (props: any) => {
	const [applicationData, setApplicationData] = React.useState<IAboutYouApplication>({});

	const getAccordionUsers = () => {
		const userArray: AccordionUser[] = [];
		userArray.push({
			userId: 'Applicant',
			displayName: `${applicationData.firstName} ${applicationData.lastName}`,
		});
		const children = applicationData.children !== undefined && applicationData.children.map((child: IChild) => {
			return {
				userId: child.id,
				displayName: `${child.firstName} ${child.lastName}`
			} as AccordionUser;
		});
		if (Array.isArray(children) && children.length > 0) {
			return userArray.concat(children);
		}
		return userArray;
	};

	const extractChildData = (vals: any) => {
		if (vals === null || vals === undefined) {
			return [] as IChild[];
		}
		const children = Object.keys(vals);
		const contents = children.map((child: any, index: number) => {
			const newChild: IChild = {
				...vals?.[child],
				id: children[index],
			};
			return newChild;
		});
		const data = applicationData;
		data.children = contents;
		setApplicationData(data);
	};

	return (
		<ApplicationDataContext.Provider value={{
			applicationData,
			getAccordionUsers,
		}}>
			<ApplicationActionContext.Provider value={{
				setApplicationData,
				extractChildData
			}}>
				{props.children}
			</ApplicationActionContext.Provider>
		</ApplicationDataContext.Provider>
	);
};

export {
	ApplicationActionContext,
	ApplicationDataContext,
};

export default ApplicationProvider;