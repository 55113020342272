import { ValidationMessages } from 'common/utils';
import { IChild } from './AboutYouQuestions/ApplicationContext';
import { LifestyleConsumptionQuestionWithUserName, LifestyleSingleQuestion, LifestyleQuestionWithUserName } from './LifestyleQuestions/LifestyleTypes';

export function lifestyleValidation(val: string[], notApplicable: boolean) {
	if (notApplicable === false) {
		if (val.length === 0) {
			return ValidationMessages.required;
		}
	}
}

export const provinces = [
	{
		label: 'Alberta',
		value: 'alberta'
	},
	{
		label: 'British Columbia',
		value: 'british columbia'
	},
	{
		label: 'Manitoba',
		value: 'manitoba'
	},
	{
		label: 'New Brunswick',
		value: 'new brunswick'
	},
	{
		label: 'Newfoundland and Labrador',
		value: 'Newfoundland and Labrador'
	},
	{
		label: 'Northwest Territories',
		value: 'northwest territories'
	},
	{
		label: 'Nova Scotia',
		value: 'Nova Scotia'
	},
	{
		label: 'Northwest Territories',
		value: 'northwest territories'
	},
	{
		label: 'Nunavut',
		value: 'Nunavut'
	},
	{
		label: 'Ontario',
		value: 'Ontario'
	},
	{
		label: 'Prince Edward Island',
		value: 'prince edward island'
	},
	{
		label: 'Quebec',
		value: 'quebec'
	},
	{
		label: 'Saskatchewan',
		value: 'saskatchewan'
	},
	{
		label: 'Yukon',
		value: 'yukon'
	}
];

export function extractSection6LifestyleData(vals: any, children: IChild[]) {
	let queriedLifestyleData = {};
	if (vals === null || vals === undefined) {
		return queriedLifestyleData;
	}

	if (vals.notApplicable) {
		return queriedLifestyleData;
	}

	const section6Questions = Object.keys(vals).filter(v => v !== 'notApplicable');
	section6Questions.forEach(question => {
		const questionDataList = [] as LifestyleConsumptionQuestionWithUserName[];
		const userQuestionDataKeys = Object.keys(vals[question]);
		userQuestionDataKeys.forEach(user => {
			let data = {} as LifestyleConsumptionQuestionWithUserName;
			if (user !== 'Applicant') {
				// data.idOfApplicantOrChildren = getChildrenDetails(user, children);
			} else {
				data.idOfApplicantOrChildren = user;
			}

			const keys = Object.keys(vals[question][user]);

			if (keys.length > 1) {
				keys.forEach(key => {
					if (key === 'numberGramsPerWeek') {
						data.consumptionAmount = vals[question][user][key];
					}
				});
			} else {
				data.consumptionAmount = vals[question][user][keys[0]];
			}

			questionDataList.push(data);
			(queriedLifestyleData as any)[question] = questionDataList;
		});
		// console.log('keys', queriedLifestyleData);
	});

	return queriedLifestyleData;
}


export function extractLifestyleData(vals: any, section: string, children: IChild[]) {
	let queriedLifestyleData = [] as LifestyleQuestionWithUserName[];
	if (vals === null || vals === undefined) {
		return queriedLifestyleData;
	}

	if (vals.notApplicable) {
		return queriedLifestyleData;
	}

	const userLifeStyleData = Object.keys(vals[section]);

	userLifeStyleData.forEach(key => {
		const data = {
			idOfApplicantOrChildren: key,
			data: vals[section][key],
		} as LifestyleQuestionWithUserName;
		queriedLifestyleData.push(data);
	});

	return queriedLifestyleData;
}

export function extractSimpleLifestyleData(vals: any, section: string, children: IChild[]) {
	let queriedLifestyleData = [] as LifestyleSingleQuestion[];

	if (vals === null || vals === undefined) {
		return queriedLifestyleData;
	}

	if (vals.notApplicable) {
		return queriedLifestyleData;
	}

	const lifestyleData = vals[section].filter((key: any) => key !== '' && key !== undefined);
	const userLifeStyleData = lifestyleData.map((sec: any) => {

		let data = {
			suspended: true,
			idOfApplicantOrChildren: sec,
		} as LifestyleSingleQuestion;

		if (sec !== 'Applicant') {
			const nameField = getChildrenDetails(sec, children);
			data.name = nameField!;
		}

		return data;

	});

	return userLifeStyleData;
}

export const getChildrenDetails = (id: string, childrenData?: IChild[]) => {
	if ( childrenData === undefined ) { return null; }

	const foundChildData = childrenData.find(child => child.id === id) as IChild;
	if (foundChildData !== undefined) {
		return foundChildData.firstName;
	} else {
		return '';
	}

};