import * as React from 'react';
import { Tooltip } from 'antd';

// tslint:disable-next-line
const className = require('classnames');

interface IProps {
	onClick?: (value?: {}) => void;
	icon?: {};
	children?: {};
	link?: string;
	title?: string;
	className?: string;
	style?: {};
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
}

const IconButton: React.FC<IProps> = (props) => {
	const Button = (
		<button
			style={props.style ? props.style : {}}
			type={props.type || 'button'}
			className={className(props.className, 'btn btn-icon btn-link btn-pd-fix')}
			disabled={props.disabled}
			onClick={props.onClick}
		>
			{props.icon}
			{props.children}
		</button>
	);

	if (props.title) {
		return (
			<Tooltip title={props.title}>
				{Button}
			</Tooltip>
		);
	}

	return Button;
};

export default IconButton;
