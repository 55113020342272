/* eslint-disable max-len */
import React from 'react';
import { PoliciesIds } from './utils';

interface IProps {
	product: PoliciesIds;
}

export const LearnMore: React.FC<IProps> = (props: IProps) => {

	if (props.product === PoliciesIds.life) {
		return (
			<React.Fragment>
				<h6>Why Term Life insurance?</h6>
				<p>We at CPAIPW love Term Life and consider it a must have coverage for everyone.</p>
				<ul className="bullet-dash">
					<li>Term Life is the simplest way to <b><i>make sure your important people have some much-needed financial support</i></b> if you’re not around anymore.</li>
					<li>It gives you the ability to <b><i>meet those inheritance and education fund goals</i></b> you set for your dependents if your time is cut short.</li>
					<li>If you need one of those <b><i>buy/sell agreements</i></b>, this is what the bank and your partners are looking for.</li>
					<li>Best of all, it can <b><i>save you money</i></b> by replacing that over-priced mortgage insurance (we will never get over the fact that the price of mortgage insurance never goes down despite the coverage reducing).  </li>
				</ul>
				<h6>How much coverage do you need?</h6>
				<p>There is no <i>right</i> answer. The goal is to have enough coverage to meet your current and future financial goals and obligations if something happens to you. Here are some of the variables to consider:</p>
				<ul className="bullet-dash">
					<li><b>Add:</b> Mortgage balance &amp; other debt(s) + income replacement for your loved ones + inflation-adjusted education expense + inheritance + amounts owing to business partners &amp; other business debts</li>
					<li><b>Subtract:</b> Your savings + amount of existing life insurance</li>
					<li><b>Equals:</b> A pretty good estimate of the coverage you will need </li>
				</ul>
			</React.Fragment>
		);
	}

	if (props.product === PoliciesIds.ltd) {
		return (
			<React.Fragment>
				<h6>Why Long Term Disability insurance?</h6>
				<p>Along with Term Life, Long Term Disability (LTD) is another one of those must have coverages.</p>
				<p>
					We wish for a happy &amp; healthy life for everyone, but accidents do happen, you may become disabled and unable to work.
					If this does happen to you, we want to make sure you still have income, so you can focus on getting healthy and feeling great again!
				</p>
				<p><strong>How to calculate your maximum LTD limit:</strong></p>
				<p>Your limit is the lower of $10,000 or 60% of the first $11,668 of gross monthly earnings and 50% of the balance.</p>
				<p><strong>Example 1) Your gross monthly earnings are above $10,000 limit:</strong></p>
				<p>Gross monthly earnings: $20,000.00</p>
				<p>
					<span className="d-block">First $11,668 x 60% =  $7,000.80</span>
					<span className="d-block">Next $8,332 x 50% =  $4,166.00</span>
					<span className="d-block">Maximum limit: $10,000 ($11,166.80)</span>
				</p>

				<p><strong>Example 2) Your gross monthly earnings below are $10,000 limit:</strong></p>

				<p>Gross monthly earnings: $12,000.00</p>
				<p>
					<span className="d-block">First $11,668 x 60% =  $7,000.80</span>
					<span className="d-block">Next $332.00 x 50% =  $166.00</span>
					<span className="d-block">Total limit: $7,166.80</span>
				</p>

				<p><strong>How to determine your gross monthly earnings:</strong></p>
				<p>Your gross monthly earnings is equal to the amounts paid as fees, salary, bonus, commission or honorarium (excluding investment income) in the previous 12 months or 24 months, whichever is greater.</p>

				<p><strong>What qualifies as gross monthly earnings:</strong></p>
				<ul className="bullet-dash">
					<li>(a) A sole proprietor’s or partner’s share net income of a CPA Firm for the immediate preceding fiscal year of the firm;</li>
					<li>(b) Share of your Professional Corporation’s share of proprietorship/partnership net income after deducting expenses but before any distribution of earnings for the immediately preceding fiscal year;</li>
					<li>(c) Employment income from a business not owned by you for the previous calendar year;</li>
					<li>(d) Share of net income from an incorporated or unincorporated business owned wholly or partially by you prior to any income distribution to yourself for the immediately proceeding fiscal year; plus</li>
					<li>(e) Any other suitable “earnings” component that the board of directors of the Policyholder may deem appropriate from time to time.</li>
				</ul>
			</React.Fragment>
		);
	}

	if (props.product === PoliciesIds.ci) {
		return (
			<React.Fragment>
				<h6>Why Critical Illness insurance?</h6>
				<p>
					Critical Illness provides you with immediate financial relief with a tax free lump-sum payment upon diagnosis of one of the covered illness.
					This covers things like Alzheimer’s Disease, cancer (restrictions apply), major surgeries.
				</p>
				<p>
					Select, Child Critical Illness to ensure you also have coverage for a tax free lump-sum payment should your dependent child be diagnosed with one of the covered illnesses.
				</p>
				<p>For a complete list of covered illnesses, contact us.</p>
			</React.Fragment>
		);
	}

	if (props.product === PoliciesIds.add) {
		return (
			<React.Fragment>
				<h6>Why Accidental Death &amp; Dismemberment Insurance?</h6>
				<p>
					Accidental Death and Dismemberment (AD&amp;D) is a perfect complement to Term Life.
					It is added protection for you and your family in the event of an accident that results in death or serious injury.
					It coverages things like loss of life, eye sight, speech, hearings, and limbs, etc.
				</p>
				<p>For the complete Accidental Loss and Loss of Use Schedule, speak with an Advisor at 1-800-661-6430.</p>
			</React.Fragment>
		);
	}

	if (props.product === PoliciesIds.deplife) {
		return (
			<React.Fragment>
				<h6>Why Dependent Life insurance?</h6>
				<p>
					We get it, no one wants to think about the loss of a child, but for few bucks a month,
					it ensures funeral and other expenses can be covered in the case of the death of your child.
				</p>
			</React.Fragment>
		);
	}

	if (props.product === PoliciesIds.officeOH) {
		return (
			<React.Fragment>
				<h6>Why Office Overhead insurance?</h6>
				<p>
					As a sole proprietor or partner, being healthy and performing at your best is critical for your ability to learn a living.
					If you become disabled, covering expenses will be top of mind. This is where office overhead can help by providing coverage for rent,
					salaries, utilities, memberships, and insurance, business taxes, and other normal business expenses.
				</p>
			</React.Fragment>
		);
	}

	return <React.Fragment />;
};