import React from 'react';
import { ApplicationPages, NavRoutes, preventDefault } from 'common/utils';
import { CheckboxButton, Divider, FormButtons, QuestionContainer, QuoteContainer, Steps } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { FormContext } from 'quote/FormContext';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { HealthAccordion } from 'components/cpaipw/HealthAccordion';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { Accordion } from 'components/cpaipw/Accordion';
import { HealthActionContext, HealthDataContext } from './HealthContext';
import { IHealthQuestionWithUserName, ISymptomQuestions } from './HealthTypes';
import { ApplicationDataContext } from 'application/AboutYouQuestions/ApplicationContext';


const HealthApplicationStep2: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);

	const healthActionContext = React.useContext(HealthActionContext);
	const healthDataContext = React.useContext(HealthDataContext);
	const appDataContext = React.useContext(ApplicationDataContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.health);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const healthData = healthDataContext.healthQuestionaireData?.section2Questions;

	const healthDataKeys = healthData && Object.keys(healthData);

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			notApplicable: healthDataKeys !== undefined && healthDataKeys?.length === 0 ? true : false
		}
	});
	const { control, watch, setError, errors } = methods;

	const watchNa = watch(['notApplicable']);

	const watchAll = watch();
	const formHasFields = Object.keys(watchAll).filter(v => v !== 'notApplicable');

	const defaultselectedUsers: any = {};
	if (healthDataKeys !== undefined) {
		healthDataKeys?.forEach((key: string) => {
			const data = healthData?.[key as keyof ISymptomQuestions];
			const users = data?.map(item => {
				return item.idOfApplicantOrChildren;
			});
			defaultselectedUsers[key] = users;
		});
	}

	const onHandleSubmit = (val: any) => {
		const keys = Object.keys(val);
		if (keys.includes('notApplicable') && keys.length === 1 && val['notApplicable'] === false) {
			setError('notApplicable', { message: 'If none of the situations are applicable, please select "Not Applicable"' });
			return;
		}

		const section2Questions = val.section2Questions !== undefined ? val.section2Questions : {};
		const contentKeys = Object.keys(section2Questions);
		const section2Data: ISymptomQuestions = {};
		contentKeys.forEach(key => {
			const data = healthActionContext.extractHealthData(val.section2Questions[key], appDataContext.applicationData.children) as IHealthQuestionWithUserName[];
			section2Data[key as keyof ISymptomQuestions] = data;
		});

		healthActionContext.setHealthQuestionaireData({ ...healthDataContext.healthQuestionaireData, section2Questions: section2Data });
		history.push(NavRoutes.ApplicationHealthStep3);
	};

	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<h1>Health</h1>
				<Steps currentstep={2} totalSteps={5} />
				<form onSubmit={preventDefault}>
					<p>
						Has the proposed insured had or do they currently have discomfort, signs or symptoms for which:
					</p>
					<div className="flex-column flex-fill">
						<QuestionContainer index={1}>
							<div className="form-group">
								<label htmlFor="notYetConsultedDoctor">
									<b>They have not yet consulted a doctor ?</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.notYetConsultedDoctor}>
									<HealthAccordion
										name="section2Questions.notYetConsultedDoctor"
										section="section2"

									/>
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={2}>
							<div className="form-group">
								<label htmlFor="waitingToSeeSpecialist">
									<b>They are waiting to see a specialist?</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.waitingToSeeSpecialist}>
									<HealthAccordion
										name="section2Questions.waitingToSeeSpecialist"
										section="section2"
									/>
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={3}>
							<div className="form-group">
								<label htmlFor="question2-3">
									<b>
										They have consulted a doctor or other health professional and been advised to take medication,
										or undergo tests or surgery that has yet to happen or for which they are currently awaiting results?
									</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.advisedMedsOrSurgery}>
									<HealthAccordion
										name="section2Questions.advisedMedsOrSurgery"
										section="section2"
									/>
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={4}>
							<Controller
								name="notApplicable"
								control={control}
								defaultValue={false}
								render={({ onChange, value }) =>
									<CheckboxButton
										name="notApplicable"
										isActive={value}
										disabled={formHasFields.length > 0}
										label="Not Applicable"
										onClick={() => onChange(!value)}
									/>
								}
							/>
							<ErrorMessage>{errors?.notApplicable?.message}</ErrorMessage>
						</QuestionContainer>
					</div>
					<FormButtons
						onClickBack={() => history.push(NavRoutes.ApplicationHealthStep1)}
						onClickNext={methods.handleSubmit(onHandleSubmit)}
					/>
				</form>
			</QuoteContainer>
		</FormProvider>
	);
};

export default HealthApplicationStep2;