import { IHistoryData, IllnessDTO } from 'application/HistoryQuestions/HistoryTypes';
import classNames from 'classnames';
import { randomString } from 'common/utils';
import Button from 'components/buttons/Button';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxGroup, CheckboxOption } from './CheckboxGroup';
import { Divider } from './Divider';
import { ErrorMessage } from './ErrorMessage';

interface IProps {
	user?: string;
	historyData: IHistoryData[];
}

const IllnessContainer: React.FC<IProps> = (props: IProps) => {
	const historyData = props.historyData.find(history => history.user === props.user);
	const members = historyData !== undefined ? historyData.illness.map(ill => ill.familyMember) : [];
	const illnesses = historyData !== undefined && historyData.illness.length > 0 ? historyData.illness : [];
	const [selectedFamilyMembers, setSelectedFamilyMembers] = React.useState<string[]>(members);

	const user = props.user || '';

	const familyOptions = [
		{
			label: 'Father',
			value: 'Father',
		}, {
			label: 'Mother',
			value: 'Mother'
		}, {
			label: 'Brother',
			value: 'Brother'
		}, {
			label: 'Sister',
			value: 'Sister'
		}
	] as CheckboxOption[];
	return (
		<div className="illness-container m-3">
			<CheckboxGroup
				name="illnessGroup"
				onChange={(value: string[]) => setSelectedFamilyMembers(value)}
				options={familyOptions}
				value={selectedFamilyMembers}
			/>
			{familyOptions.map((option, index: number) => {
				return selectedFamilyMembers.includes(option.value) ?
					<IllnessGroup
						memberName={option.label}
						currentlySelectedUser={user}
						key={`${option.label}-${user}-${index}`}
						illnessData={illnesses}
					/> :
					<React.Fragment />;
			})}
		</div>);
};

interface IllnessGroupProps {
	memberName: string;
	currentlySelectedUser: string;
	illnessData: IllnessDTO[];
}

const IllnessGroup: React.FC<IllnessGroupProps> = (props: IllnessGroupProps) => {
	const illnessIndexFromProps = props.illnessData.length > 0 ? props.illnessData.map(ill => ill.id) : [];
	if (illnessIndexFromProps.length === 0) {
		illnessIndexFromProps.push(randomString(9));
	}
	const [illnessIndex, setIllnessIndex] = React.useState<string[]>([...illnessIndexFromProps]);

	return (
		<React.Fragment>
			{
				illnessIndex.map((item, index) => {
					const foundIllness = props.illnessData.find(ill => ill.id === item);
					return (<IllnessForm
						currentlySelectedUser={props.currentlySelectedUser}
						illnessData={foundIllness}
						key={item}
						memberName={props.memberName}
						lastItem={illnessIndex.length === index + 1}
						index={item}
						updatedIllnessData={updateIllnessData}
					/>);
				})
			}
		</React.Fragment>
	);

	function updateIllnessData(index: string | undefined) {
		const illnessIndexData = [...illnessIndex];
		if (index !== undefined) {
			const updatedStuff = illnessIndexData.filter((data: string) => data !== index);
			setIllnessIndex(updatedStuff);
		} else {
			illnessIndexData.push(randomString(9));
			setIllnessIndex(illnessIndexData);
		}
	}

};

interface IllnessFormProps {
	lastItem: boolean;
	currentlySelectedUser: string;
	illnessData?: IllnessDTO;
	index: string;
	memberName: string;
	updatedIllnessData: (index: string | undefined) => void;
}

const IllnessForm: React.FC<IllnessFormProps> = (props: IllnessFormProps) => {
	const methods = useFormContext();
	const { control, errors } = methods;

	return (
		<React.Fragment>
			<Divider className="mt-3 mb-2" />
			<div className="expendable-form-group">
				<h4>{props.memberName}</h4>
				<div className="form-group">
					<label className="font-weight-bold" htmlFor={`${props.memberName}.nameOfIllness`}>Name of Illness (If cancer, indicate type): </label>
					<Controller
						name={`${props.currentlySelectedUser}.${props.memberName}.${props.index}.nameOfIllness`}
						control={control}
						defaultValue={props.illnessData !== undefined ? props.illnessData.nameOfIllness : ''}
						rules={{
							required: 'Field is required',
						}}
						render={({ onChange, value }) =>
							<input className="form-control" onChange={onChange} value={value} id={`${props.memberName}.nameOfIllness`} />
						}
					/>
					<ErrorMessage>{errors?.[props.currentlySelectedUser]?.[props.memberName]?.[props.index]?.nameOfIllness?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label className="font-weight-bold" htmlFor={`${props.memberName}.ageAtOnsetIllness`}>Age at onset of the illness</label>
					<Controller
						name={`${props.currentlySelectedUser}.${props.memberName}.${props.index}.ageAtOnsetIllness`}
						control={control}
						defaultValue={props.illnessData !== undefined ? props.illnessData.ageAtOnsetIllness : ''}
						rules={{
							required: 'Field is required',
						}}
						render={({ onChange, value }) =>
							<input className="form-control" onChange={onChange} value={value} id={`${props.memberName}.ageAtOnsetIllness`} />
						}
					/>
					<ErrorMessage>{errors?.[props.currentlySelectedUser]?.[props.memberName]?.[props.index]?.ageAtOnsetIllness?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label className="font-weight-bold" htmlFor={`${props.memberName}.ageIfAlive`}>Age if alive</label>
					<Controller
						name={`${props.currentlySelectedUser}.${props.memberName}.${props.index}.ageIfAlive`}
						control={control}
						defaultValue={props.illnessData !== undefined ? props.illnessData.ageIfAlive : ''}
						render={({ onChange, value }) =>
							<input className="form-control" onChange={onChange} value={value} id={`${props.memberName}.ageIfAlive`} />
						}
					/>
					<ErrorMessage>{errors?.[props.currentlySelectedUser]?.[props.memberName]?.[props.index]?.ageIfAlive?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label className="font-weight-bold" htmlFor={`${props.memberName}.ageAtDeath`}>Age at death</label>
					<Controller
						name={`${props.currentlySelectedUser}.${props.memberName}.${props.index}.ageAtDeath`}
						control={control}
						defaultValue={props.illnessData !== undefined ? props.illnessData.ageAtDeath : ''}
						render={({ onChange, value }) =>
							<input className="form-control" onChange={onChange} value={value} id={`${props.memberName}.ageAtDeath`} />
						}
					/>
					<ErrorMessage>{errors?.[props.currentlySelectedUser]?.[props.memberName]?.[props.index]?.ageAtDeath?.message}</ErrorMessage>
				</div>
			</div>
			<Button
				className={classNames(props.lastItem ? 'btn-primary' : 'btn-outline-primary')}
				onClick={handleButtonClick}
			>
				{props.lastItem ? 'Add Illness +' : 'Remove Illness -'}
			</Button>
		</React.Fragment>
	);

	function handleButtonClick() {
		if (props.lastItem) {
			return props.updatedIllnessData(undefined);
		} else {
			return props.updatedIllnessData(props.index);
		}
	}
};
export default IllnessContainer;
