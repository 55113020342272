import React from 'react';
import { DayMonthYear, IHealthAbnormalities, IHealthQuestionWithUserName, ILast5Years, ISymptomQuestions } from 'application/HealthQuestions/HealthTypes';
import { ValidationMessages } from 'common/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { DayMonthYearInput } from './DayMonthYearInput';
import { ErrorMessage } from './ErrorMessage';
import { HealthDataContext } from 'application/HealthQuestions/HealthContext';

interface IProps {
	name: string
	user?: string;
	section: string;
}

export const HealthAccordion: React.FC<IProps> = (props: IProps) => {
	const methods = useFormContext();
	const healthDataContext = React.useContext(HealthDataContext);

	const { control, errors } = methods;
	const user = props.user || '';
	const { healthQuestionaireData } = healthDataContext;

	const fieldNameHasDot = props.name.includes('.');
	const fieldName = fieldNameHasDot ? props.name.split('.') : props.name;
	const errorList = fieldNameHasDot ? errors?.[fieldName[0]]?.[fieldName[1]]?.[user] : errors?.[props.name]?.[user];

	let foundHealthData = undefined;
	if (props.section === 'section1') {
		if (healthQuestionaireData?.section1Questions !== undefined) {
			foundHealthData = healthQuestionaireData?.section1Questions?.find(data => data.idOfApplicantOrChildren === user);
		}
	} else if (props.section === 'section2') {
		// console.log('fieldname=', fieldName[1]);
		if (healthQuestionaireData?.section2Questions !== undefined) {
			const findData = healthQuestionaireData?.section2Questions?.[fieldName[1] as keyof ISymptomQuestions];
			foundHealthData = findData !== undefined ? findData.find((data: IHealthQuestionWithUserName) => data.idOfApplicantOrChildren === user) : undefined;
		}
	} else if (props.section === 'section3') {
		if (healthQuestionaireData?.section3Questions !== undefined) {
			const findData = healthQuestionaireData?.section3Questions?.[fieldName[1] as keyof ILast5Years];
			foundHealthData = findData !== undefined ? findData.find((data: IHealthQuestionWithUserName) => data.idOfApplicantOrChildren === user) : undefined;
		}
	} else if (props.section === 'section4') {
		if (healthQuestionaireData?.section4Questions !== undefined) {
			foundHealthData = healthQuestionaireData?.section4Questions?.find(data => data.idOfApplicantOrChildren === user);
		}
	} else if (props.section === 'section5') {
		if (healthQuestionaireData?.section5Questions !== undefined) {
			const findData = healthQuestionaireData?.section5Questions?.[fieldName[1] as keyof IHealthAbnormalities];
			foundHealthData = findData !== undefined ? findData.find((data: IHealthQuestionWithUserName) => data.idOfApplicantOrChildren === user) : undefined;
		}
	};

	return (
		<div className="px-3 pt-2 expendable-form-group">
			<div className="form-group">
				<label className="font-weight-bold" htmlFor={`${props.name}.${user}.natureOfIllness`}>
					Nature of Illnesses, surgery, accidents, consultations, examinations, treatments, medication, results
				</label>
				<Controller
					name={`${props.name}.${user}.natureOfIllness`}
					control={control}
					defaultValue={foundHealthData?.healthQuestionsOfApplicantOrChildren.natureOfIllness !== undefined ? foundHealthData?.healthQuestionsOfApplicantOrChildren.natureOfIllness : ''}
					rules={{
						required: ValidationMessages.required,
					}}
					render={({ onChange, value }) =>
						<input className="form-control" onChange={onChange} value={value} id={`${props.name}.${user}.natureOfIllness`} />
					}
				/>
				<ErrorMessage>{errorList?.natureOfIllness?.message}</ErrorMessage>
			</div>
			<div className="form-group">
				<label className="font-weight-bold" htmlFor={`${props.name}.${user}.date`}>Date</label>
				<Controller
					name={`${props.name}.${user}.dateOfIllness`}
					control={control}
					defaultValue={foundHealthData?.healthQuestionsOfApplicantOrChildren.dateOfIllness !== undefined ? foundHealthData?.healthQuestionsOfApplicantOrChildren.dateOfIllness : ''}
					rules={{
						required: ValidationMessages.required,
					}}
					render={({ onChange, value }) =>
						<input className="form-control" onChange={onChange} value={value} id={`${props.name}.${user}.dateOfIllness`} />
					}
				/>
				<ErrorMessage>{errorList?.dateOfIllness?.message}</ErrorMessage>
			</div>
			<div className="form-group">
				<label className="font-weight-bold" htmlFor={`${props.name}.${user}.lengthOfIllness`}>Length of Illness/Disability</label>
				<Controller
					name={`${props.name}.${user}.lengthOfIllness`}
					control={control}
					defaultValue={foundHealthData?.healthQuestionsOfApplicantOrChildren.lengthOfIllness !== undefined ?
						{
							unit: foundHealthData?.healthQuestionsOfApplicantOrChildren.lengthOfIllnessMeasurement,
							value: foundHealthData?.healthQuestionsOfApplicantOrChildren.lengthOfIllness
						} : ''}
					rules={{
						required: ValidationMessages.required,
						validate: {
							validateRequired: (value) => hasValue(value) || ValidationMessages.required,
							validateUnitData: (value) => hasUnit(value) || ValidationMessages.unit,
						},
					}}
					render={({ onChange, value }) => <DayMonthYearInput name={`${props.name}.${user}.lengthOfIllness`} value={value} onChange={onChange} />}
				/>
				<ErrorMessage>{errorList?.lengthOfIllness?.message}</ErrorMessage>
			</div>
			<div className="form-group">
				<label className="font-weight-bold" htmlFor={`${props.name}.${user}.lengthOfHospitalization`}>Length of Hospitalization (If Applicable)</label>
				<Controller
					name={`${props.name}.${user}.lengthOfHospitalization`}
					control={control}
					defaultValue={foundHealthData?.healthQuestionsOfApplicantOrChildren.lengthOfHospitalization !== undefined ?
						{
							unit: foundHealthData?.healthQuestionsOfApplicantOrChildren.lengthOfHospitalizationMeasurement,
							value: foundHealthData?.healthQuestionsOfApplicantOrChildren.lengthOfHospitalization,
						} : ''
					}
					render={({ onChange, value }) =>
						<DayMonthYearInput name={`${props.name}.${user}.lengthOfHospitalization`} value={value} onChange={onChange} />
					}
				/>
			</div>
			<div className="form-group">
				<label className="font-weight-bold" htmlFor={`${props.name}.${user}.nameAndAddressOfPhysiciansOrHospitals`}>Name and Addresses of Physicians or Hospitals</label>
				<Controller
					name={`${props.name}.${user}.nameAndAddressOfPhysiciansOrHospitals`}
					control={control}
					defaultValue={foundHealthData?.healthQuestionsOfApplicantOrChildren.nameAndAddressOfPhysiciansOrHospitals !== undefined ?
						foundHealthData?.healthQuestionsOfApplicantOrChildren.nameAndAddressOfPhysiciansOrHospitals : ''}
					rules={{
						required: ValidationMessages.required,
					}}
					render={({ onChange, value }) =>
						<input className="form-control" onChange={onChange} value={value} id={`${props.name}.${user}.nameAndAddressOfPhysiciansOrHospitals`} />
					}
				/>
				<ErrorMessage>{errorList?.nameAndAddressOfPhysiciansOrHospitals?.message}</ErrorMessage>
			</div>
		</div>
	);

	function hasValue(val: string | DayMonthYear) {
		if (typeof val === 'object' && val !== null) {
			return val.value !== '';
		} else {
			return val !== '';
		};
	}

	function hasUnit(val: string | DayMonthYear) {
		if (typeof val === 'object' && val !== null) {
			return val.unit !== '';
		}

		if (typeof val === 'string') {
			return false;
		}
	}
};
