import React from 'react';
import { FormContext } from 'quote/FormContext';
import { FormButtons, InfoBox, NeedsAssessmentSidebarCard, QuoteContainer, Steps } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { NavRoutes, QuotePages } from 'common/utils';
import { ReactComponent as IconExpenses } from 'assets/icons/icon-expenses.svg';

interface IProps {}

const NeedsAssessmentStep4: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);
	const history = useHistory();

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<QuoteContainer sidebar={<NeedsAssessmentSidebarCard />}>
			<IconExpenses className="mb-3" />
			<h3>Foreseeable Expenses</h3>
			<Steps currentstep={2} totalSteps={3} />
			<p>
				Foreseeable Expenses are predicted based on your payment history and lifestyle. Foreseeable expenses can be divided into two categories:
			</p>
			<InfoBox title="Annual Basic Expenses (Excluding Debt Payments)">
				These expenses can be described as total costs to be incurred on an annual basis. <i>Some key examples include grocery bills,
				Wi-Fi &amp; internet, cellphone bills, utility payments, transportation payments, extracurriculars, and vacations.</i>
			</InfoBox>
			<InfoBox title="Financial Goals" className="mb-4">
				These expenses are often related to your stage of life and reflect your financial goals.
				<i>These expenses might be post-secondary tuition for a child or spouse, paying for a child’s wedding, and other
				costs related to your lifestyle. (i) this can also include funeral costs.</i>
			</InfoBox>
			<FormButtons
				onClickBack={() => history.push(NavRoutes.QuoteNeedsAssessmentStep3)}
				onClickNext={() => history.push(NavRoutes.QuoteNeedsAssessmentStep5)}
			/>
			<div className="d-block d-lg-none">
				<NeedsAssessmentSidebarCard />
			</div>
		</QuoteContainer>
	);
};
export default NeedsAssessmentStep4;
