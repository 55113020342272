import * as React from 'react';
import { Link } from 'react-router-dom';
import { Spin, Icon } from 'antd';
import classNames from 'classnames';

interface IProps {
	onClick?: (e?: any) => any;
	children?: {};
	to?: string | {};
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	className?: string;
	tabIndex?: number;
	loading?: boolean;
	style?: {};
}

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin={true} />;

const Button: React.FC<IProps> = (props: IProps): JSX.Element => {
	const { to, onClick, className, type, disabled, children, tabIndex, loading, ...otherProps } = props;

	if (to && !disabled) {
		return (
			<Link
				onClick={onClick}
				to={to}
				tabIndex={tabIndex}
				className={classNames('btn', className, loading && 'btn-loading')}
				{...otherProps}
			>
				<span className="btn-content">{children}</span>
				{loading && <Spin indicator={antIcon}/>}
			</Link>
		);
	}

	return (
		<button
			onClick={onClick}
			className={classNames('btn', className, loading && 'btn-loading', disabled && 'disabled')}
			type={type}
			disabled={disabled || loading}
			tabIndex={tabIndex}
			{...otherProps}
		>
			{loading && <Spin indicator={antIcon} className="ant-btn-spin" />}
			<span className="btn-content">{children}</span>
		</button>
	);
};

export default Button;
