import classNames from 'classnames';
import React from 'react';

interface IProps {
	index: number;
	children: {};
}

export const QuestionContainer: React.FC<IProps> = ( props: IProps ) => {
	const displayIndex = () => {
		let returnVal = props.index.toString();
		if (props.index < 10) {
			returnVal = '0' + returnVal;
		}
		return returnVal;
	};
	return (
		<div className="question-container position-relative">
			<div className="question-side position-absolute">
				<div className={classNames('question-index', props.index !== 1 && 'not-first')}>
					{displayIndex()}
				</div>
				<div className="policy-index-line" />
			</div>
			<div className="flex-column flex-fill">
				{props.children}
			</div>
		</div>
	);
};
