import React from 'react';
import { FormContext } from 'quote/FormContext';
import { ApplicationPages } from 'common/utils';
import { Page } from 'components/common';
import { ReactComponent as ThumbsUp } from 'assets/img/thumbs-up.svg';

interface IProps {

}

const ApplicationNextSteps: React.FC<IProps> = () => {
	const FormCtx = React.useContext(FormContext);
	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.review);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page>
			<div className="container">
				<div className="d-flex align-items-center mb-5">
					<ThumbsUp style={{ width: 152, height: 155, fill: 'none' }} />
					<div className="ml-4">
						<h2>Woo-hoo! You’re almost there!</h2>
						<p>Please check your mailbox, you will have received a link to your application to sign.</p>
						<p>You are safe to close this browser window.</p>
					</div>
				</div>
				<h4>Next Steps</h4>
				<div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3">
					<div className="col mb-3 mb-md-4">
						<div className="card card-body h-100 p-md-4 shadow">
							<div className="d-md-flex align-items-center justify-content-start">
								<div className="d-block mr-3">
									<div className="badge-round d-flex flex-fill bg-primary-light text-primary mb-3">01</div>
								</div>
								<h5 className="card-title d-inline-block mb-2 mb-md-3">Provide a digital signature</h5>
							</div>
							<p className="card-text">Provide a digital signature now by clicking through the link you can find in your mailbox.</p>
						</div>
					</div>
					<div className="col mb-3 mb-md-4">
						<div className="card card-body h-100 p-md-4 shadow">
							<div className="d-md-flex align-items-center justify-content-start">
								<div className="d-block mr-3">
									<div className="badge-round d-flex flex-fill bg-primary-light text-primary mb-3">02</div>
								</div>
								<h5 className="card-title d-inline-block mb-2 mb-md-3">Have your witness provide a digital signature</h5>
							</div>
							<p className="card-text">
								Your witness also has received an email with a request to sign the application.
							</p>
						</div>
					</div>
					<div className="col mb-3 mb-md-4">
						<div className="card card-body h-100 p-md-4 shadow">
							<div className="d-md-flex align-items-center justify-content-start">
								<div className="d-block mr-3">
									<div className="badge-round d-flex flex-fill bg-primary-light text-primary mb-3">03</div>
								</div>
								<h5 className="card-title d-inline-block mb-2 mb-md-3">Your application will be reviewed.</h5>
							</div>
							<p className="card-text">
								Your application will reviewed by a member of our team and we'll contact you within the next two business days.
							</p>
						</div>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default ApplicationNextSteps;