import classNames from 'classnames';
import { removeItem } from 'common/utils';
import * as React from 'react';
import { CheckboxButton } from './CheckboxButton';

export interface CheckboxOption {
	label: string;
	value: string;
}

interface IProps {
	name: string;
	options: CheckboxOption[];
	onChange: (value: string[]) => void;
	className?: string;
	value: string[];
	disabled?: boolean;
}

export const CheckboxGroup: React.FC<IProps> = (props: IProps) => {
	const onChange = (val: string) => props.onChange(handleSelect(val));
	return (
		<React.Fragment>
			<div
				className={classNames(props.className)}
				data-toggle="buttons"
			>
				{props.options.map((option, key) => {
					return (
						<CheckboxButton
							key={`${props.name}-${key}`}
							name={props.name}
							label={option.label}
							value={option.value}
							isActive={props.value && props.value.includes(option.value)}
							onClick={() => onChange(option.value)}
							disabled={props.disabled}
						/>
					);
				})}
			</div>
		</React.Fragment>
	);

	function handleSelect(val: string) {
		if ( props.value === null || props.value === undefined || props.value.length === 0 ) {
			return [val];
		}
		if ( props.value.includes(val) ) {
			const newValues = [...props.value];
			if ( props.value.length === 1) {
				return [];
			}
			return removeItem(newValues, val);
		} else {
			return [...props.value, val];
		}
	}

};