import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IconError } from 'assets/icons/icon-error.svg';

interface IProps {
	children?: string;
	className?: string;
}

export const ErrorMessage: React.FC<IProps> = ( props: IProps ) => {
	if ( !props.children ) {
		return <React.Fragment />;
	}
	return (
		<div className={classNames('alert alert-danger d-flex mt-2', props.className)}>
			<IconError className="text-danger mr-2 icon-error" />
			<p className="mb-0">
				{props.children}
			</p>
		</div>
	);
};