import classNames from 'classnames';
import { FormContext } from 'quote/FormContext';
import { getNearest10k } from 'quote/policy/utils';
import React from 'react';

interface IProps {}

export const NeedsAssessmentSidebarCard: React.FC<IProps> = ( props: IProps ) => {
	const FormCtx = React.useContext(FormContext);
	const currentStep = FormCtx.needsAssessment.step || 0;
	const calculations = FormCtx.calculateTotal();
	const total = getNearest10k(calculations.total);
	const recommendation = ( total > 2000000 ) ? 2000000 : ( total <= 0 ) ? 10000 : total;

	return (
		<div className="shadow-sm rounded p-3 mb-lg-4">
			<div className="d-flex flex-column">
				<h5 className="text-primary">Coverage Amount</h5>
				<div className="d-flex justify-content-between">
					<span className="font-weight-bold mr-2">
						Debts
					</span>
					<span className={classNames('text-right')}>
						{currentStep <= 2 ? (
							'Calculated after step 2'
						) : (
							'$'+calculations.debts.toLocaleString('en')
						)}
					</span>
				</div>
				<div className="d-flex justify-content-between">
					<span className="font-weight-bold mr-2">
						Expenses
					</span>
					<span className={classNames('text-right', currentStep < 3 && 'text-gray-600')}>
						{currentStep <= 3 ? (
							'Calculated after step 2'
						) : (
							'$'+(calculations.expenses + calculations.income).toLocaleString('en')
						)}
					</span>
				</div>
				<div className="d-flex justify-content-between">
					<span className="font-weight-bold mr-2">
						Savings
					</span>
					<span className={classNames('text-right', currentStep <= 3 && 'text-gray-600')}>
						{currentStep <= 4 ? (
							'Calculated after step 3'
						) : (
							'-$' + calculations.savings.toLocaleString('en')
						)}
					</span>
				</div>
				<React.Fragment>
					<div className="border-top border-gray-300 pt-3 mt-3 d-flex align-items-center justify-content-between">
						<span className="h6 mb-0">Working Total</span>
						{currentStep < 3 ? (
							<span className="h5 mb-0">${(0).toLocaleString('en')}</span>
						) : (
							<span className="h5 mb-0">${recommendation.toLocaleString('en')}</span>
						)}
					</div>
					{calculations.debts < 10000 && currentStep > 2 ? (
						<p className="small mt-2">
							<i>Auto-populates a minimum of $10,000 and rounds to the nearest $10,000.</i>
						</p>
					) : (
						null
					)}
				</React.Fragment>
			</div>
		</div>
	);
};
