import React from 'react';
import { FormContext } from 'quote/FormContext';
import { PolicyActionContext, PolicyDataContext } from './PolicyContext';
import { MyPolicy, QuoteFromOliver, sortProductsByName } from './utils';
import classNames from 'classnames';
import { ReactComponent as IconLoad } from 'assets/icons/icon-load.svg';
import { NavRoutes } from 'common/utils';
import { useHistory } from 'react-router-dom';

interface IProps {
	primaryQuote: QuoteFromOliver[];
	spouseQuote?: QuoteFromOliver[];
	isPrimaryQuoteLoading?: boolean;
	isSpouseQuoteLoading?: boolean;
	isLoadingProducts?: boolean;
	loadPolicies?: () => void;
}

export const SideBarComponent: React.FC<IProps> = (props: IProps) => {
	const { primaryQuote, spouseQuote, isPrimaryQuoteLoading, isSpouseQuoteLoading, isLoadingProducts, loadPolicies } = props;
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const { initialUserData: primaryUserData } = FormCtx;
	const PolicyDataCtx = React.useContext(PolicyDataContext);
	const PolicyActionCtx = React.useContext(PolicyActionContext);

	return (
		<div className="shadow-sm rounded d-flex flex-column mb-lg-4">
			<div className="border-bottom border-bottom-gray-500 small mb-2">
				<div className="px-3 pt-3">
					<h3 className="mb-3">{primaryUserData?.firstName || ''} {primaryUserData?.lastName || ''}'s Policy Summary</h3>
					{PolicyDataCtx.policy.length === 0 && <p className="mb-3">Start adding policies to your plan and we’ll calculate how much it will cost you monthly.</p>}
					{sortProductsByName(PolicyDataCtx.policy).map((item: MyPolicy, index: number) => {
						const quoteItem = primaryQuote.find((val: QuoteFromOliver) => val.id === item.policyId);
						return (
							<div className={classNames('d-flex justify-content-between mb-2 align-items-start')} key={`policy-item-${index}`}>
								<span>{PolicyActionCtx.productTitle(item.policyId)}</span>
								{!PolicyDataCtx.isDirty && primaryQuote.length > 0 && quoteItem !== undefined ? (
									<span>${quoteItem?.premium?.toFixed(2).toLocaleString()} /mo</span>
								) : (
									<div className="bg-gray-300 text-gray-700 rounded px-3" ><IconLoad className={classNames('policy-spinner', isPrimaryQuoteLoading && 'spin')} /></div>
								)}
							</div>
						);
					})}
					{PolicyDataCtx.policy.length !== 0 && (
						<div className="d-flex justify-content-between policy-summary-total my-3 pt-3">
							<span className="font-weight-bold">Total</span>
							{PolicyDataCtx.isDirty || primaryQuote.length === 0 ? (
								<div className="bg-gray-300 text-gray-700 rounded px-3"><IconLoad className={classNames('policy-spinner', isPrimaryQuoteLoading && 'spin')} /></div>
							) : (
								<span className="font-weight-bold">${PolicyDataCtx.quoteData.total?.toFixed(2).toLocaleString()} /mo</span>
							)}
						</div>
					)}
				</div>
			</div>

			{FormCtx.spouseApplicantId !== undefined && FormCtx.spouseApplicantId !== '' &&
				<>
					<div className="border-bottom border-bottom-gray-500 small">
						<div className="px-3 pt-3">
							<h3 className="mb-3">{FormCtx.spouseData?.spouseFirstName || ''} {FormCtx.spouseData?.spouseLastName || ''}'s Policy Summary</h3>
							{PolicyDataCtx.spousePolicy.length === 0 && <p className="mb-3">Start adding policies to your plan and we’ll calculate how much it will cost you monthly.</p>}
							{sortProductsByName(PolicyDataCtx.spousePolicy).map((item: MyPolicy, index: number) => {
								const spouseQuoteItem = spouseQuote?.find((val: QuoteFromOliver) => val.id === item.policyId);
								return (
									<div className={classNames('d-flex justify-content-between mb-2 align-items-start')} key={`spouse-policy-item-${index}`}>
										<span>{PolicyActionCtx.spouseProductTitle(item.policyId)}</span>
										{!PolicyDataCtx.spouseIsDirty && spouseQuote && spouseQuote.length > 0 && spouseQuoteItem !== undefined ? (
											<span>${spouseQuoteItem?.premium?.toFixed(2).toLocaleString()} /mo</span>
										) : (
											<div className="bg-gray-300 text-gray-700 rounded px-3" ><IconLoad className={classNames('policy-spinner', isSpouseQuoteLoading && 'spin')} /></div>
										)}
									</div>
								);
							})}
							{PolicyDataCtx.spousePolicy.length !== 0 && (
								<div className="d-flex justify-content-between policy-summary-total my-3 pt-3">
									<span className="font-weight-bold">Total</span>
									{PolicyDataCtx.spouseIsDirty || spouseQuote === undefined || spouseQuote.length === 0 ? (
										<div className="bg-gray-300 text-gray-700 rounded px-3"><IconLoad className={classNames('policy-spinner', isSpouseQuoteLoading && 'spin')} /></div>
									) : (
										<span className="font-weight-bold">${PolicyDataCtx.spouseQuoteData.total?.toFixed(2).toLocaleString()} /mo</span>
									)}
								</div>
							)}
						</div>
					</div>


					{PolicyDataCtx.spousePolicy.length !== 0 && (
						<div className="d-flex justify-content-between border-bottom border-bottom-gray-700 my-3 px-3 pb-3">
							<span className="font-weight-bold">Combined Total</span>
							{PolicyDataCtx.spouseIsDirty || spouseQuote === undefined || spouseQuote.length === 0 ? (
								<div className="bg-gray-300 text-gray-700 rounded px-3">
									<IconLoad className={classNames('policy-spinner', (isSpouseQuoteLoading || isPrimaryQuoteLoading) && 'spin')} />
								</div>
							) : (
								<span className="font-weight-bold">
									${(
										(PolicyDataCtx.spouseQuoteData?.total || 0) +
										(PolicyDataCtx.quoteData?.total || 0)
									).toFixed(2).toLocaleString()} /mo
								</span>

							)}
						</div>
					)}
				</>
			}

			<div className="d-flex align-items-start flex-column">
				{spouseQuote !== undefined && spouseQuote.length !== 0 ? (
					PolicyDataCtx.isDirty || primaryQuote.length === 0 || PolicyDataCtx.spouseIsDirty || spouseQuote?.length === 0 ? (
						<button
							className={classNames('btn btn-primary align-self-stretch mb-2 mx-3', PolicyDataCtx.policy.length === 0 && 'disabled')}
							disabled={PolicyDataCtx.policy.length === 0 || isLoadingProducts === true}
							onClick={loadPolicies}
						>
							{isPrimaryQuoteLoading || isSpouseQuoteLoading ? 'Loading premiums' : 'Calculate Premium'}
							{(isPrimaryQuoteLoading || isSpouseQuoteLoading) && <IconLoad className={classNames('text-white policy-spinner ml-2 spinner-grow-sm spin')} />}
						</button>
					) : (
						<>
							<button className="btn btn-primary align-self-stretch mb-2 mx-3" onClick={goToReview}>
								{isPrimaryQuoteLoading || isSpouseQuoteLoading ? 'Loading premiums' : 'Review'}
							</button>
							{!isPrimaryQuoteLoading && !isSpouseQuoteLoading &&
								<button className="btn btn-outline-primary align-self-stretch mb-2 mx-3" onClick={() => history.push(NavRoutes.QuoteYourDetails)}>
									Back
								</button>
							}
						</>
					)
				)
					: (

						PolicyDataCtx.isDirty || primaryQuote.length === 0 ? (
							<button
								className={classNames('btn btn-primary align-self-stretch mb-2 mx-3', PolicyDataCtx.policy.length === 0 && 'disabled')}
								disabled={PolicyDataCtx.policy.length === 0 || isLoadingProducts === true}
								onClick={loadPolicies}
							>
								{isPrimaryQuoteLoading ? 'Loading premiums' : 'Calculate Premium'}
								{isPrimaryQuoteLoading && <IconLoad className={classNames('text-white policy-spinner ml-2 spinner-grow-sm spin')} />}
							</button>
						) : (
							<>
								<button className="btn btn-primary align-self-stretch mb-2 mx-3" onClick={goToReview}>
									{isPrimaryQuoteLoading ? 'Loading premiums' : 'Review'}
								</button>
								{!isPrimaryQuoteLoading &&
									<button className="btn btn-outline-primary align-self-stretch mb-2 mx-3" onClick={() => history.push(NavRoutes.QuoteYourDetails)}>
										Back
									</button>
								}
							</>
						))}
			</div>
		</div>

	);

	function goToReview() {
		if (isPrimaryQuoteLoading || isSpouseQuoteLoading) { return; }
		PolicyActionCtx.checkAndSetHasChildCI();
		PolicyActionCtx.checkAndSetHasChildDL();
		PolicyActionCtx.spouseCheckAndSetHasChildDLorCL();
		history.push(NavRoutes.QuoteReviewPolicy);
	}
};
