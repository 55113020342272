import { CheckboxOption } from 'components/cpaipw/CheckboxGroup';
import { RadioOption } from 'components/cpaipw/RadioButtonGroup';

export enum PoliciesIds {
	add = 'ADD',
	ci = 'CI',
	cich = 'CICH',
	deplife = 'DEPLIFE',
	life = 'LIFE',
	dental = 'DENTAL',
	ehb = 'EHB',
	officeOH = 'OFFICEOH',
	ltd = 'LTD',
};

export type Policy = {
	policyId: PoliciesIds;
	title: string;
	mostPopular?: boolean;
	requirements: PolicyRequirements;
	fields: PolicyFields[];
}

export type QuoteFromOliver = {
	id?: PoliciesIds;
	title?: string;
	premium?: number;
	parameters: {
		coverageAmount?: string
		waitingPeriod?: string;
	}
}

export enum PolicyFields {
	coverageAmountField = 'coverageAmountField',
	coverageAmountRadio = 'coverageAmountRadio',
	applyToChildren = 'applyToChildren',
	waitingPeriod = 'waitingPeriod',
}

export type PolicyRequirements = {
	min?: number;
	max?: number;
	multiplesOf?: number;
	requiresLTD?: boolean;
	spouseMax: number;
	cpaMax?: number;
	equalOrLowerThanTLB?: true;
	mustBeWorking?: boolean;
	grossMonthlyEarnings?: true;
	children?: true;
}

export type PolicyParams = {
	coverageAmount?: string;
	waitingPeriod?: string;
	applyToChildren?: string;
}

export type MyPolicy = {
	policyId: PoliciesIds;
	parameters?: PolicyParams;
}

export const policies = [
	{
		policyId: PoliciesIds.life,
		mostPopular: true,
		fields: [
			PolicyFields.coverageAmountField,
		],
		requirements: {
			min: 10000,
			max: 2000000,
			multiplesOf: 10000,
		},
	},
	{
		policyId: PoliciesIds.ltd,
		mostPopular: true,
		fields: [
			PolicyFields.coverageAmountField,
			PolicyFields.waitingPeriod,
		],
		requirements: {
			max: 10000,
			min: 100,
			multiplesOf: 100,
			mustBeWorking: true,
			grossMonthlyEarnings: true,
		},
	},
	{
		policyId: PoliciesIds.ci,
		fields: [
			PolicyFields.coverageAmountField,
			PolicyFields.applyToChildren,
		],
		requirements: {
			min: 0,
			max: 250000,
			multiplesOf: 10000,
		},
	},
	{
		policyId: PoliciesIds.cich,
		fields: [],
		requirements: {},
	},
	{
		policyId: PoliciesIds.add,
		fields: [
			PolicyFields.coverageAmountField,
		],
		requirements: {
			min: 10000,
			cpaMax: 1000000,
			multiplesOf: 10000,
			spouseMax: 500000,
			equalOrLowerThanTLB: true,
		},
	},
	{
		policyId: PoliciesIds.deplife,
		fields: [
			PolicyFields.coverageAmountRadio,
		],
		requirements: {
			children: true,
		}
	},
	{
		policyId: PoliciesIds.officeOH,
		fields: [
			PolicyFields.coverageAmountField,
		],
		requirements: {
			max: 6000,
			min: 100,
			multiplesOf: 100,
			requiresLTD: true,
		},
	},
] as Policy[];

export const coverageAmountRadioOptions = [
	{
		label: '$10,000',
		value: 'IDLIF10/10',
	},
	{
		label: '$15,000',
		value: 'IDLIF15/15',
	},
	{
		label: '$20,000',
		value: 'IDLIF20/20',
	},
	{
		label: '$25,000',
		value: 'IDLIF25/25',
	},
] as RadioOption[];

export const applyToChildrenRadioOptions = [
	{
		label: 'Yes',
		value: 'yes',
	},
	{
		label: 'No',
		value: 'no',
	},
] as RadioOption[];

export const waitingPeriodRadioOptions = [
	{
		label: '30 days',
		value: 'ILTD30',
	},
	{
		label: '90 days',
		value: 'ILTD90',
	},
	{
		label: '120 days',
		value: 'ILTD120',
	},
	{
		label: '180 days',
		value: 'ILTD180',
	},
	{
		label: '365 days',
		value: 'ILTD365',
	},
] as RadioOption[];



export interface IPolicyComponentProps {
	value?: string;
	onChange: (val: string) => void;
	error?: string;
	description?: string;
}

export const tooltipText = {
	waitingPeriod: 'This is the time before benefits begin if disabled.',
	applyToChildren: 'A flat $10,000 benefit will be available for each child.',
};

export const cpaStatusRadioOptions = [
	{
		label: 'CPA Member',
		value: 'member',
	},
	{
		label: 'CPA Candidate',
		value: 'student',
	},
	{
		label: 'Spouse of a CPA Member or CPA Candidate',
		value: 'spouse',
	},
];

export const cpaBodiesRadioOptions = [
	{
		label: 'CPA Alberta',
		value: 'cpaAB',
	},
	{
		label: 'CPA British Columbia',
		value: 'cpaBC',
	},
	{
		label: 'CPA Manitoba',
		value: 'cpaMB',
	},
	{
		label: 'CPA Northwest Territories',
		value: 'cpaNT',
	},
	{
		label: 'CPA Saskatchewan',
		value: 'cpaSK',
	},
];

export const whyToBuyOptions = [
	{
		label: 'Getting Married',
		value: 'gettingMarried',
	},
	{
		label: 'Buying a New Home',
		value: 'buyingANewHome',
	},
	{
		label: 'Birth of a Child',
		value: 'birthOfAChild',
	},
	{
		label: 'New Job',
		value: 'newJobNewPlaceOfEmployment',
	},
	{
		label: 'Death of a Loved One',
		value: 'deathOfALovedOne',
	},
	{
		label: 'I Started my Own Company',
		value: 'iStartedMyOwnCompany',
	},
	{
		label: 'I’m Becoming a Partner at a Firm',
		value: 'imBecomingAPartnerAtAFirm',
	},
	{
		label: 'Buying an Income Property',
		value: 'buyingAnIncomeProperty',
	},
	{
		label: 'Becoming Self-Employed',
		value: 'becomingSelfEmployed',
	},
	{
		label: 'Becoming Grandparents and Want to Leave a Legacy',
		value: 'becomingGrandparentsAndWantToLeaveALegacy',
	},
	{
		label: 'Other (Please Specify)',
		value: 'other',
	},
] as CheckboxOption[];

export function getFormattedValue(value: number | undefined) {
	if (value === undefined) {
		return '';
	}
	return (value).toLocaleString('en');
}

export const sortProducts = (products: any[]) => { //products is either OliverProductResponseDTO[] or QuoteFromOliver[]
	var sorted = [] as any[];

	policies.forEach((policy: Policy, index: number) => {
		var foundProducts = products.filter((product) => product.id === policy.policyId);
		if (foundProducts.length > 0) {
			sorted.push({
				index: index,
				product: foundProducts[0],
				settings: policy
			});
		}
	});

	return sorted;
};

export const sortProductsByName = (products: MyPolicy[]) => { //products is either OliverProductResponseDTO[] or QuoteFromOliver[]
	var sorted = [] as MyPolicy[];

	policies.forEach((policy: Policy, index: number) => {
		var foundProducts = products.filter((product) => product.policyId === policy.policyId);
		if (foundProducts.length > 0) {
			sorted.push(policy);
		}
	});

	return sorted;
};

export function getNearest10k(value: number) {
	return Math.ceil(value / 10000) * 10000;
}