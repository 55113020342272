import { randomString } from 'common/utils';
import React from 'react';
import { IHistoryData, IllnessDTO } from './HistoryTypes';

interface IHistoryDataContext {
	historyData: IHistoryData[];
}

interface IHistoryDataActionContext {
	setHistoryData: React.Dispatch<React.SetStateAction<IHistoryData[]>>;
	extractHistoryData: (filteredHistoryDataKeys: string[], value: any)  => void,
}

const HistoryDataContext = React.createContext<IHistoryDataContext>({
	historyData: []
});

const HistoryActionContext = React.createContext<IHistoryDataActionContext>({
	setHistoryData: () => null,
	extractHistoryData: () => undefined
});


const HistoryProvider = (props: any) => {
	const [historyData, setHistoryData] = React.useState<IHistoryData[]>([]);

	const extractHistoryData = (filteredHistoryDataKeys: string[], val: any) => {
		const historyContextData = [] as IHistoryData[];

		// our data for history looks like this applicant.familMember.illness.randomlyGeneratedString
		filteredHistoryDataKeys.forEach(user => {
			const innerHistoryUserDataKey = Object.keys(val[user]);

			let historyData = {
				user,
				illness: [],
			} as IHistoryData;

			innerHistoryUserDataKey.forEach(familyMember => {

				const familyMemberKeys = Object.keys(val[user][familyMember]);

				familyMemberKeys.forEach(illness => {
					const data = val[user][familyMember][illness] as IllnessDTO;

					let illnessDataForFamilyMember = {
						id: randomString(9),
						ageAtDeath: data.ageAtDeath,
						ageAtOnsetIllness: data.ageAtOnsetIllness,
						ageIfAlive: data.ageIfAlive,
						nameOfIllness: data.nameOfIllness,
						familyMember,
					} as IllnessDTO;

					historyData.illness.push(illnessDataForFamilyMember);
				});
			});
			historyContextData.push(historyData);
		});

		setHistoryData(historyContextData);
	};

	return (
		<HistoryDataContext.Provider value={{
			historyData,
		}}>
			<HistoryActionContext.Provider value={{
				setHistoryData,
				extractHistoryData
			}}>
				{props.children}
			</HistoryActionContext.Provider>
		</HistoryDataContext.Provider>
	);
};

export {
	HistoryActionContext,
	HistoryDataContext
};

export default HistoryProvider;