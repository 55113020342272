import React from 'react';
import { ApplicationPages, QuotePages } from 'common/utils';
import { Units } from 'components/cpaipw/UnitsTypeInput';
import oliverAPI from 'api/OliverAPI';
import { getNearest10k } from './policy/utils';


export interface IFormContext {
	upsertFormValues: (values: IinitialQuestions & IAboutYou & INeedsAssessment) => Promise<ApplicantDTO| undefined>;
	setInitialUserData: React.Dispatch<React.SetStateAction<IinitialQuestions>>;
	setCurrentPage: (page: QuotePages | ApplicationPages) => void;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setNeedsAssessment: React.Dispatch<React.SetStateAction<INeedsAssessment>>;
	setPersonalData: React.Dispatch<React.SetStateAction<IAboutYou>>;
	setApplicantId: React.Dispatch<React.SetStateAction<string>>;
	setWeightUnit: React.Dispatch<React.SetStateAction<Units>>;
	setHeightUnit: React.Dispatch<React.SetStateAction<Units>>;
	setSkippedNeedsAssessment: React.Dispatch<React.SetStateAction<boolean>>;
	setNeedsAssessmentOnly: React.Dispatch<React.SetStateAction<boolean | undefined>>;
	calculateTotal: () => ITotalNeedsAssessment;
	setSpouseData: React.Dispatch<React.SetStateAction<ISpouseData>>;
	setSpouseApplicantId: React.Dispatch<React.SetStateAction<string>>;
	initialUserData: IinitialQuestions | null;
	personalData: IAboutYou;
	needsAssessment: INeedsAssessment;
	currentPage: QuotePages | ApplicationPages;
	isLoading: boolean;
	applicantId: string;
	skippedNeedsAssessment: boolean;
	weightUnit: Units;
	heightUnit: Units;
	needsAssessmentOnly?: boolean;
	recommended_term_life?: number,
	//Spouse
	spouseData: ISpouseData | null;
	spouseApplicantId: string;
}

export const FormContext = React.createContext<IFormContext>({
	upsertFormValues: async () => undefined,
	setCurrentPage: () => { },
	setInitialUserData: () => { },
	setUserDataLoading: () => { },
	setPersonalData: () => { },
	setIsLoading: () => { },
	setApplicantId: () => { },
	setNeedsAssessment: () => { },
	setWeightUnit: () => { },
	setSkippedNeedsAssessment: () => { },
	setHeightUnit: () => { },
	setNeedsAssessmentOnly: () => { },
	calculateTotal: () => Object(),
	setSpouseData: () => { },
	setSpouseApplicantId: () => { },
	needsAssessment: {},
	personalData: {},
	initialUserData: {},
	isLoading: true,
	skippedNeedsAssessment: false,
	userDataLoading: true,
	applicantId: '',
	heightUnit: Units.ftin,
	weightUnit: Units.lbs,
	currentPage: QuotePages.yourDetails,
	needsAssessmentOnly: false,
	spouseData: {},
	spouseApplicantId: '',
	recommended_term_life: 0,
} as IFormContext);

export interface IinitialQuestions {
	firstName?: string;
	lastName?: string;
	email?: string;
	phonenumber?: string;
	optIn?: boolean;
	existingClient?: string;
	referredBy?: string;
	sendPDFByEmail?: boolean;
}

export type IAboutYou = {
	dob?: string;
	gender?: string;
	usedTobacco?: string;
	cpaStatus?: string;
	cpaBody?: string;
}

export type ISpouseData = {
	spouseFirstName?: string;
	spouseLastName?: string;
	spouseEmail?: string;
	spousePhonenumber?: string;
	spouseOptIn?: boolean;
	spouseDob?: string;
	spouseGender?: string;
	spouseUsedTobacco?: string;
}

export type INeedsAssessment = {
	reasonToBuy?: string[];
	reasonToBuyOther?: string;
	hasMortgage?: string;
	hasDebts?: string;
	debts?: IDebts;
	hasSavings?: string;
	valueOfSavings?: ISavings;
	annualIncome?: string;
	annualIncomeYears?: string;
	annualIncomeYearsOther?: string;
	hasWantChildren?: string;
	numberChildren?: string;
	payFuneral?: string;
	expenses?: IExpenses;
	hasInsurance?: string;
	existingInsurance?: IExistingInsurance;
	step?: number;
	recommended_term_life?: number;
}

export type IDebts = {
	lineOfCredit?: string;
	creditCard?: string;
	studentLoans?: string;
	autoLoans?: string;
	mortgageBalance?: string;
	other?: string;
}

export type ISavings = {
	rrsp?: string;
	tfsa?: string;
	chequingAccounts?: string;
	savingsAccount?: string;
	other?: string;
}

export type IExpenses = {
	tuition?: string;
	wedding?: string;
	legacy?: string;
	funeral?: string;
}

export type IExistingInsurance = {
	employer?: string;
	bank?: string;
	life?: string;
}
export type ITotalNeedsAssessment = {
	debts: number;
	savings: number;
	expenses: number;
	insurance: number;
	income: number;
	total: number;
}


const FormProvider = (props: any) => {
	const [initailUserData, setInitialUserData] = React.useState<IinitialQuestions>({});
	const [personalData, setPersonalData] = React.useState<IAboutYou>({}); //({firstName: 'Bob', lastName: 'Ross', cpaStatus: 'member', cpaBody: 'cpaAB'});
	const [needsAssessment, setNeedsAssessment] = React.useState<INeedsAssessment>({});
	const [currentPage, setCurrentPage] = React.useState<QuotePages | ApplicationPages>(QuotePages.yourDetails);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [skippedNeedsAssessment, setSkippedNeedsAssessment] = React.useState<boolean>(false);
	const [needsAssessmentOnly, setNeedsAssessmentOnly] = React.useState<boolean | undefined>(undefined);
	const [weightUnit, setWeightUnit] = React.useState<Units>(Units.lbs);
	const [heightUnit, setHeightUnit] = React.useState<Units>(Units.ftin);
	const [needsAssessmentId, setNeedsAssessmentId] = React.useState<string>('');
	const [applicantId, setApplicantId] = React.useState<string>('');
	const [spouseData, setSpouseData] = React.useState<ISpouseData>({});
	const [spouseApplicantId, setSpouseApplicantId] = React.useState<string>('');

	React.useEffect(() => {
		// if on this page http://localhost:3000/quote/needs-assessment set needsassessmentonly to true
		if (window.location.href.includes('needs-assessment')) {
			setNeedsAssessmentOnly(true);
		}
	}, []);


	const checkSetCurrentPage = (page: QuotePages | ApplicationPages) => {
		if (currentPage !== page) {
			setCurrentPage(page);
		}
	};

	function calculateTotal(): ITotalNeedsAssessment {
		const needsAssessmentVals = needsAssessment;
		let debts = 0;
		if (needsAssessmentVals.debts !== undefined) {
			const debtsKeys = Object.keys(needsAssessmentVals.debts);
			debtsKeys.forEach((key) => {
				const value = needsAssessmentVals.debts?.[key as keyof IDebts];
				if (value !== undefined) {
					const numVal = parseInt(value);
					if (isNaN(numVal) === false) {
						debts += numVal;
					}
				}
			});
		}

		let savings = 0;
		if (needsAssessmentVals.valueOfSavings !== undefined) {
			const savingsKeys = Object.keys(needsAssessmentVals.valueOfSavings);
			savingsKeys.forEach((key) => {
				const value = needsAssessmentVals.valueOfSavings?.[key as keyof ISavings];
				if (value !== undefined) {
					const numVal = parseInt(value);
					if (isNaN(numVal) === false) {
						savings += numVal;
					}
				}
			});
		}

		let expenses = 0;

		if (needsAssessmentVals.expenses !== undefined) {
			const expensesKeys = Object.keys(needsAssessmentVals.expenses);
			expensesKeys.forEach((key) => {
				const value = needsAssessmentVals.expenses?.[key as keyof IExpenses];
				if (value !== undefined) {
					const numVal = parseInt(value);
					if (isNaN(numVal) === false) {
						expenses += numVal;
					}
				}
			});
		}

		let insurance = 0;
		if (needsAssessmentVals.existingInsurance !== undefined) {
			const existingInsuranceKeys = Object.keys(needsAssessmentVals.existingInsurance);
			existingInsuranceKeys.forEach((key) => {
				const value = needsAssessmentVals.existingInsurance?.[key as keyof IExistingInsurance];
				if (value !== undefined) {
					const numVal = parseInt(value);
					if (isNaN(numVal) === false) {
						insurance += numVal;
					}
				}
			});
		}
		let income = 0;
		if (needsAssessmentVals.annualIncome !== undefined && needsAssessmentVals.annualIncomeYears !== undefined) {
			const annualIncome = parseInt(needsAssessmentVals.annualIncome);
			let annualIncomeYears = 0;
			if (needsAssessmentVals.annualIncomeYears === 'other' && needsAssessmentVals.annualIncomeYearsOther !== undefined) {
				annualIncomeYears = parseInt(needsAssessmentVals.annualIncomeYearsOther);
			} else {
				annualIncomeYears = parseInt(needsAssessmentVals.annualIncomeYears);
			}
			if (isNaN(annualIncomeYears) === false) {
				income = annualIncome * annualIncomeYears;
			}
		}

		const total = income + expenses + debts - savings - insurance;

		return {
			income,
			insurance,
			expenses,
			debts,
			savings,
			total,
		};
	}

	async function upsertFormValues(values: IinitialQuestions & IAboutYou & INeedsAssessment & ISpouseData) {
		const smoking_status = values?.usedTobacco || personalData?.usedTobacco;
		const existing_client = values?.existingClient || initailUserData?.existingClient;
		const hasSpouse = values?.spouseFirstName !== undefined && values?.spouseFirstName !== '';

		//recommended term life
		const calculations = calculateTotal();
		const total = getNearest10k(calculations.total);
		const recommendation = (total > 2000000) ? 2000000 : (total <= 0) ? 10000 : total;
		values.recommended_term_life = recommendation;

		let whyToBuy = '';
		if (values.reasonToBuy !== undefined) {
			values.reasonToBuy?.map((v, i) => {
				if (i > 0) {
					whyToBuy = whyToBuy + ', ';
				};
				if (v === 'other') {
					whyToBuy = whyToBuy + values.reasonToBuyOther;
				} else {
					whyToBuy = whyToBuy + v;
				}
			});
		} else if (needsAssessment.reasonToBuy !== undefined) {
			needsAssessment.reasonToBuy?.map((v, i) => {
				if (i > 0) {
					whyToBuy = whyToBuy + ', ';
				};
				if (v === 'other') {
					whyToBuy = whyToBuy + needsAssessment.reasonToBuyOther;
				} else {
					whyToBuy = whyToBuy + v;
				}
			});
		}

		let incomeYears = undefined;
		if (values.annualIncomeYears !== undefined) {
			if (values.annualIncomeYears === 'other' && values.annualIncomeYearsOther !== undefined) {
				incomeYears = values.annualIncomeYearsOther;
			} else {
				incomeYears = values.annualIncomeYears;
			}
		} else if (needsAssessment.annualIncomeYears !== undefined) {
			if (needsAssessment.annualIncomeYears === 'other' && needsAssessment.annualIncomeYearsOther !== undefined) {
				incomeYears = needsAssessment.annualIncomeYearsOther;
			} else {
				incomeYears = needsAssessment.annualIncomeYears;
			}
		}

		var applicant: ApplicantDTO = {
			first_name: values?.firstName || initailUserData?.firstName,
			last_name: values?.lastName || initailUserData?.lastName,
			email: values?.email || initailUserData?.email,
			optIn: values?.optIn || initailUserData?.optIn,
			existing_client: (existing_client && existing_client === 'yes' ? true : false),
			referred_by: values?.referredBy || initailUserData?.referredBy,
			sendPDFByEmail: values?.sendPDFByEmail || initailUserData?.sendPDFByEmail,
			phoneNumber: values?.phonenumber || initailUserData?.phonenumber,
			cpa_status: values?.cpaStatus || personalData?.cpaStatus,
			cpa_body: values?.cpaBody || personalData?.cpaBody,
			date_of_birth: values?.dob || personalData?.dob,
			gender: values?.gender || personalData?.gender,
			smoking_status: (smoking_status && smoking_status === 'yes' ? true : false),
			applicant_id: applicantId !== '' ? applicantId : undefined,
			debt_auto: values?.debts?.autoLoans || needsAssessment?.debts?.autoLoans,
			debt_cc: values?.debts?.creditCard || needsAssessment?.debts?.creditCard,
			debt_loc: values?.debts?.lineOfCredit || needsAssessment?.debts?.lineOfCredit,
			debt_student_loan: values?.debts?.studentLoans || needsAssessment?.debts?.studentLoans,
			debt_other: values?.debts?.other || needsAssessment?.debts?.other,
			debt_mortgage_balance: values?.debts?.mortgageBalance || needsAssessment?.debts?.mortgageBalance,
			annual_income: values?.annualIncome || needsAssessment?.annualIncome,
			annual_income_years: incomeYears,
			why_to_buy: whyToBuy !== '' ? whyToBuy : undefined,
			existing_insurance_bank: values?.existingInsurance?.bank || needsAssessment?.existingInsurance?.bank,
			existing_insurance_employer: values?.existingInsurance?.employer || needsAssessment?.existingInsurance?.employer,
			existing_insurance_life: values?.existingInsurance?.life || needsAssessment?.existingInsurance?.life,
			expense_funeral: values?.expenses?.funeral || needsAssessment?.expenses?.funeral,
			expense_legacy: values?.expenses?.legacy || needsAssessment?.expenses?.legacy,
			expense_tuition: values?.expenses?.tuition || needsAssessment?.expenses?.tuition,
			expense_wedding: values?.expenses?.wedding || needsAssessment?.expenses?.wedding,
			has_wants_num_children: values?.numberChildren || needsAssessment?.numberChildren,
			savings_chequing: values?.valueOfSavings?.chequingAccounts || needsAssessment?.valueOfSavings?.chequingAccounts,
			savings_other: values?.valueOfSavings?.other || needsAssessment?.valueOfSavings?.other,
			savings_rrsp: values?.valueOfSavings?.rrsp || needsAssessment?.valueOfSavings?.rrsp,
			savings_savings: values?.valueOfSavings?.savingsAccount || needsAssessment?.valueOfSavings?.savingsAccount,
			savings_tfsa: values?.valueOfSavings?.tfsa || needsAssessment?.valueOfSavings?.tfsa,
			spouse_name: hasSpouse ? values?.spouseFirstName + ' ' + values?.spouseLastName : '',
			recommended_term_life: values?.recommended_term_life?.toString(),
			needs_assessment_id: needsAssessmentId !== '' ? needsAssessmentId : undefined,
		};

		const request = { applicant, needsAssessmentOnly } as ApplicantRequest;

		try {
			const applicantResp = await oliverAPI.postApplicant(request);

			if (applicantResp.applicant_id !== undefined && applicantResp.applicant_id !== '' && applicantResp.applicant_id !== null) {
				setApplicantId(applicantResp.applicant_id);
				setNeedsAssessmentId(applicantResp.needs_assessment_id ?? '');
			}

			// if spouse data is present, create another applicant for them and post it with the oliver api, then set the spouse applicant id
			if (hasSpouse) {
				// create spouse applicant
				var spouseApplicant: ApplicantDTO = {
					first_name: values?.spouseFirstName,
					last_name: values?.spouseLastName,
					date_of_birth: values?.spouseDob,
					gender: values?.spouseGender,
					email: values?.spouseEmail,
					optIn: values?.spouseOptIn,
					phoneNumber: values?.spousePhonenumber,
					smoking_status: (values?.spouseUsedTobacco && values?.spouseUsedTobacco === 'yes' ? true : false),
					cpa_status: 'spouse',
					cpa_body: values?.cpaBody || personalData?.cpaBody,
					applicant_id: spouseApplicantId !== '' ? spouseApplicantId : undefined,
					spouse_name: applicant.first_name + ' ' + applicant.last_name,
				};

				const spouseRequest = { applicant: spouseApplicant } as ApplicantRequest;

				const spouseResp = await oliverAPI.postApplicant(spouseRequest);

				if (values?.spouseEmail !== undefined && values?.spouseEmail !== '') {
					oliverAPI.postAddTagToContact({ tagName: 'Add a Spouse Lead', email: values.spouseEmail });
				}
				setSpouseApplicantId(spouseResp?.applicant_id ?? '');
			}
			return applicantResp;

		} catch (error) {
			console.error({ error });
			return undefined;
		}
	}

	return (
		<FormContext.Provider
			value={{
				upsertFormValues,
				setInitialUserData,
				setNeedsAssessment,
				setCurrentPage: checkSetCurrentPage,
				setIsLoading,
				setPersonalData,
				setApplicantId,
				calculateTotal,
				setSkippedNeedsAssessment,
				setNeedsAssessmentOnly,
				skippedNeedsAssessment,
				applicantId,
				needsAssessment,
				initialUserData: initailUserData,
				personalData,
				currentPage,
				isLoading,
				setWeightUnit,
				weightUnit,
				setHeightUnit,
				heightUnit,
				needsAssessmentOnly,
				spouseData,
				spouseApplicantId,
				setSpouseData,
				setSpouseApplicantId,
			}}
		>
			{props.children}
		</FormContext.Provider>
	);
};

export default FormProvider;
