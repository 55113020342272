import React from 'react';
import { RadioButtonGroup } from 'components/cpaipw/RadioButtonGroup';
import { FormContext, IinitialQuestions } from 'quote/FormContext';
import { useForm, Controller } from 'react-hook-form';
import { QuoteContainer, LoadContainer, FormButtons, CheckboxButton } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { NavRoutes, preventDefault, QuotePages, ValidationMessages } from 'common/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { PatternFormat } from 'react-number-format';

interface IProps {
	location: any;
}

const GetStarted: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);

	const history = useHistory();
	const validateAreaCode = (value: any) => {
		const areaCode = value.substring(4, 5);
		if (areaCode.includes('0') || areaCode.includes('1')) {
			return 'Please enter a valid phone number';
		}
		return true;
	};

	const { control, handleSubmit, errors, trigger, getValues } = useForm<IinitialQuestions>({
		defaultValues: {
			firstName: FormCtx.initialUserData?.firstName || '',
			lastName: FormCtx.initialUserData?.lastName || '',
			email: FormCtx.initialUserData?.email || '',
			phonenumber: FormCtx.initialUserData?.phonenumber || '',
			optIn: FormCtx.initialUserData?.optIn,
			referredBy: FormCtx.initialUserData?.referredBy || '',
			existingClient: FormCtx.initialUserData?.existingClient,
		},
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const onSubmit = async (values: IinitialQuestions) => {
		FormCtx.setIsLoading(true);
		FormCtx.setInitialUserData(values);

		const result = await FormCtx.upsertFormValues(values);
		FormCtx.setIsLoading(false);

		if (result !== undefined) {
			history.replace(NavRoutes.QuoteSelectInsuranceType);
		} else {
			alert('Failed to fetch policy');
		}
	};

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.yourDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		async function checkParamsValues() {
			const isValid = await trigger();
			if (isValid === true) {
				const values = getValues();
				onSubmit(values);
			}
		}
		const params = new URLSearchParams(props.location.search);
		if ((params.get('dob') || params.get('gender') || params.get('usedTobacco'))) {

			FormCtx.setIsLoading(true);
			setTimeout(() => {
				checkParamsValues();
			}, 100);
		}
		FormCtx.setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<QuoteContainer>
			<h1>Get Started</h1>
			<p>To help us get started with your quote, please enter your information below.</p>
			<LoadContainer isLoading={FormCtx.isLoading}>
				<form onSubmit={preventDefault}>
					<div className="form-group">
						<label htmlFor="firstName">First Name</label>
						<Controller
							name="firstName"
							control={control}
							rules={{ required: ValidationMessages.required }}
							render={({ onChange, value }) =>
								<input
									type="text"
									className="form-control"
									value={value}
									onChange={onChange}
									name="firstName"
									id="firstName"
								/>
							}
						/>
						<ErrorMessage>{errors.firstName?.message}</ErrorMessage>
					</div>
					<div className="form-group">
						<label htmlFor="lastName">Last Name</label>
						<Controller
							name="lastName"
							control={control}
							rules={{ required: ValidationMessages.required }}
							render={({ onChange, value }) =>
								<input
									type="text"
									className="form-control"
									value={value}
									onChange={onChange}
									name="lastName"
									id="lastName"
								/>
							}
						/>
						<ErrorMessage>{errors.lastName?.message}</ErrorMessage>
					</div>
					<div className="form-group">
						<label htmlFor="email">Email</label>
						<Controller
							name="email"
							control={control}
							//rules={{ required: ValidationMessages.required }}
							render={({ onChange, value }) =>
								<input
									type="text"
									className="form-control"
									value={value}
									onChange={onChange}
									name="email"
									id="email"
								/>
							}
						/>
						<ErrorMessage>{errors.email?.message}</ErrorMessage>
					</div>

					<div className="form-group">
						<label htmlFor="phonenumber">Phone</label>
						<Controller
							name="phonenumber"
							control={control}
							rules={{ validate: validateAreaCode }}
							render={({ onChange, value, ref }) =>
								<PatternFormat
									format="+1 (###) ###-####"
									mask="_"
									value={value}
									getInputRef={ref}
									onChange={onChange}
									name="phonenumber"
									id="phonenumber"
									type="text"
									className="form-control"
								/>
							}
						/>
						<ErrorMessage>{errors.phonenumber?.message}</ErrorMessage>
					</div>
					<div className="form-group">
						<label htmlFor="existingClient">Are you an existing client?</label>
						<Controller
							name="existingClient"
							control={control}
							// rules={{
							// 	required: ValidationMessages.required,
							// }}
							render={({ onChange, value }) =>
								<RadioButtonGroup
									name="existingClient"
									onChange={onChange}
									value={value}
									options={[
										{
											label: 'Yes',
											value: 'yes',
										},
										{
											label: 'No',
											value: 'no',
										},
									]}
								/>
							}
						/>
						<ErrorMessage>{errors.existingClient?.message}</ErrorMessage>
					</div>
					<div className="form-group">
						<label htmlFor="referredBy">Who referred you? (Leave blank if you were not referred)</label>
						<Controller
							name="referredBy"
							control={control}
							render={({ onChange, value }) =>
								<input
									type="text"
									className="form-control"
									value={value}
									onChange={onChange}
									name="referredBy"
									id="referredBy"
								/>
							}
						/>
						<ErrorMessage>{errors.referredBy?.message}</ErrorMessage>
					</div>
					<div className="form-group">
						<label htmlFor="optIn">Email Subscription</label>
						<Controller
							className="col"
							name="optIn"
							control={control}
							render={({ onChange, value }) =>
								<CheckboxButton
									name="optIn"
									hideBorder={true}
									isActive={value}
									label="	Yes, I would like to receive emails according to my subscription preferences. I understand I can unsubscribe at any time."
									onClick={() => onChange(!value)}
								/>
							}
						/>
					</div>
					<FormButtons
						onClickNext={handleSubmit(onSubmit)}
					/>
				</form>
			</LoadContainer>
		</QuoteContainer>
	);

};

export default GetStarted;
