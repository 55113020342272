import oliverAPI from 'api/OliverAPI';
import React, { useEffect, useState } from 'react';
import { ApplicationDataContext } from './AboutYouQuestions/ApplicationContext';
import { BeneficiaryDataContext } from './BeneficiaryQuestions/BeneficiaryContext';
import { HealthDataContext } from './HealthQuestions/HealthContext';
import { HistoryDataContext } from './HistoryQuestions/HistoryContext';
import { LifestyleDataContext } from './LifestyleQuestions/LifestyleContext';

interface ISubmissionContext {
	setSubmitApplication: React.Dispatch<React.SetStateAction<boolean>>;
	submissionLoading: boolean;
	applicationId: string | undefined;
};

export const SubmissionContext = React.createContext<ISubmissionContext>({
	setSubmitApplication: () => null,
	submissionLoading: false,
	applicationId: undefined,
});

const SubmissionProvider = (props: any) => {
	const [applicationId, setApplicationId] = useState<string | undefined>('');
	const applicationCtx = React.useContext(ApplicationDataContext);
	const beneficiaryCtx = React.useContext(BeneficiaryDataContext);
	const healthCtx = React.useContext(HealthDataContext);
	const lifestyleCtx = React.useContext(LifestyleDataContext);
	const historyCtx = React.useContext(HistoryDataContext);

	const [submitApplicationData, setSubmitApplicationData] = React.useState<boolean>(false);
	const [submissionLoading, setSubmissionLoading] = React.useState<boolean>(false);


	useEffect(() => {
		const submitApplication = async () => {
			setSubmissionLoading(true);
			let id = undefined;
			let request = {
				application: {
					applicationID: applicationId,
					aboutYouApplication: applicationCtx.applicationData,
					beneficiaries: beneficiaryCtx.beneficiaryData,
					healthAnswers: healthCtx.healthQuestionaireData,
					lifestyleAnswers: lifestyleCtx.lifestyleQuestions,
					history: {
						historyRecords: historyCtx.historyData
					}
				} as ApplicationDTO
			} as ApplicationRequest;

			id = await oliverAPI.postApplication(request);
			// console.log('applicationId',id);
			setApplicationId(id);
		};

		if (submitApplicationData) {
			submitApplication();
		}
		return () => {
			setSubmitApplicationData(false);
			setSubmissionLoading(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submitApplicationData]);

	return (
		<SubmissionContext.Provider value={{
			setSubmitApplication: setSubmitApplicationData,
			submissionLoading,
			applicationId,
		}}>
			{props.children}
		</SubmissionContext.Provider>
	);
};

export default SubmissionProvider;