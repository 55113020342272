import React from 'react';
import { ApplicationPages, NavRoutes, preventDefault } from 'common/utils';
import { CheckboxButton, Divider, FormButtons, QuestionContainer, QuoteContainer, Steps } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { FormContext } from 'quote/FormContext';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { HealthAccordion } from 'components/cpaipw/HealthAccordion';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { Accordion } from 'components/cpaipw/Accordion';
import { HealthActionContext, HealthDataContext } from './HealthContext';
import { IHealthQuestionWithUserName, ILast5Years } from './HealthTypes';
import { ApplicationDataContext } from 'application/AboutYouQuestions/ApplicationContext';

const HealthApplicationStep3: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);

	const healthActionContext = React.useContext(HealthActionContext);
	const healthDataContext = React.useContext(HealthDataContext);
	const appDataContext = React.useContext(ApplicationDataContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.health);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const healthData = healthDataContext.healthQuestionaireData?.section3Questions;

	const healthDataKeys = healthData && Object.keys(healthData);

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			notApplicable: healthDataKeys !== undefined && healthDataKeys?.length === 0 ? true : false
		}
	});
	const { control, watch, setError, errors } = methods;

	const watchNa = watch(['notApplicable']);

	const watchAll = watch();
	const formHasFields = Object.keys(watchAll).filter(v => v !== 'notApplicable');

	const defaultselectedUsers: any = {};
	if (healthDataKeys !== undefined) {
		healthDataKeys?.forEach((key: string) => {
			const data = healthData?.[key as keyof ILast5Years];
			const users = data?.map(item => {
				return item.idOfApplicantOrChildren;
			});
			defaultselectedUsers[key] = users;
		});
	}

	const onHandleSubmit = (val: any) => {
		const keys = Object.keys(val);
		if (keys.includes('notApplicable') && keys.length === 1 && val['notApplicable'] === false) {
			setError('notApplicable', { message: 'If none of the situations are applicable, please select "Not Applicable"' });
			return;
		}

		const section3Questions = val.section3Questions !== undefined ? val.section3Questions : {};
		const contentKeys = Object.keys(section3Questions);
		const section3Data: ILast5Years = {};
		contentKeys.forEach(key => {
			const data = healthActionContext.extractHealthData(val.section3Questions[key], appDataContext.applicationData.children) as IHealthQuestionWithUserName[];
			section3Data[key as keyof ILast5Years] = data;
		});

		healthActionContext.setHealthQuestionaireData({ ...healthDataContext.healthQuestionaireData, section3Questions: section3Data });
		history.push(NavRoutes.ApplicationHealthStep4);
	};


	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<h1>Health</h1>
				<Steps currentstep={3} totalSteps={5} />
				<form onSubmit={preventDefault}>
					<p>
						<b>In the last 5 years,</b> has the proposed insured spent <b>more than 72 hours: </b>
					</p>
					<div className="flex-column flex-fill">
						<QuestionContainer index={1}>
							<div className="form-group">
								<label htmlFor="inHospitalNotRelated">
									<b>In a hospital, clinic or rehabilitation facility for care not related to pregnancy or childbirth?</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.inHospitalNotRelated}>
									<HealthAccordion
										name="section3Questions.inHospitalNotRelated"
										section="section3"
									/>
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={2}>
							<div className="form-group">
								<label htmlFor="addictionTreatmentCenter">
									<b>In an alcohol, drug or gambling addiction treament centre?</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.addictionTreatmentCenter}>
									<HealthAccordion
										name="section3Questions.addictionTreatmentCenter"
										section="section3"
									/>
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={3}>
							<Controller
								name="notApplicable"
								control={control}
								defaultValue={false}
								render={({ onChange, value }) =>
									<CheckboxButton
										name="notApplicable"
										isActive={value}
										label="Not Applicable"
										disabled={formHasFields.length > 0}
										onClick={() => onChange(!value)}
									/>
								}
							/>
							<ErrorMessage>{errors?.notApplicable?.message}</ErrorMessage>
						</QuestionContainer>
					</div>
					<FormButtons
						onClickBack={() => history.push(NavRoutes.ApplicationHealthStep2)}
						onClickNext={methods.handleSubmit(onHandleSubmit)}
					/>
				</form>
			</QuoteContainer>
		</FormProvider>
	);
};

export default HealthApplicationStep3;