import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import FourOhFourPage from './common/404Page';
import FourOhOnePage from './common/401Page';
import PageFooter from './components/common/PageFooter';

import { PageHero } from 'components/common';
import GetStarted from 'quote/aboutYou/GetStarted';
import YourDetails from 'quote/aboutYou/YourDetails';
import NeedsAssessmentStep0 from 'quote/needsAssessment/NeedsAssessmentStep0';
import NeedsAssessmentStep1 from 'quote/needsAssessment/NeedsAssessmentStep1';
import NeedsAssessmentStep2 from 'quote/needsAssessment/NeedsAssessmentStep2';
import NeedsAssessmentStep3 from 'quote/needsAssessment/NeedsAssessmentStep3';
import NeedsAssessmentStep4 from 'quote/needsAssessment/NeedsAssessmentStep4';
import NeedsAssessmentStep5 from 'quote/needsAssessment/NeedsAssessmentStep5';
import NeedsAssessmentStep6 from 'quote/needsAssessment/NeedsAssessmentStep6';
import NeedsAssessmentStep7 from 'quote/needsAssessment/NeedsAssessmentStep7';
import NeedsAssessmentStep8 from 'quote/needsAssessment/NeedsAssessmentStep8';
import SelectPolicy from 'quote/policy/SelectPolicy';
import ReviewSelection from 'quote/policy/ReviewSelection';
import { NavRoutes } from 'common/utils';
import AboutYouApplication1 from 'application/AboutYouQuestions/AboutYouApplicationStep1';
import AboutYouApplication2 from 'application/AboutYouQuestions/AboutYouApplicationStep2';
import AboutYouApplication3 from 'application/AboutYouQuestions/AboutYouApplicationStep3';
import BeneficiaryApplicationStep1 from 'application/BeneficiaryQuestions/BeneficiaryApplicationStep1';
import HealthApplicationStep1 from 'application/HealthQuestions/HealthApplicationStep1';
import HealthApplicationStep2 from 'application/HealthQuestions/HealthApplicationStep2';
import HealthApplicationStep3 from 'application/HealthQuestions/HealthApplicationStep3';
import HealthApplicationStep4 from 'application/HealthQuestions/HealthApplicationStep4';
import HealthApplicationStep5 from 'application/HealthQuestions/HealthApplicationStep5';
import HistoryApplicationStep1 from 'application/HistoryQuestions/HistoryApplicationStep1';
import LifestyleApplicationStep1 from 'application/LifestyleQuestions/LifestyleApplicationStep1';
import LifestyleApplicationStep2 from 'application/LifestyleQuestions/LifestyleApplicationStep2';
import LifestyleApplicationStep3 from 'application/LifestyleQuestions/LifestyleApplicationStep3';
import LifestyleApplicationStep4 from 'application/LifestyleQuestions/LifestyleApplicationStep4';
import LifestyleApplicationStep5 from 'application/LifestyleQuestions/LifestyleApplicationStep5';
import LifestyleApplicationStep6 from 'application/LifestyleQuestions/LifestyleApplicationStep6';
import BeneficiaryApplicationStep2 from 'application/BeneficiaryQuestions/BeneficiaryApplicationStep2';
import BeneficiaryApplicationStep3 from 'application/BeneficiaryQuestions/BeneficiaryApplicationStep3';
import ApplicationReview from 'application/reviewApplication/ApplicationReview';
import ApplicationThankYou from 'application/reviewApplication/ApplicationThankYou';
import Sign from 'application/reviewApplication/Sign';
import ApplicationNextSteps from 'application/reviewApplication/ApplicationNextSteps';
import SelectInsuranceType from 'quote/aboutYou/SelectInsuranceType';

const Routes: React.FC = () => {

	return (
		<React.Fragment>
			<PageHero />
			<Switch>
				<Route path="/401" component={FourOhOnePage} />
				<Route exact={true} path={NavRoutes.ApplicationThankYou} component={ApplicationThankYou} />
				<Route exact={true} path={NavRoutes.Sign} component={Sign} />
				<Route exact={true} path={NavRoutes.ApplicationNextSteps} component={ApplicationNextSteps} />
				<Route exact={true} path={NavRoutes.ApplicationReview} component={ApplicationReview} />
				<Route exact={true} path={NavRoutes.ApplicationHistoryStep1} component={HistoryApplicationStep1} />
				<Route exact={true} path={NavRoutes.ApplicationLifestyleStep6} component={LifestyleApplicationStep6} />
				<Route exact={true} path={NavRoutes.ApplicationLifestyleStep5} component={LifestyleApplicationStep5} />
				<Route exact={true} path={NavRoutes.ApplicationLifestyleStep4} component={LifestyleApplicationStep4} />
				<Route exact={true} path={NavRoutes.ApplicationLifestyleStep3} component={LifestyleApplicationStep3} />
				<Route exact={true} path={NavRoutes.ApplicationLifestyleStep2} component={LifestyleApplicationStep2} />
				<Route exact={true} path={NavRoutes.ApplicationLifestyleStep1} component={LifestyleApplicationStep1} />
				<Route exact={true} path={NavRoutes.ApplicationHealthStep5} component={HealthApplicationStep5} />
				<Route exact={true} path={NavRoutes.ApplicationHealthStep4} component={HealthApplicationStep4} />
				<Route exact={true} path={NavRoutes.ApplicationHealthStep3} component={HealthApplicationStep3} />
				<Route exact={true} path={NavRoutes.ApplicationHealthStep2} component={HealthApplicationStep2} />
				<Route exact={true} path={NavRoutes.ApplicationHealthStep1} component={HealthApplicationStep1} />
				<Route exact={true} path={NavRoutes.ApplicationBeneficiariesStep1} component={BeneficiaryApplicationStep1} />
				<Route exact={true} path={NavRoutes.ApplicationBeneficiariesStep2} component={BeneficiaryApplicationStep2} />
				<Route exact={true} path={NavRoutes.ApplicationBeneficiariesStep3} component={BeneficiaryApplicationStep3} />
				<Route exact={true} path={NavRoutes.ApplicationAboutYouStep1} component={AboutYouApplication1} />
				<Route exact={true} path={NavRoutes.ApplicationAboutYouStep2} component={AboutYouApplication2} />
				<Route exact={true} path={NavRoutes.ApplicationAboutYouStep3} component={AboutYouApplication3} />
				<Route exact={true} path={NavRoutes.QuoteReviewPolicy} component={ReviewSelection} />
				<Route exact={true} path={NavRoutes.QuoteSelectPolicy} component={SelectPolicy} />
				<Route exact={true} path={NavRoutes.QuoteNeedsAssessmentStep8} component={NeedsAssessmentStep8} />
				<Route exact={true} path={NavRoutes.QuoteNeedsAssessmentStep7} component={NeedsAssessmentStep7} />
				<Route exact={true} path={NavRoutes.QuoteNeedsAssessmentStep6} component={NeedsAssessmentStep6} />
				<Route exact={true} path={NavRoutes.QuoteNeedsAssessmentStep5} component={NeedsAssessmentStep5} />
				<Route exact={true} path={NavRoutes.QuoteNeedsAssessmentStep4} component={NeedsAssessmentStep4} />
				<Route exact={true} path={NavRoutes.QuoteNeedsAssessmentStep3} component={NeedsAssessmentStep3} />
				<Route exact={true} path={NavRoutes.QuoteNeedsAssessmentStep2} component={NeedsAssessmentStep2} />
				<Route exact={true} path={NavRoutes.QuoteNeedsAssessmentStep1} component={NeedsAssessmentStep1} />
				<Route exact={true} path={NavRoutes.QuoteNeedsAssessmentStep0} component={NeedsAssessmentStep0} />
				<Route exact={true} path={NavRoutes.QuoteYourDetails} component={YourDetails} />
				<Route exact={true} path={NavRoutes.QuoteAboutYou} component={GetStarted} />
				<Route exact={true} path={NavRoutes.QuoteSelectInsuranceType} component={SelectInsuranceType} />

				<Route path="*" component={FourOhFourPage} />
			</Switch>
			<PageFooter />
		</React.Fragment>
	);
};

export default Routes;
