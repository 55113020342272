import React from 'react';
import { LoadContainer, QuoteContainer } from 'components/cpaipw';
import { FormContext } from 'quote/FormContext';
import { preventDefault, QuotePages } from 'common/utils';
import { getNearest10k, MyPolicy, PoliciesIds, PolicyParams, QuoteFromOliver } from './utils';
import { PolicyActionContext, PolicyDataContext } from './PolicyContext';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import oliverAPI from 'api/OliverAPI';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from './useConstant';
import { useAsyncCallback } from 'react-async-hook';
import { CustomerPolicyCard } from './CustomerPolicyCard';
import { SideBarComponent } from './SideBarComponent';

interface IProps { }

const SelectPolicy: React.FC<IProps> = (props: IProps) => {
	const location = useLocation();
	const FormCtx = React.useContext(FormContext);
	const PolicyActionCtx = React.useContext(PolicyActionContext);
	const PolicyDataCtx = React.useContext(PolicyDataContext);
	const [quote, setQuote] = React.useState<QuoteFromOliver[]>([]);
	const [products, setProducts] = React.useState<OliverProductResponseDTO[] | undefined>(undefined);
	const [isLoadingProducts, setIsLoadingProducts] = React.useState<boolean>(true);
	const [isDropdownVisible, setDropdownVisible] = React.useState(false);

	//For Spouse
	const [spouseQuote, setSpouseQuote] = React.useState<QuoteFromOliver[]>([]);
	const [isSpouseDropdownVisible, setSpouseDropdownVisible] = React.useState(false);
	const [spouseChildrenCI, setSpouseChildrenCI] = React.useState(false);
	const [cpaChildrenCI, setCpaChildrenCI] = React.useState(false);
	const [spouseDependentLife, setSpouseDependentLife] = React.useState(false);
	const [cpaDependentLife, setCpaDependentLife] = React.useState(false);
	const isSpouseIncluded = FormCtx.spouseApplicantId !== undefined && FormCtx.spouseApplicantId !== '';
	const [hasInitializedPolicies, setHasInitializedPolicies] = React.useState(false);

	const methods = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
	});

	React.useEffect(() => {
		if (PolicyDataCtx.isDirty === false) {
			PolicyActionCtx.setIsDirty(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [PolicyDataCtx.policy]);

	React.useEffect(() => {
		if (isSpouseIncluded && PolicyDataCtx.spouseIsDirty === false) {
			PolicyActionCtx.setSpouseIsDirty(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [PolicyDataCtx.spousePolicy, isSpouseIncluded]);


	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);
		handleDebouncedAsync.execute();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (hasInitializedPolicies === true) {
			if (PolicyDataCtx.policy.length === 0) {
				PolicyActionCtx.setQuoteData({});
				const quote = PolicyActionCtx.createQuoteData({});
				setQuote(quote);
			}
			if (PolicyDataCtx.spousePolicy.length === 0) {
				PolicyActionCtx.setSpouseQuoteData({});
				const spouseQuote = PolicyActionCtx.spouseCreateQuoteData({});
				setSpouseQuote(spouseQuote);
			}
		}
		setSpouseChildrenCI(PolicyDataCtx.spousePolicy.some(policy => policy.policyId === PoliciesIds.cich));
		setCpaChildrenCI(PolicyDataCtx.policy.some(policy => policy.policyId === PoliciesIds.cich));
		setSpouseDependentLife(PolicyDataCtx.spousePolicy.some(policy => policy.policyId === PoliciesIds.deplife));
		setCpaDependentLife(PolicyDataCtx.policy.some(policy => policy.policyId === PoliciesIds.deplife));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [PolicyDataCtx.policy, PolicyDataCtx.spousePolicy]);

	React.useEffect(() => {

		if (!hasInitializedPolicies) {
			const calculations = FormCtx.calculateTotal();
			const state = location.state as { updatePolicy?: boolean };
			const updatePolicy = state?.updatePolicy !== undefined ? state?.updatePolicy : false;
			if (PolicyDataCtx.policy.length === 0 || updatePolicy === true) {
				const total = getNearest10k(calculations.total);
				const recommendation = (total > 2000000) ? 2000000 : (total <= 10000) ? 10000 : total;
				PolicyActionCtx.policyDispatch(
					{
						policyId: PoliciesIds.life,
						parameters: {
							coverageAmount: FormCtx.skippedNeedsAssessment === false ? recommendation.toString() : '1000000',
						},
					}
				);
			};
			if (PolicyDataCtx.spousePolicy.length === 0 || updatePolicy === true) {
				PolicyActionCtx.spousePolicyDispatch(
					{
						policyId: PoliciesIds.life,
						parameters: {
							coverageAmount: '1000000',
						},
					}
				);
			};
			setHasInitializedPolicies(true);
		}
		handleDebouncedAsync.execute();
		handleSpouseDebouncedAsync.execute();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		async function getProducts() {
			const products = await oliverAPI.getOliverProducts();
			setProducts(products);
			setIsLoadingProducts(false);
		}
		getProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const asyncFunctionDebounced = useConstant(() => AwesomeDebouncePromise(
		getPremium,
		1000,
		{ key: () => 'policy' }
	));
	const handleDebouncedAsync = useAsyncCallback(asyncFunctionDebounced);

	//For Spouse
	const asyncSpouseFunctionDebounced = useConstant(() => AwesomeDebouncePromise(
		getSpousePremium,
		1000,
		{ key: () => 'spousePolicy' }
	));
	const handleSpouseDebouncedAsync = useAsyncCallback(asyncSpouseFunctionDebounced);

	// Create a filtered list of products that are not already added to the policy
	const isProductAdded = (productId: string) => {
		return PolicyDataCtx.policy.some(policy => policy.policyId === productId);
	};

	const availableProducts = products?.filter(product => product.id && !isProductAdded(product.id));


	function handleMenuClick(e: any) {
		const productId = e.key;
		if (!isProductAdded(productId)) {
			const policy: MyPolicy = {
				policyId: productId as PoliciesIds
			};
			PolicyActionCtx.policyDispatch(policy);
		}
		setDropdownVisible(false);
	}

	// Create a filtered list of products that are not already added to the policy - For Spouse
	const isSpouseProductAdded = (productId: string) => {
		return PolicyDataCtx.spousePolicy.some(policy => policy.policyId === productId);
	};

	const availableSpouseProducts = products?.filter(product => product.id && !isSpouseProductAdded(product.id));

	function handleSpouseMenuClick(e: any) {
		const productId = e.key;
		if (!isSpouseProductAdded(productId)) {
			const policy: MyPolicy = {
				policyId: productId as PoliciesIds
			};
			PolicyActionCtx.spousePolicyDispatch(policy);
		}
		setSpouseDropdownVisible(false);
	}

	function loadPolicy() {
		handleDebouncedAsync.execute();
		isSpouseIncluded &&
			handleSpouseDebouncedAsync.execute();
	};

	return (
		<FormProvider {...methods}>
			<QuoteContainer largeColumn={true} sidebar={
				<SideBarComponent
					primaryQuote={quote}
					spouseQuote={spouseQuote}
					isPrimaryQuoteLoading={handleDebouncedAsync.loading}
					isSpouseQuoteLoading={handleSpouseDebouncedAsync.loading}
					isLoadingProducts={isLoadingProducts}
					loadPolicies={loadPolicy}
				/>
			}
			>
				<LoadContainer isLoading={isLoadingProducts}>
					<form onSubmit={preventDefault}>
						<div className="products">
							<CustomerPolicyCard
								availableProducts={availableProducts ?? []}
								handleMenuClick={handleMenuClick}
								isDropdownVisible={isDropdownVisible}
								setDropdownVisible={setDropdownVisible}
								products={products}
								selectedPolicies={PolicyDataCtx.policy}
								debouncedRequest={handleDebouncedAsync.execute}
								passValueToDispatch={passValueToDispatch}
								isSpouse={false}
								spouseChildrenCI={spouseChildrenCI}
								cpaChildrenCI={cpaChildrenCI}
								spouseDependentLife={spouseDependentLife}
								cpaDependentLife={cpaDependentLife}
								loadPolicies={loadPolicy}
							/>
						</div>
						{isSpouseIncluded &&
							<div className="products mt-4">
								<CustomerPolicyCard
									availableProducts={availableSpouseProducts ?? []}
									handleMenuClick={handleSpouseMenuClick}
									isDropdownVisible={isSpouseDropdownVisible}
									setDropdownVisible={setSpouseDropdownVisible}
									products={products}
									selectedPolicies={PolicyDataCtx.spousePolicy}
									debouncedRequest={handleSpouseDebouncedAsync.execute}
									passValueToDispatch={passSpouseValueToDispatch}
									isSpouse={isSpouseIncluded}
									spouseChildrenCI={spouseChildrenCI}
									cpaChildrenCI={cpaChildrenCI}
									spouseDependentLife={spouseDependentLife}
									cpaDependentLife={cpaDependentLife}
									loadPolicies={loadPolicy}
								/>
							</div>
						}
					</form>
					<div className="d-block d-lg-none pl-0 pl-md-2 mt-lg-0 mt-4">
						<SideBarComponent
							primaryQuote={quote}
							spouseQuote={spouseQuote}
							isPrimaryQuoteLoading={handleDebouncedAsync.loading}
							isSpouseQuoteLoading={handleSpouseDebouncedAsync.loading}
							isLoadingProducts={isLoadingProducts}
							loadPolicies={loadPolicy}
						/>
					</div>
				</LoadContainer>
			</QuoteContainer>
		</FormProvider>
	);

	function passValueToDispatch(data: any) {
		PolicyActionCtx.policyDispatch(data);
	}

	function passSpouseValueToDispatch(data: any) {
		PolicyActionCtx.spousePolicyDispatch(data);
	}

	function createRequest(values: any) {
		const keys = Object.keys(values);
		const params = keys.map((key: string) => {
			return {
				policyId: key,
				parameters: values[key] as PolicyParams | undefined,
			};
		}) as MyPolicy[];
		passValueToDispatch(params);
		const request = PolicyActionCtx.createRequest(params);
		return request;
	}

	function createSpouseRequest(values: any) {
		const keys = Object.keys(values);
		const params = keys.map((key: string) => {
			return {
				policyId: key,
				parameters: values[key] as PolicyParams | undefined,
			};
		}) as MyPolicy[];
		passSpouseValueToDispatch(params);
		const request = PolicyActionCtx.spouseCreateRequest(params);
		return request;
	}

	async function getPremium() {
		const isValid = await methods.trigger();
		const values = methods.getValues().policy;

		if (values?.CI !== undefined && values?.CI?.coverageAmount === '0' && values?.CI?.applyToChildren !== 'yes') {
			methods.setError('policy.CI.coverageAmount', { message: 'A coverage amount of $0 is only possible when coverage for children is enabled.' });
			return;
		}

		if (isValid === false) {
			return;
		}
		if (values?.CI !== undefined && values?.CI?.applyToChildren === 'yes') {
			values[PoliciesIds.cich] = {
				//// This is the default coverage amount for
				//// adding children to Critical Illness
				coverageAmount: 10000,
			};
		}
		const request = createRequest(values);
		const fetchedData = await oliverAPI.getQuote(request);

		PolicyActionCtx.setQuoteData(fetchedData);
		const quote = PolicyActionCtx.createQuoteData(fetchedData);
		setQuote(quote);
		PolicyActionCtx.setIsDirty(false);
	}

	async function getSpousePremium() {
		const isValid = await methods.trigger();
		const spouseValues = methods.getValues().spousePolicy;

		if (spouseValues?.CI !== undefined && spouseValues?.CI?.coverageAmount === '0' && spouseValues?.CI?.applyToChildren !== 'yes') {
			methods.setError('policy.CI.coverageAmount', { message: 'A coverage amount of $0 is only possible when coverage for children is enabled.' });
			return;
		}
		if (isValid === false) {
			return;
		}
		if (spouseValues?.CI !== undefined && spouseValues?.CI?.applyToChildren === 'yes') {
			spouseValues[PoliciesIds.cich] = {
				//// This is the default coverage amount for
				//// adding children to Critical Illness
				coverageAmount: 10000,
			};
		}
		const spouseRequest = createSpouseRequest(spouseValues);
		const spouseFetchedData = await oliverAPI.getQuote(spouseRequest);

		PolicyActionCtx.setSpouseQuoteData(spouseFetchedData);
		const spouseQuote = PolicyActionCtx.spouseCreateQuoteData(spouseFetchedData);
		setSpouseQuote(spouseQuote);
		PolicyActionCtx.setSpouseIsDirty(false);
	}

};



export default SelectPolicy;
