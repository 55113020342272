import React from 'react';
import classNames from 'classnames';

interface IProps {
	currentstep: number;
	totalSteps: number;
}

export const Steps: React.FC<IProps> = ( props: IProps ) => {
	const steps = [];

	for (let i=1; i <= props.totalSteps; i++) {
		steps.push(
			<div
				key={`step-${i}`}
				className={
					classNames(
						'd-flex flex-fill pt-1',
						i === props.currentstep && 'bg-secondary',
						i > props.currentstep && 'bg-gray-200',
						i < props.currentstep && 'bg-secondary-dark',
						i < props.totalSteps && 'mr-1',
					)
				}
			/>
		);
	}

	return (
		<React.Fragment>
			<p className="mb-1 font-weight-bold">Step {props.currentstep} of {props.totalSteps}</p>
			<div className="d-flex flex-row justify-content-between rounded overflow-hidden mb-4">
				{steps}
			</div>
		</React.Fragment>
	);
};
