import React from 'react';
import { FormContext } from 'quote/FormContext';
import { FormButtons, InfoBox, QuoteContainer, Steps } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { NavRoutes, QuotePages } from 'common/utils';
import { ReactComponent as IconDebts } from 'assets/icons/icon-expenses.svg';

interface IProps {
	location: any;
}

const NeedsAssessmentStep2: React.FC<IProps> = () => {
	const FormCtx = React.useContext(FormContext);
	const history = useHistory();

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<QuoteContainer>
			<IconDebts className="mb-3" />
			<h3>Financial Standing – Debts and Expenses</h3>
			<Steps currentstep={2} totalSteps={3} />
			<InfoBox title="How is a Life Policy Calculated?" className="mb-4">
				In order to provide your Life Insurance
				policy estimate, we need to ask you about your current financial standing and foreseeable expenses.
			</InfoBox>
			<FormButtons
				onClickBack={() => history.push(NavRoutes.QuoteNeedsAssessmentStep1)}
				onClickNext={() => history.push(NavRoutes.QuoteNeedsAssessmentStep3)}
			/>
		</QuoteContainer>
	);
};
export default NeedsAssessmentStep2;
