import { FormContext } from 'quote/FormContext';
import React from 'react';
import { MyPolicy, PoliciesIds, QuoteFromOliver } from './utils';


interface IPolicyDataContext {
	policy: MyPolicy[];
	quoteData: OliverQuoteResponseDTO;
	isDirty: boolean;
	hasChildDL: boolean;
	hasChildCI: boolean;
	spousePolicy: MyPolicy[];
	spouseQuoteData: OliverQuoteResponseDTO,
	spouseIsDirty: boolean,
	spouseHasChildDLorCI: boolean,
}

interface IPolicyActionContext {
	policyDispatch: React.Dispatch<MyPolicy>;
	createRequest: (policy: MyPolicy[]) => any;
	createQuoteData: (data: OliverQuoteResponseDTO) => QuoteFromOliver[];
	setQuoteData: (data: OliverQuoteResponseDTO) => any;
	productTitle: (id: string | undefined) => string;
	setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
	checkAndSetHasChildDL: () => void;
	checkAndSetHasChildCI: () => void;
	spousePolicyDispatch: React.Dispatch<MyPolicy>;
	spouseCreateRequest: (policy: MyPolicy[]) => any;
	spouseCreateQuoteData: (data: OliverQuoteResponseDTO) => QuoteFromOliver[];
	setSpouseQuoteData: (data: OliverQuoteResponseDTO) => any;
	spouseProductTitle: (id: string | undefined) => string;
	setSpouseIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
	spouseCheckAndSetHasChildDLorCL: () => void;
}

const PolicyActionContext = React.createContext<IPolicyActionContext>({
	policyDispatch: () => [],
	createRequest: (data: MyPolicy[]) => { },
	createQuoteData: (data: OliverQuoteResponseDTO) => [],
	setQuoteData: (data: OliverQuoteResponseDTO) => { },
	productTitle: (id: string | undefined) => '',
	setIsDirty: () => { },
	checkAndSetHasChildDL: () => undefined,
	checkAndSetHasChildCI: () => undefined,
	spousePolicyDispatch: () => [],
	spouseCreateRequest: (data: MyPolicy[]) => { },
	spouseCreateQuoteData: (data: OliverQuoteResponseDTO) => [],
	setSpouseQuoteData: (data: OliverQuoteResponseDTO) => { },
	spouseProductTitle: (id: string | undefined) => '',
	setSpouseIsDirty: () => { },
	spouseCheckAndSetHasChildDLorCL: () => undefined,
});

const PolicyDataContext = React.createContext<IPolicyDataContext>({
	policy: [],
	quoteData: {},
	isDirty: true,
	hasChildDL: false,
	hasChildCI: false,
	spousePolicy: [],
	spouseQuoteData: {},
	spouseIsDirty: true,
	spouseHasChildDLorCI: false,
});

const PolicyProvider = (props: any) => {
	const [selectedPolicies, dispatch] = React.useReducer(handlePolicyState, []);
	const [quoteData, setQuoteData] = React.useState<OliverQuoteResponseDTO>({});
	const [isDirty, setIsDirty] = React.useState<boolean>(true);
	const [hasChildDL, setHasChildDL] = React.useState<boolean>(true);
	const [hasChildCI, setHasChildCI] = React.useState<boolean>(true);
	const [spouseSelectedPolicies, spouseDispatch] = React.useReducer(handlePolicyState, []);
	const [spouseQuoteData, setSpouseQuoteData] = React.useState<OliverQuoteResponseDTO>({});
	const [spouseIsDirty, setSpouseIsDirty] = React.useState<boolean>(true);
	const [spouseHasChildDLorCI, setSpouseHasChildDLorCI] = React.useState<boolean>(true);
	const formCtx = React.useContext(FormContext);


	const createRequest = (policyData: MyPolicy[]): QuoteRequest => {

		const updatedPolicyData = policyData.map(policy => ({
			id: policy.policyId,
			option: createProductOption(policy),
		}));
		return {
			quote: {
				applicant_id: formCtx.applicantId, //99201
				products: updatedPolicyData,
			}
		} as QuoteRequest;
	};

	const productTitle = (id: string | undefined) => {
		let title = '';
		if (id !== undefined) {
			switch (id) {
				case PoliciesIds.life:
					title = 'Term Life';
					break;
				case PoliciesIds.ltd:
					title = 'Long Term Disability';
					break;
				case PoliciesIds.officeOH:
					title = 'Office Overhead';
					break;
				case PoliciesIds.deplife:
					title = 'Dependent Life';
					break;
				case PoliciesIds.ci:
					title = 'Critical Illness';
					break;
				case PoliciesIds.cich:
					title = 'Critical Illness - Child(ren)';
					break;
				case PoliciesIds.add:
					title = 'Accidental Death and Dismemberment';
					break;
				default:
					break;
			}
		}
		return title;
	};


	const createQuoteData = (quote: OliverQuoteResponseDTO) => {
		let quoteData = [] as QuoteFromOliver[];
		if (quote.products !== undefined && quote.products.length > 0) {
			quoteData = quote.products.map(prod => {
				return {
					id: prod.id as PoliciesIds,
					title: productTitle(prod.id),
					premium: prod.option?.premium,
					parameters: {
						coverageAmount: prod.option?.coverage,
						waitingPeriod: prod.option?.waiting_period,
					}
				} as QuoteFromOliver;
			});
		}

		return quoteData;
	};

	const checkAndSetHasChildDL = () => {
		const hasChildDLPolicy = (selectedPolicies.findIndex(p => p.policyId === PoliciesIds.deplife) !== -1);
		setHasChildDL(hasChildDLPolicy);
	};

	const checkAndSetHasChildCI = () => {
		let hasChildCIPolicy = false;
		const founndApplicantCI = selectedPolicies.find(p => p.policyId === PoliciesIds.ci);
		if (founndApplicantCI !== undefined && founndApplicantCI.parameters !== undefined) {
			hasChildCIPolicy = founndApplicantCI.parameters.applyToChildren === 'yes';
		}

		setHasChildCI(hasChildCIPolicy);
	};

	const spouseCreateRequest = (policyData: MyPolicy[]): QuoteRequest => {
		const updatedPolicyData = policyData.map(policy => ({
			id: policy.policyId,
			option: createProductOption(policy),
		}));
		return {
			quote: {
				// applicant_id: formCtx.spouseApplicantId, //99201
				applicant_id: formCtx.spouseApplicantId, //99201
				products: updatedPolicyData,
			}
		} as QuoteRequest;
	};

	const spouseCheckAndSetHasChildDLorCL = () => {
		const hasDL = (spouseSelectedPolicies.findIndex(p => p.policyId === PoliciesIds.deplife) !== -1);
		let hasChildCI = false;
		const founndApplicantCI = spouseSelectedPolicies.find(p => p.policyId === PoliciesIds.ci);
		if (founndApplicantCI !== undefined && founndApplicantCI.parameters !== undefined) {
			hasChildCI = founndApplicantCI.parameters.applyToChildren === 'yes';
		}
		const hasChildPolicy = (hasDL || hasChildCI);

		setSpouseHasChildDLorCI(hasChildPolicy);
	};

	return (
		<PolicyDataContext.Provider value={{
			policy: selectedPolicies,
			quoteData,
			isDirty,
			hasChildDL,
			hasChildCI,
			spousePolicy: spouseSelectedPolicies,
			spouseQuoteData,
			spouseIsDirty,
			spouseHasChildDLorCI
		}}>
			<PolicyActionContext.Provider value={{
				policyDispatch: dispatch,
				createRequest,
				createQuoteData,
				setQuoteData,
				productTitle,
				setIsDirty,
				checkAndSetHasChildDL,
				checkAndSetHasChildCI,
				spousePolicyDispatch: spouseDispatch,
				spouseCreateRequest,
				spouseCreateQuoteData: createQuoteData,
				setSpouseQuoteData,
				spouseProductTitle: productTitle,
				setSpouseIsDirty,
				spouseCheckAndSetHasChildDLorCL,
			}}>
				{props.children}
			</PolicyActionContext.Provider>
		</PolicyDataContext.Provider>
	);

	function getPeriodFromTextValue(value: string | undefined) {
		let period = 0;
		if (value !== undefined) {
			switch (value) {
				case 'ILTD30':
					period = 30;
					break;
				case 'ILTD90':
					period = 90;
					break;
				case 'ILTD120':
					period = 120;
					break;
				case 'ILTD180':
					period = 180;
					break;
				case 'ILTD365':
					period = 365;
					break;
				default:
					break;
			}
		}
		return period;
	}

	function getCoverageFromTextValue(value: string | undefined) {
		let coverage = 0;
		if (value !== undefined) {
			switch (value) {
				case 'IDLIF10/10':
					coverage = 10000;
					break;
				case 'IDLIF15/15':
					coverage = 15000;
					break;
				case 'IDLIF20/20':
					coverage = 20000;
					break;
				case 'IDLIF25/25':
					coverage = 25000;
					break;
				default:
					break;
			}
		}
		return coverage;
	}

	function handlePolicyState(state: MyPolicy[], data: MyPolicy | MyPolicy[]) {
		if (Array.isArray(data)) {
			return data;
		}
		const arrPos = state.findIndex(val => val.policyId === data.policyId);
		if (arrPos !== -1 && data.parameters === undefined) {
			if (state.length === 1) {
				return [];
			} else {
				const filterArr = state.filter((val) => val.policyId !== data.policyId);
				return [...filterArr];
			}
		} else {
			const filterArr = state.filter((val) => val.policyId !== data.policyId);
			return [...filterArr, data];
		}
	}

	function createProductOption(policy: MyPolicy): QuoteProductOptionsDTO {
		let option = {} as QuoteProductOptionsDTO;
		// create method to map data to oliver option dto
		// use policy id cause its enum to create other type of data
		switch (policy.policyId) {
			case PoliciesIds.life:
				option = {
					id: 'ITERMLIFE',
					coverage: policy.parameters?.coverageAmount,
					type: 'AGE',
				} as QuoteProductOptionsDTO;
				break;
			case PoliciesIds.ltd:
				option = {
					id: createLongTermDisabilityOptionsId(policy),
					coverage: policy.parameters?.coverageAmount,
					type: 'AGE',
					waiting_period: getPeriodFromTextValue(policy.parameters?.waitingPeriod),
					waiting_period_option: 'IMMEDIATE',
				} as QuoteProductOptionsDTO;
				break;
			case PoliciesIds.officeOH:
				option = {
					id: 'IOFFICEOH',
					coverage: policy.parameters?.coverageAmount,
					type: 'AGE',
				} as QuoteProductOptionsDTO;
				break;
			case PoliciesIds.deplife:
				option = {
					id: createDependencyOptionsId(policy),
					coverage: getCoverageFromTextValue(policy.parameters?.coverageAmount),
					type: 'VOLUME',
				} as QuoteProductOptionsDTO;
				break;
			case PoliciesIds.ci:
				option = {
					id: 'ICI',
					coverage: policy.parameters?.coverageAmount,
					type: 'AGE',
					// apply_to_all_children: policy.parameters?.applyToChildren === 'yes'
				} as QuoteProductOptionsDTO;
				break;
			case PoliciesIds.cich:
				option = {
					id: 'ICIChild',
					coverage: policy.parameters?.coverageAmount,
					type: 'VOLUME',
				} as QuoteProductOptionsDTO;
				break;
			case PoliciesIds.add:
				option = {
					id: 'IADD',
					coverage: policy.parameters?.coverageAmount,
					type: 'VOLUME',
				} as QuoteProductOptionsDTO;
				break;
			default:
				break;
		}
		return option;

	}

	function createLongTermDisabilityOptionsId(policy: MyPolicy) {
		return policy.parameters?.waitingPeriod;
	}

	function createDependencyOptionsId(policy: MyPolicy) {
		return policy.parameters?.coverageAmount;
	}
};

export {
	PolicyActionContext,
	PolicyDataContext,
};

export default PolicyProvider;