import classNames from 'classnames';
import React from 'react';

interface IProps {
	className?: string;
}

export const Divider: React.FC<IProps> = ( props: IProps ): JSX.Element => {

	return (
		<div className={classNames('pb-1 w-100 border-top border-top-gray-400', props.className ? props.className : 'mb-4')} />
	);
};
