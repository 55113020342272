import React from 'react';
import { RadioButtonGroup } from 'components/cpaipw/RadioButtonGroup';
import { FormContext, IExpenses, INeedsAssessment } from 'quote/FormContext';
import { useForm, Controller } from 'react-hook-form';
import { FormButtons, QuoteContainer, InfoMessage, Steps, InfoBox, AmountField, NeedsAssessmentSidebarCard, AmountInput } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { NavRoutes, QuotePages, ValidationMessages } from 'common/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { ReactComponent as IconExpenses } from 'assets/icons/icon-expenses.svg';

interface IProps {
	location: any;
}

const NeedsAssessmentStep6: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);
	const history = useHistory();

	const { control, handleSubmit, errors, watch } = useForm({
		defaultValues: FormCtx.needsAssessment,
	});
	const onSubmit = (values: INeedsAssessment) => {

		if ( values.expenses === undefined ) {
			values.expenses = {};
		}
		if ( values.hasWantChildren === 'no' ) {
			values.numberChildren = '0';
		}

		expensesFields.forEach((item) => {
			if ( values.expenses !== undefined && (values.expenses[item.name] === undefined || values.expenses[item.name] === '')) {
				values.expenses[item.name] = '0';
			}
		});

		if (!FormCtx.needsAssessmentOnly) {
			FormCtx.upsertFormValues(values);
		}

		values.step = 4;
		FormCtx.setNeedsAssessment({...FormCtx.needsAssessment, ...values});

		history.push(NavRoutes.QuoteNeedsAssessmentStep7);
	};

	const watchHasWantChildren = watch('hasWantChildren');
	const watchPayFuneral = watch('payFuneral');

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<QuoteContainer sidebar={<NeedsAssessmentSidebarCard />}>
			<form>
				<IconExpenses className="mb-3" />
				<h3>Forseeable Expenses</h3>
				<Steps currentstep={2} totalSteps={3} />
				<InfoBox title="Financial Goals" />
				<InfoMessage>
					These are one-time expenses related to your stage of life and life plan.
				</InfoMessage>
				<p>
					Your financial goals can still be looked after with proper planning, so in the event that you pass,
					your debt can be paid back in full, and you can plan to provide additional money to be used by your beneficiaries.
				</p>
				<p>
					To calculate the amount of coverage you need to pay your additional expenses, please answer the following questions accordingly:
				</p>

				<div className="form-group">
					<label>Do you have children or plan to have children?</label>
					<Controller
						name="hasWantChildren"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value, name }) =>
							<RadioButtonGroup
								onChange={onChange}
								value={value}
								name={name}
								options={[
									{
										label: 'Yes',
										value: 'yes',
									},
									{
										label: 'No',
										value: 'no',
									},
								]}
							/>
						}
					/>
					<ErrorMessage>{errors?.hasWantChildren?.message}</ErrorMessage>
				</div>
				{watchHasWantChildren === 'yes' && (
					<React.Fragment>
						<div className="form-group">
							<label>How many children do you have and/or planning to have?</label>
							<Controller
								name="numberChildren"
								control={control}
								rules={{
									required: ValidationMessages.required,
									validate: val => (isNaN(val) || val <= 0) ? ValidationMessages.number : true,
								}}
								render={({ onChange, value, name }) =>
									<AmountInput
										onChange={onChange}
										value={value}
										name={name}
									/>
								}
							/>
							<ErrorMessage>{errors?.numberChildren?.message}</ErrorMessage>
						</div>
						<div className="form-group">
							<label>What total amount of money will you be putting towards each of the following?</label>
							<InfoMessage>
								We’ve estimated the average costs to help you with your decision.
							</InfoMessage>
							{expensesFields.filter(v => v.name !== 'funeral').map((item, index: number) => {
								return (
									<React.Fragment key={`${item.name}-${index}`}>
										<AmountField
											prepend="$"
											item={item}
											index={index}
										>
											<Controller
												name={`expenses.${item.name}`}
												control={control}
												rules={{
													validate: val => ((val !== undefined && val !== null && val !== '') && isNaN(val)) ? ValidationMessages.number : true,
												}}
												render={({ onChange, value, name }) =>
													<AmountInput
														onChange={onChange}
														value={value}
														name={name}
													/>
												}
											/>
										</AmountField>
										<ErrorMessage>{errors?.expenses?.[item.name]?.message}</ErrorMessage>
									</React.Fragment>
								);
							})}
						</div>
					</React.Fragment>
				)}
				<div className="form-group">
					<label>Would you like to leave money for your funeral?</label>
					<Controller
						name="payFuneral"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value, name }) =>
							<RadioButtonGroup
								onChange={onChange}
								value={value}
								name={name}
								options={[
									{
										label: 'Yes',
										value: 'yes',
									},
									{
										label: 'No',
										value: 'no',
									},
								]}
							/>
						}
					/>
					<ErrorMessage>{errors?.payFuneral?.message}</ErrorMessage>
				</div>
				{watchPayFuneral === 'yes' && (
					<div className="form-group">
						<label>How much money would you like to leave for your funeral?</label>
						{expensesFields.filter(v => v.name === 'funeral').map((item, index: number) => {
							return (
								<React.Fragment key={`${item.name}-${index}`}>
									<AmountField
										prepend="$"
										item={item}
										index={index}
									>
										<Controller
											name={`expenses.${item.name}`}
											control={control}
											rules={{
												validate: val => isNaN(val) ? ValidationMessages.number : true,
											}}
											render={({ onChange, value, name }) =>
												<AmountInput
													onChange={onChange}
													value={value}
													name={name}
												/>
											}
										/>
									</AmountField>
									<ErrorMessage>{errors?.expenses?.[item.name]?.message}</ErrorMessage>
								</React.Fragment>
							);
						})}
					</div>
				)}
				<FormButtons
					onClickBack={() => history.push(NavRoutes.QuoteNeedsAssessmentStep5)}
					onClickNext={handleSubmit(onSubmit)}
				/>
			</form>
			<div className="d-block d-lg-none">
				<NeedsAssessmentSidebarCard />
			</div>
		</QuoteContainer>
	);
};

const expensesFields = [
	{
		label: 'Funeral',
		name: 'funeral',
		description: 'Avg. $9,000',
	},
	{
		label: 'Tuition',
		name: 'tuition',
		description: 'Avg. 4-Year Undergraduate Program $26,772',
	},
	{
		label: 'Wedding',
		name: 'wedding',
		description: 'Avg. $30,000',
	},
	{
		label: 'Legacy',
		name: 'legacy',
		description: 'Amount is a personal choice',
	},
] as {
	label: string;
	name: keyof IExpenses;
	description?: string;
}[];
export default NeedsAssessmentStep6;
