import React from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as IconTooltip } from 'assets/icons/icon-tooltip.svg';
import { Button } from 'components/buttons';
import { tooltipText } from './utils';

interface IProps {
	item: any;
}

export const PolicyReviewItem: React.FC<IProps> = ( props: IProps ) => {
	const { title, premium, parameters } = props.item;
	const [waitingPeriodTooltipOpen, setWaitingPeriodTooltipOpen] = React.useState<boolean>(false);
	const [applyToChildrenTooltipOpen, setApplyToChildrenTooltipOpen] = React.useState<boolean>(false);

	return (
		<React.Fragment>
			<div className="d-flex flex-row justify-content-between px-3 py-2 bg-gray-100 rounded-sm mb-2 mt-4">
				<h5 className="h5 mb-0">{title}</h5>
				<div className="d-flex align-items-end">
					<h5 className="mb-0 mr-1">${premium ? premium.toFixed(2).toLocaleString() : 0.00}</h5>
					<h6 className="mb-0">/mo</h6>
				</div>
			</div>
			<div className="row px-3">
				{parameters.coverageAmount && (
					<div className="col-4 d-flex flex-column">
						<span className="mb-1 font-weight-bold">Coverage Amount</span>
						<span>${parseFloat(parameters.coverageAmount).toLocaleString('en')}</span>
					</div>
				)}
				{parameters.waitingPeriod !== '0' && parameters.waitingPeriod !== 0 && (
					<div className="col-4 d-flex flex-column">
						<div className="mb-1 d-flex align-items-center">
							<span className="font-weight-bold">Waiting Period</span>
							<Tooltip visible={waitingPeriodTooltipOpen} title={tooltipText.waitingPeriod}>
								<Button className="btn-icon p-2 my-n2" onClick={toggleWaitingPeriodTooltip}>
									<IconTooltip className="tooltip-icon" />
								</Button>
							</Tooltip>
						</div>
						<span>{parameters.waitingPeriod} days</span>
					</div>
				)}
				{parameters.childCoverageAmount && (
					<div className="col-4 d-flex flex-column">
						<div className="mb-1 d-flex align-items-center">
							<span className="font-weight-bold">Child(ren) Coverage</span>
						</div>
						<span>${parseFloat(parameters.childCoverageAmount).toLocaleString('en')}</span>
					</div>
				)}
				{parameters.applyToChildren && (
					<div className="col-4 d-flex flex-column">
						<div className="mb-1 d-flex align-items-center">
							<span className="font-weight-bold">Apply to Children</span>
							<Tooltip visible={applyToChildrenTooltipOpen} title={tooltipText.applyToChildren}>
								<Button className="btn-icon p-2 my-n2" onClick={toggleApplyToChildrenTooltip}>
									<IconTooltip className="tooltip-icon" />
								</Button>
							</Tooltip>
						</div>
						<span>{parameters.applyToChildren}</span>
					</div>
				)}
			</div>
		</React.Fragment>
	);
	function toggleWaitingPeriodTooltip() {
		setWaitingPeriodTooltipOpen(v => !v);
	}
	function toggleApplyToChildrenTooltip() {
		setApplyToChildrenTooltipOpen(v => !v);
	}
};
