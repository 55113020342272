import { SubmissionContext } from 'application/SubmissionContext';
import { ApplicationPages, NavRoutes, preventDefault } from 'common/utils';
import { CheckboxButton, FormButtons, QuestionContainer, QuoteContainer, Steps } from 'components/cpaipw';
import { Accordion } from 'components/cpaipw/Accordion';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import IllnessContainer from 'components/cpaipw/IllnessContainer';
import { FormContext } from 'quote/FormContext';
import React from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { HistoryActionContext, HistoryDataContext } from './HistoryContext';


const HistoryApplicationStep1: React.FC = () => {
	const history = useHistory();

	const FormCtx = React.useContext(FormContext);
	const historyActionCtx = React.useContext(HistoryActionContext);
	const historyDataCtx = React.useContext(HistoryDataContext);
	const submissionCtx = React.useContext(SubmissionContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.history);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const methods = useForm({
		defaultValues: {
			historyData: [],
		},
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});
	const { control, setError, handleSubmit } = methods;

	// const watchNa = watch(['notApplicable'])

	const onValidSubmit = async (val: any) => {
		const keys = Object.keys(val);
		if (notApplicableOrEmptyValue(keys, val)) {
			setError('notApplicable', { message: 'If none of the situations are applicable, please select "Not Applicable"' });
			return;
		}
		const filteredKeys = keys.filter(key => key !== 'notApplicable');
		historyActionCtx.extractHistoryData(filteredKeys, val);
		submissionCtx.setSubmitApplication(true);
		history.push(NavRoutes.ApplicationReview);
	};

	const historyUsers = historyDataCtx.historyData.length > 0 ? historyDataCtx.historyData.map(history => history.user) : [];

	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<h1>History</h1>
				<Steps currentstep={1} totalSteps={1} />
				<form onSubmit={preventDefault}>
					<div className="flex-column flex-fill">
						<QuestionContainer index={1}>
							<div className="form-group">
								<label htmlFor="question1" className="font-weight-normal">
									Is there any history in the family (father, mother brothers, sisters) of heart disease, stroke, high cholestrol, high blood pressure, disabetes, kidney
									disease, multiple sclerosis, Huntington's chorea, polyposis coli, cancer, Alzheimer's disease, Parkinson's disease, muscular  dysthrophy,
									motor neuron diseases or other hereditary diseases ?
								</label>
								<Accordion defaultState={historyUsers}>
									<IllnessContainer historyData={historyDataCtx.historyData}/>
								</Accordion>
							</div>
						</QuestionContainer>
						<QuestionContainer index={2}>
							<Controller
								name="notApplicable"
								control={control}
								defaultValue={false}
								render={({ onChange, value }) =>
									<CheckboxButton
										name="notApplicable"
										isActive={value}
										label="Not Applicable"
										onClick={() => onChange(!value)}
									/>
								}
							/>
							<ErrorMessage>{''}</ErrorMessage>
						</QuestionContainer>
					</div>
					<FormButtons
						onClickBack={() => history.push(NavRoutes.ApplicationLifestyleStep6)}
						onClickNext={handleSubmit(onValidSubmit)}
					/>
				</form>
			</QuoteContainer>
		</FormProvider>
	);

	function notApplicableOrEmptyValue(keys: string[], val: any) {
		return keys.includes('notApplicable') && keys.length === 1 && val['notApplicable'] === false;
	}
};

export default HistoryApplicationStep1;