import React from 'react';
import { FormContext } from 'quote/FormContext';
import { IUnit, Units, UnitsTypeInput } from './UnitsTypeInput';

interface IProps {
	name: string;
	id?: string;
	value: string; // always metric (kg)
	onChange: (value: string) => void; // always sends in metric (kg) value
}

export const WeightInput: React.FC<IProps> = (props: IProps) => {
	const formCxt = React.useContext(FormContext);
	const { weightUnit: unit, setWeightUnit: setUnit } = formCxt;
	const [displayValue, setDisplayValue] = React.useState(props.value);

	React.useEffect(() => {
		if (unit === Units.lbs) {
			setDisplayValue(convertToLbs(displayValue));
		} else {
			// double check to see if display value isn't in kgs yet
			if ( props.value !== displayValue ) {
				const newValue = convertToKg(displayValue);
				setDisplayValue(newValue);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unit]);

	const units = [
		{
			label: Units.lbs,
			value: Units.lbs
		},
		{
			label: Units.kg,
			value: Units.kg
		}
	] as IUnit[];

	const handleOnChange = (value: string) => {
		setDisplayValue(value);
		let weight = value;
		if (unit === Units.lbs) {
			weight = convertToKg(weight);
		}

		props.onChange(weight);
	};

	const updateUnits = (newUnit: Units) => {
		if ( newUnit === unit ) {
			return;
		}
		setUnit(newUnit);
	};

	const convertToLbs = (value: string) => {
		let weight = parseFloat(value);

		return isNaN(weight) ? value : Math.round(weight / 0.453592).toString();
	};

	const convertToKg = (value: string) => {
		let weight = parseFloat(value);

		return isNaN(weight) ? value : Math.round(weight * 0.453592).toString();
	};

	return (
		<UnitsTypeInput
			name={props.name}
			id={props.id}
			units={units}
			value={displayValue}
			selectedUnit={unit}
			onChange={handleOnChange}
			onUnitChange={updateUnits}
		/>
	);
};