import React from 'react';
import { FormContext, INeedsAssessment } from 'quote/FormContext';
import { useForm, Controller } from 'react-hook-form';
import { FormButtons, QuoteContainer, Steps } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { CheckboxGroup } from 'components/cpaipw/CheckboxGroup';
import { NavRoutes, QuotePages, ValidationMessages } from 'common/utils';
import { ReactComponent as IconShieldPeople } from 'assets/icons/icon-shield-people.svg';
import { whyToBuyOptions } from 'quote/policy/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
interface IProps {
}

const NeedsAssessmentStep1: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);
	const history = useHistory();

	const { control, handleSubmit, watch, errors, setError, clearErrors } = useForm({
		defaultValues: FormCtx.needsAssessment,
	});

	const onSubmit = (values: INeedsAssessment) => {
		if (values.reasonToBuy === undefined || values.reasonToBuy?.length === 0 ) {
			setError('reasonToBuyOther', { message: ValidationMessages.required });
			return;
		}
		values.step = 2;
		FormCtx.setNeedsAssessment({...FormCtx.needsAssessment, ...values});
		if (!FormCtx.needsAssessmentOnly) {
			FormCtx.upsertFormValues(values);
		}
		history.push(NavRoutes.QuoteNeedsAssessmentStep2);
	};
	const watchReasonToBuy = watch('reasonToBuy');

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		clearErrors();
	}, [clearErrors, watchReasonToBuy]);

	return (
		<QuoteContainer>
			<IconShieldPeople height={100} />
			<form>
				<h3>What’s Your Why to Buy?</h3>
				<Steps currentstep={1} totalSteps={3}/>
				<p>
					There are many reasons an individual takes out a Life Insurance policy and they
					are often related to big life changes. From the list below identify your reason(s)
					for wanting to purchase Life Insurance:
				</p>
				<p><i>check all that apply</i></p>

				<div className="form-group">
					<Controller
						name="reasonToBuy"
						control={control}
						render={({ onChange, value, name }) =>
							<CheckboxGroup
								name={name}
								onChange={onChange}
								value={value}
								options={whyToBuyOptions}
							/>
						}
					/>
					{watchReasonToBuy?.includes('other') && (
						<React.Fragment>
							<label htmlFor="reasonToBuyOther">Other:</label>
							<Controller
								name="reasonToBuyOther"
								control={control}
								rules={{
									required: ValidationMessages.required,
								}}
								render={({ onChange, value, name }) =>
									<input
										type="text"
										name={name}
										onChange={onChange}
										value={value}
										className="form-control"
									/>
								}
							/>
						</React.Fragment>
					)}
					<ErrorMessage>{errors?.reasonToBuyOther?.message}</ErrorMessage>
				</div>
				<FormButtons
					onClickBack={() => history.push(FormCtx.needsAssessmentOnly ? NavRoutes.QuoteNeedsAssessmentStep0 : NavRoutes.QuoteSelectPolicy)}
					onClickNext={handleSubmit(onSubmit)}
				/>
			</form>
		</QuoteContainer>
	);
};
export default NeedsAssessmentStep1;
