import React from 'react';
import { RadioButtonGroup } from 'components/cpaipw/RadioButtonGroup';
import { FormContext, IAboutYou, ISpouseData, IinitialQuestions } from 'quote/FormContext';
import { useForm, Controller } from 'react-hook-form';
import { QuoteContainer, LoadContainer, FormButtons } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { getAgeFromDate, NavRoutes, preventDefault, QuotePages, validateDate, ValidationMessages } from 'common/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { PatternFormat } from 'react-number-format';
import { cpaBodiesRadioOptions, cpaStatusRadioOptions } from 'quote/policy/utils';
import { TogglePolicyButton } from 'quote/policy/TogglePolicyButton';

interface IProps {
	location: any;
}

const YourDetails: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);
	const urlParams = new URLSearchParams(props.location.search);
	const history = useHistory();
	const [showSpouse, setShowSpouse] = React.useState<boolean>(FormCtx.spouseApplicantId !== undefined && FormCtx.spouseApplicantId !== '');

	const validateAreaCode = (value: any) => {
		const areaCode = value.substring(4, 5);
		if (areaCode.includes('0') || areaCode.includes('1')) {
			return 'Please enter a valid phone number';
		}
		return true;
	};

	const { control, handleSubmit, errors, trigger, getValues } = useForm<IAboutYou & IinitialQuestions & ISpouseData>({
		defaultValues: {
			...FormCtx.initialUserData,
			dob: urlParams.get('dob') || FormCtx.personalData?.dob || '',
			gender: urlParams.get('gender') || FormCtx.personalData?.gender || '',
			usedTobacco: urlParams.get('usedTobacco') || FormCtx.personalData?.usedTobacco || '',
			cpaStatus: FormCtx.personalData?.cpaStatus || '',
			cpaBody: FormCtx.personalData?.cpaBody || '',
			spouseDob: FormCtx.spouseData?.spouseDob || '',
			spouseGender: FormCtx.spouseData?.spouseGender || '',
			spouseUsedTobacco: FormCtx.spouseData?.spouseUsedTobacco || '',
			spouseFirstName: FormCtx.spouseData?.spouseFirstName || '',
			spouseLastName: FormCtx.spouseData?.spouseLastName || '',
			spouseEmail: FormCtx.spouseData?.spouseEmail || '',
			spousePhonenumber: FormCtx.spouseData?.spousePhonenumber || '',
		},
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	const removeSpouse = () => {
		setShowSpouse(false);
		FormCtx.setSpouseData({});
		FormCtx.setSpouseApplicantId('');
	};

	const onSubmit = async (values: IAboutYou & IinitialQuestions & ISpouseData) => {
		FormCtx.setIsLoading(true);
		FormCtx.setPersonalData(values);
		FormCtx.setSpouseData(values);

		const result = await FormCtx.upsertFormValues(values);
		FormCtx.setIsLoading(false);

		let updatePolicy = false;

		if (result !== undefined) {
			if (FormCtx.needsAssessmentOnly === undefined) {
				FormCtx.setSkippedNeedsAssessment(true);
			} else {
				FormCtx.setSkippedNeedsAssessment(false);
				updatePolicy = true;
			}
			history.replace(NavRoutes.QuoteSelectPolicy, { updatePolicy: updatePolicy });
		} else {
			alert('Failed to fetch policy');
		}

	};

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.yourDetails);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		async function checkParamsValues() {
			const isValid = await trigger();
			if (isValid === true) {
				const values = getValues();
				onSubmit(values);
			}
		}
		const params = new URLSearchParams(props.location.search);
		if ((params.get('dob') || params.get('gender') || params.get('usedTobacco'))) {

			FormCtx.setIsLoading(true);
			setTimeout(() => {
				checkParamsValues();
			}, 100);
		}
		FormCtx.setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<QuoteContainer>
			<h1>Your Details</h1>
			<LoadContainer isLoading={FormCtx.isLoading}>
				<form onSubmit={preventDefault}>
					<div className="border p-3 rounded">
						<div className="m-2">
							<div className="form-group">
								<h4 className="mb-3">You</h4>
								<label htmlFor="dob">When were you born?</label>
								<Controller
									name="dob"
									control={control}
									rules={{
										required: ValidationMessages.required,
										validate: date => validateDate(date) ? ValidationMessages.date : getAgeFromDate(date) >= 65 ? ValidationMessages.age : true,
									}}
									render={({ onChange, value }) =>
										<PatternFormat
											format="####/##/##"
											placeholder="yyyy/mm/dd"
											mask={['y', 'y', 'y', 'y', 'm', 'm', 'd', 'd']}
											value={value}
											onChange={onChange}
											name="dob"
											id="dob"
											type="text"
											className="form-control"
										/>
									}
								/>
								<ErrorMessage>{errors.dob?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="gender">What is your gender?</label>
								<Controller
									name="gender"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<RadioButtonGroup
											name="gender"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Male',
													value: 'male',
												},
												{
													label: 'Female',
													value: 'female',
												},
											]}
										/>
									}
								/>
								<ErrorMessage>{errors.gender?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="gender">In the past 12 months, have you used tobacco?</label>
								<Controller
									name="usedTobacco"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<RadioButtonGroup
											name="usedTobacco"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Yes',
													value: 'yes',
												},
												{
													label: 'No',
													value: 'no',
												},
											]}
										/>
									}
								/>
								<ErrorMessage>{errors.usedTobacco?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="cpaStatus">What is your CPA status?</label>
								<Controller
									name="cpaStatus"
									control={control}
									rules={{ required: ValidationMessages.required }}
									render={({ onChange, value }) =>
										<RadioButtonGroup
											name="cpaStatus"
											onChange={onChange}
											list={true}
											value={value}
											options={cpaStatusRadioOptions}
										/>
									}
								/>
								<ErrorMessage>{errors.cpaStatus?.message}</ErrorMessage>
							</div>
							<div className="form-group mb-0">
								<label htmlFor="cpaBody">What provincial CPA body are you (or your spouse) registered with?</label>
								<Controller
									name="cpaBody"
									control={control}
									rules={{ required: ValidationMessages.required }}
									render={({ onChange, value }) =>
										<RadioButtonGroup
											name="cpaBody"
											onChange={onChange}
											value={value}
											options={cpaBodiesRadioOptions}
										/>
									}
								/>
								<ErrorMessage>{errors.cpaBody?.message}</ErrorMessage>
							</div>
						</div>
					</div>

					{showSpouse ? (
						<div className="form-group border p-3 rounded mt-3">
							<div className="m-2">
								<div className="d-flex justify-content-between align-items-center mb-3 mt-n2">
									<h4 className="mb-0">Your Spouse</h4>
									<TogglePolicyButton onClick={() => removeSpouse()} isSelected={true} />
								</div>
								<div className="form-group">
									<label htmlFor="spouseFirstName">What is your spouse’s first name?</label>
									<Controller
										name="spouseFirstName"
										control={control}
										rules={{ required: ValidationMessages.required }}
										render={({ onChange, value }) =>
											<input
												type="text"
												className="form-control"
												value={value}
												onChange={onChange}
												name="spouseFirstName"
												id="spouseFirstName"
											/>
										}
									/>
									<ErrorMessage>{errors.spouseFirstName?.message}</ErrorMessage>
								</div>
								<div className="form-group">
									<label htmlFor="spouseLastName">What is your spouse’s last name?</label>
									<Controller
										name="spouseLastName"
										control={control}
										rules={{ required: ValidationMessages.required }}
										render={({ onChange, value }) =>
											<input
												type="text"
												className="form-control"
												value={value}
												onChange={onChange}
												name="spouseLastName"
												id="spouseLastName"
											/>
										}
									/>
									<ErrorMessage>{errors.spouseLastName?.message}</ErrorMessage>
								</div>
								<div className="form-group">
									<label htmlFor="spouseDob">What is your spouse's date of birth?</label>
									<Controller
										name="spouseDob"
										control={control}
										rules={{
											required: ValidationMessages.required,
											validate: date => validateDate(date) ? ValidationMessages.date : getAgeFromDate(date) >= 65 ? ValidationMessages.age : true,
										}}
										render={({ onChange, value }) =>
											<PatternFormat
												format="####/##/##"
												placeholder="yyyy/mm/dd"
												mask={['y', 'y', 'y', 'y', 'm', 'm', 'd', 'd']}
												value={value}
												onChange={onChange}
												name="spouseDob"
												id="spouseDob"
												type="text"
												className="form-control"
											/>
										}
									/>
									<ErrorMessage>{errors.spouseDob?.message}</ErrorMessage>
								</div>
								<div className="form-group">
									<label htmlFor="spouseGender">What is your spouse's gender?</label>
									<Controller
										name="spouseGender"
										control={control}
										rules={{
											required: ValidationMessages.required,
										}}
										render={({ onChange, value }) =>
											<RadioButtonGroup
												name="spouseGender"
												onChange={onChange}
												value={value}
												options={[
													{
														label: 'Male',
														value: 'male',
													},
													{
														label: 'Female',
														value: 'female',
													},
												]}
											/>
										}
									/>
									<ErrorMessage>{errors.spouseGender?.message}</ErrorMessage>
								</div>
								<div className="form-group">
									<div className="d-flex justify-content-between align-items-center">
										<label className="font-weight-bold" htmlFor="spouseEmail">Enter your spouse’s email address
											<span className="text-muted font-italic">(optional)</span></label>
									</div>
									<Controller
										name="spouseEmail"
										control={control}
										//rules={{ required: ValidationMessages.required }}
										render={({ onChange, value }) =>
											<input
												type="text"
												className="form-control"
												value={value}
												onChange={onChange}
												name="spouseEmail"
												id="spouseEmail"
											/>
										}
									/>
									<ErrorMessage>{errors.spouseEmail?.message}</ErrorMessage>
								</div>
								<div className="form-group">
									<div className="d-flex justify-content-between align-items-center">
										<label className="font-weight-bold" htmlFor="spousePhonenumber">Enter your spouse’s phone number
											<span className="text-muted font-italic">(optional)</span></label>
									</div>
									<Controller
										name="spousePhonenumber"
										control={control}
										rules={{ validate: validateAreaCode }}
										render={({ onChange, value, ref }) =>
											<PatternFormat
												format="+1 (###) ###-####"
												mask="_"
												value={value}
												getInputRef={ref}
												onChange={onChange}
												name="spousePhonenumber"
												id="spousePhonenumber"
												type="text"
												className="form-control"
											/>
										}
									/>
									<ErrorMessage>{errors.spousePhonenumber?.message}</ErrorMessage>
								</div>
								<div className="form-group mb-0">
									<label htmlFor="spouseUsedTobacco">In the past 12 months, has your spouse used tobacco?</label>
									<Controller
										name="spouseUsedTobacco"
										control={control}
										rules={{
											required: ValidationMessages.required,
										}}
										render={({ onChange, value }) =>
											<RadioButtonGroup
												name="spouseUsedTobacco"
												onChange={onChange}
												value={value}
												options={[
													{
														label: 'Yes',
														value: 'yes',
													},
													{
														label: 'No',
														value: 'no',
													},
												]}
											/>
										}
									/>
									<ErrorMessage>{errors.spouseUsedTobacco?.message}</ErrorMessage>
								</div>
							</div>
						</div>
					) : (
						<React.Fragment>
							<div className="form-group border p-3 rounded mt-3">
								<div className="m-2">
									<h4>Would you like to add your spouse?</h4>
									<p>CPAs and their spouses are both eligible for insurance under our plan. You can enter their information below to get a quote for both of you.</p>
									<p className="font-italic small">Note: if your spouse is also a CPA, please complete a separate quote.</p>
									<button type="button" className="btn btn-primary" onClick={() => setShowSpouse(true)}>
										Add Spouse
									</button>
								</div>
							</div>

						</React.Fragment>
					)}
					<FormButtons
						onClickBack={() => history.push(NavRoutes.QuoteSelectInsuranceType)}
						onClickNext={handleSubmit(onSubmit)}
					/>
				</form>
			</LoadContainer>
		</QuoteContainer>
	);

};

export default YourDetails;
