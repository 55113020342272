import React, { useEffect } from 'react';
import { ApplicationPages, NavRoutes, preventDefault, ValidationMessages } from 'common/utils';
import { CheckboxButton, FormButtons, QuoteContainer, Steps, Divider } from 'components/cpaipw';
import { FormContext } from 'quote/FormContext';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { BeneficiaryActionContext, BeneficiaryDataContext, IAssignedBeneficiary } from './BeneficiaryContext';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';

const BeneficiaryApplicationStep2: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const beneficaryDataConext = React.useContext(BeneficiaryDataContext);
	const beneficiaries = beneficaryDataConext.beneficiaryData;
	const beneficiaryActionContext = React.useContext(BeneficiaryActionContext);

	const [individuals, setIndividuals] = React.useState<IAssignedBeneficiary[] | undefined>(undefined);
	const [corporations, setCorporations] = React.useState<IAssignedBeneficiary[] | undefined>(undefined);
	const [estate, setEstate] = React.useState<IAssignedBeneficiary | undefined>(undefined);
	const [equallyShared, setEquallyShared] = React.useState<boolean | undefined>(undefined);
	const [total, setTotal] = React.useState<number>(100);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.beneficiaries);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const individualsData = beneficiaryActionContext.getIndividuals();
		const corporationsData = beneficiaryActionContext.getCorporations();
		const estateData = beneficiaryActionContext.getEstate();
		const isEqual = beneficiaries.division;

		if ( isEqual === 'EQUAL_SHARES' || isEqual === undefined ) {
			setEquallyShared(true);
		} else {
			setEquallyShared(false);
		}

		setIndividuals(individualsData);
		setCorporations(corporationsData);
		setEstate(estateData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const methods = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange'
	});

	const { control, setValue, getValues, handleSubmit, watch, errors, setError, clearErrors } = methods;

	const watchValues = watch();

	React.useEffect(() => {
		clearErrors('total');
	}, [total, clearErrors]);

	useEffect(() => {
		if (equallyShared) {
			const value = getAverage();

			const all = [] as IAssignedBeneficiary[];

			if(individuals !== undefined && individuals.length > 0) { all.push(...individuals); }
			if(corporations !== undefined && corporations.length > 0) { all.push(...corporations); }
			if(estate !== undefined) { all.push(estate); }

			all.forEach((i) => {
				setValue(i.id, value);
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [equallyShared]);

	React.useEffect(() => {
		if ( equallyShared === false ) {
			calculateTotal();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchValues]);

	const onValidSubmit = (val: any) => {
		if( total !== 100 ) {
			setError('total', { message: 'The total percentage has to add up to 100%' });
			return;
		}
		beneficiaryActionContext.extractBeneficiaryAllocations(val, equallyShared === true);
		history.push(NavRoutes.ApplicationBeneficiariesStep3);
	};

	const getAverage = () => {
		const numberIndividuals = individuals !== undefined ? individuals.length : 0;
		const numberCorporations = corporations !== undefined ? corporations.length : 0;
		const hasEstate = estate !== undefined;
		const total =  numberIndividuals + numberCorporations + (hasEstate ? 1 : 0);
		let percentage = 100 / total;

		if( Math.round(percentage) !== percentage ) {
			return percentage.toFixed(2);
    	}

		return percentage;
	};

	const calculateTotal = () => {
		var newTotal = 0;

		if (individuals !== undefined ? individuals.length : 0) {
			individuals?.forEach(i => {
				const val = getValues(i.id);
				if ( isNaN(val) ) {
					setTotal(-1);
					return;
				}
				newTotal += Number(val);
			});
		}

		if (corporations !== undefined ? corporations.length : 0) {
			corporations?.forEach(i => {
				const val = getValues(i.id);
				if ( isNaN(val) ) {
					setTotal(-1);
					return;
				}
				newTotal += Number(val);
			});
		}

		if (estate !== undefined) {
			const val = getValues(estate.id);
			if ( isNaN(val) ) {
				setTotal(-1);
				return;
			}
			newTotal += Number(val);
		}

		setTotal(newTotal);
	};
	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<h1>Beneficiaries</h1>
				<Steps currentstep={2} totalSteps={3} />
				<form onSubmit={preventDefault}>
					<p className="font-weight-bold">
						To be divided as follows:
					</p>

					{individuals && <div className="mb-4">
						<h6>Individual(s)</h6>
						{individuals.map((individual: IAssignedBeneficiary, index: number) => {
							const iData = beneficiaries.individuals?.find((i) => i.id === individual.id);
							return (
								<React.Fragment key={`${individual.id}-${index}`}>
									<div
										className={classNames('d-flex py-2 flex-fill justify-content-between align-items-center rounded px-3', index % 2 === 0 && 'bg-gray-100')}
									>
										<div className="d-flex flex-grow-1">{individual.displayName}</div>
										<div className="d-flex flex-shrink">
											<div className="input-group">
												<Controller
													name={individual.id}
													control={control}
													rules={{
														required: ValidationMessages.required,
														min: {value: 0, message: ValidationMessages.number },
														max: {value: 100, message: ValidationMessages.number },
														validate: val => isNaN(val) ? ValidationMessages.number : true,
													}}
													defaultValue={(iData !== undefined && iData.allocation !== undefined) ? iData.allocation : getAverage()}
													render={({ onChange, value }) =>
														<input
															id={individual.id}
															className={classNames('form-control', equallyShared && 'disabled')}
															type="text"
															value={value}
															onChange={onChange}
															disabled={equallyShared}
														/>
													}
												/>
												<div className="input-group-append">
													<div className="input-group-text text-gray-600">%</div>
												</div>
											</div>
										</div>
									</div>
									<ErrorMessage className="mt-3">{errors?.[individual.id]?.message}</ErrorMessage>
								</React.Fragment>
							);
						})}
					</div>}
					{corporations && <div className="mb-4">
						<h6>Corporations(s)</h6>
						{corporations.map((corporation, index: number) => {
							const iData = beneficiaries.corporations?.find((i) => i.id === corporation.id);
							return (
								<React.Fragment key={`${corporation.id}-${index}`}>
									<div
										className={classNames('d-flex py-2 flex-fill justify-content-between align-items-center rounded px-3', index % 2 === 0 && 'bg-gray-100')}
									>
										<div className="d-flex flex-grow-1">{corporation.displayName}</div>
										<div className="d-flex flex-shrink">
											<div className="input-group">
												<Controller
													name={corporation.id}
													control={control}
													rules={{
														required: ValidationMessages.required,
														min: {value: 0, message: ValidationMessages.number },
														max: {value: 100, message: ValidationMessages.number },
														validate: val => isNaN(val) ? ValidationMessages.number : true,
													}}
													defaultValue={(iData !== undefined && iData.allocation !== undefined)? iData.allocation : getAverage()}
													render={({ onChange, value }) =>
														<input
															id={corporation.id}
															className={classNames('form-control', equallyShared && 'disabled')}
															type="text"
															value={value}
															onChange={onChange}
															disabled={equallyShared}
														/>
													}
												/>
												<div className="input-group-append">
													<div className="input-group-text text-gray-600">%</div>
												</div>
											</div>
										</div>
									</div>
									<ErrorMessage className="mt-3">{errors?.[corporation.id]?.message}</ErrorMessage>
								</React.Fragment>
							);
						})}
					</div>}
					{estate && (
						<div className="mb-4">
							<h6>Estate</h6>
							<div className={classNames('d-flex py-2 flex-fill justify-content-between align-items-center rounded px-3 bg-gray-100')}>
								<div className="d-flex flex-grow-1">{estate.displayName}</div>
								<div className="d-flex flex-shrink">
									<div className="input-group">
										<Controller
											name={estate.id}
											control={control}
											rules={{
												required: ValidationMessages.required,
												min: {value: 0, message: ValidationMessages.number },
												max: {value: 100, message: ValidationMessages.number },
												validate: val => isNaN(val) ? ValidationMessages.number : true,
											}}
											defaultValue={(beneficiaries.estate !== undefined && beneficiaries.estate.allocation !== undefined) ? beneficiaries.estate.allocation : getAverage()}
											render={({ onChange, value }) =>
												<input
													id={estate.id}
													className={classNames('form-control', equallyShared && 'disabled')}
													type="text"
													value={value}
													onChange={onChange}
													disabled={equallyShared}
												/>
											}
										/>
										<div className="input-group-append">
											<div className="input-group-text text-gray-600">%</div>
										</div>
									</div>
								</div>
							</div>
							<ErrorMessage className="mt-3">{errors?.[estate.id]?.message}</ErrorMessage>
						</div>
					)}
					<CheckboxButton
						label="I want my shares to divide equally to survivor(s)."
						isActive={equallyShared === true}
						onClick={handleEquallyShared}
					/>
					<Divider className="mt-4 mb-3" />
					<div className="d-flex flex-row justify-content-between align-items-center pb-2 font-weight-bold">
						<span className="h6">Total percentage:</span>
						<span className={classNames('h6', total !== 100 && 'text-danger')}>
							{(total !== -1) ? ((Math.round(total) === total) ? total : total.toFixed(2)) : '0'}%
						</span>
					</div>
					<ErrorMessage>{errors?.total?.message}</ErrorMessage>
					<FormButtons
						onClickBack={() => history.push(NavRoutes.ApplicationBeneficiariesStep1)}
						onClickNext={handleSubmit(onValidSubmit)}
					/>
				</form>
			</QuoteContainer>
		</FormProvider>
	);
	function handleEquallyShared() {
		clearErrors();
		setEquallyShared(!equallyShared);
		setTotal(100);
	}
};

export default BeneficiaryApplicationStep2;