import React from 'react';
import { ApplicationPages, NavRoutes, preventDefault, ValidationMessages } from 'common/utils';
import { RadioButtonGroup, FormButtons, InfoMessage, QuoteContainer, Steps, CheckboxButton } from 'components/cpaipw';
import { FormContext } from 'quote/FormContext';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { BeneficiaryActionContext, BeneficiaryDataContext } from './BeneficiaryContext';
import { SubmissionContext } from 'application/SubmissionContext';


const BeneficiaryApplicationStep3: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const submissionCtx = React.useContext(SubmissionContext);
	const beneficaryDataConext = React.useContext(BeneficiaryDataContext);
	const beneficiaryActionContext = React.useContext(BeneficiaryActionContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.beneficiaries);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			beneficiaryUnder18: beneficaryDataConext.isUnder18,
			designateTrustee: beneficaryDataConext.beneficiaryData.trustee ? 'yes' : 'no',
			firstName: beneficaryDataConext.beneficiaryData.trustee?.firstName || '',
			lastName: beneficaryDataConext.beneficiaryData.trustee?.lastName || '',
			middleInitial: beneficaryDataConext.beneficiaryData.trustee?.middleInitial || '',
			relationshipToInsured: beneficaryDataConext.beneficiaryData.trustee?.relationship || '',
			iUnderstand: beneficaryDataConext.beneficiaryData.trustee?.confirmedUnderstanding || '',
		}
	});
	const { control, watch, errors, handleSubmit } = methods;

	const watchUnder18 = watch(['beneficiaryUnder18']);
	const watchDesignateTrustee = watch(['designateTrustee']);

	const onValidSubmit = async (val: any) => {
		beneficiaryActionContext.extractTrustee(val);
		beneficiaryActionContext.setIsUnder18(val['beneficiaryUnder18']);
		submissionCtx.setSubmitApplication(true);
		history.push(NavRoutes.ApplicationHealthStep1);
	};

	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<h1>Beneficiaries</h1>
				<Steps currentstep={3} totalSteps={3} />
				<form onSubmit={preventDefault}>
					<div className="form-group">
						<label>Do you have any beneficiaries under 18?</label>
						<Controller
							name="beneficiaryUnder18"
							control={control}
							rules={{
								required: ValidationMessages.required,
							}}
							render={({ onChange, value }) =>
								<RadioButtonGroup
									name="beneficiaryUnder18"
									onChange={onChange}
									value={value}
									options={[
										{
											label: 'Yes',
											value: 'yes'
										},
										{
											label: 'No',
											value: 'no'
										},
									]}
								/>
							}
						/>
						<ErrorMessage>{errors?.beneficiaryUnder18?.message}</ErrorMessage>
					</div>
					{watchUnder18.beneficiaryUnder18 === 'yes' && (
						<React.Fragment>
							<div className="form-group">
								<label>Do you want to designate a trustee/administrator?</label>
								<InfoMessage>
									When designating a trustee/administrator, we recommend you consult with a legal advisor, and with any proposed trustee/administrator.
									Select “No” if you have already made another trustee/administrator appointment.
								</InfoMessage>
								<Controller
									name="designateTrustee"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<RadioButtonGroup
											name="designateTrustee"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Yes',
													value: 'yes'
												},
												{
													label: 'No',
													value: 'no'
												},
											]}
										/>
									}
								/>
								<ErrorMessage>{errors?.designateTrustee?.message}</ErrorMessage>
							</div>
						</React.Fragment>
					)}
					{watchDesignateTrustee.designateTrustee === 'yes' && watchUnder18.beneficiaryUnder18 === 'yes' && (
						<React.Fragment>
							<h5>Trustee Contact Information</h5>
							<div className="form-group">
								<label>First Name</label>
								<Controller
									name="firstName"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input className="form-control" onChange={onChange} value={value} />
									}
								/>
								<ErrorMessage>{errors?.firstName?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label>Middle Initial</label>
								<Controller
									name="middleInitial"
									control={control}
									render={({ onChange, value }) =>
										<input className="form-control" onChange={onChange} value={value} />
									}
								/>
							</div>
							<div className="form-group">
								<label>Last Name</label>
								<Controller
									name="lastName"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input className="form-control" onChange={onChange} value={value} />
									}
								/>
								<ErrorMessage>{errors?.lastName?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label>Relationship to insured</label>
								<Controller
									name="relationshipToInsured"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input className="form-control" onChange={onChange} value={value} />
									}
								/>
								<ErrorMessage>{errors?.relationshipToInsured?.message}</ErrorMessage>
							</div>
							<p className="small font-italic">
								I hereby appoint the following trustee to receive and to hold in trust, on behalf of any beneficiary,
								money payable to the beneficiary under this plan where, at the time of payment is to be made,
								the beneficiary is a minor or otherwise lacks legal capacity. The trustee shall act prudently and may use the money,
								including any returns on it or investments made, for the education and/or maintenance of the beneficiary.
								The trust will terminate once thebeneficiary is of the age of majority and has legal capacity.
								At that time, the trustee shall deliver to the beneficiary all assets held in trust.
							</p>
							<Controller
								name="iUnderstand"
								control={control}
								rules={{
									required: ValidationMessages.required
								}}
								render={({ onChange, value }) =>
									<CheckboxButton
										name="iUnderstand"
										onClick={() => onChange(!value)}
										isActive={value}
										label="I understand and accept."
									/>
								}
							/>
							<ErrorMessage className="mt-3">{errors?.iUnderstand?.message}</ErrorMessage>
						</React.Fragment>
					)}
					<FormButtons
						onClickBack={() => history.push(NavRoutes.ApplicationBeneficiariesStep2)}
						onClickNext={handleSubmit(onValidSubmit)}
					/>
				</form>
			</QuoteContainer>
		</FormProvider>
	);
};

export default BeneficiaryApplicationStep3;