import React from 'react';
import { FormContext } from 'quote/FormContext';
import { Page } from 'components/common';
import { Button } from 'components/buttons';
import { useHistory } from 'react-router-dom';
import { NavRoutes, QuotePages } from 'common/utils';
import { ReactComponent as IconClipBoard } from 'assets/icons/icon-needs-assessment.svg';
import { ReactComponent as IconShieldPeople } from 'assets/icons/icon-shield-people.svg';
import { ReactComponent as IconExpenses } from 'assets/icons/icon-expenses.svg';
import { ReactComponent as IconSavings } from 'assets/icons/icon-savings.svg';

interface IProps {
}

const NeedsAssessmentStep0: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);
	const history = useHistory();

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page>
			<div className="container">
				<div className="d-flex align-items-center mb-3 pb-3">
					<div className="row">
						<div className="col-lg-2 mb-4 mb-lg-0">
							<IconClipBoard className="align-self-start" style={{ minWidth: 152, height: 155, fill: 'none' }} />
						</div>
						<div className="col-lg-8">
							<div className="row">
								<div>
									<h1>Get a personalized recommendation by filling out our quick needs assessment.</h1>
									<p className="mb-3">
										<i>It is important to have Life Insurance, but it is equally important to know WHY you need it.</i>
									</p>
									<div className="d-flex mb-5">
										<Button className="btn-primary px-4" onClick={() => next(false)}>Get Started</Button>
									</div>
									<h4>A Life Insurance Policy Calculation</h4>
									<p>In general, a Life Insurance policy is the sum of all your savings and existing Life
										Insurance coverage subtracted from the sum of all your debts and forseeable expenses.
										The remaining debt and expenses that have yet to be paid is what we call a "gap".
										We then recommend a Life Insurance policy which will pay out the entirety of that gap.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<span className="d-block line-dash-header text-gray-600 mb-4">Calculation Breakdown</span>
				<div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
					<div className="col mb-3 mb-md-4">
						<div className="card card-body h-100 p-md-4 shadow">
							<IconShieldPeople />
							<h4 className="card-title mt-3 mb-2">What's Your Why to Buy?</h4>
							<p className="card-text">Understand your insurance needs before you select your policy.</p>
						</div>
					</div>
					<div className="col mb-3 mb-md-4">
						<div className="card card-body h-100 p-md-4 shadow">
							<IconExpenses />
							<h4 className="card-title mt-3 mb-2">Debts &amp; Expenses</h4>
							<p className="card-text">A high-level view of all your debts and an estimate of your foreseeable everyday expenses and financial goals for larger one-time payments.</p>
						</div>
					</div>
					<div className="col mb-3 mb-md-4">
						<div className="card card-body h-100 p-md-4 shadow">
							<IconSavings />
							<h4 className="card-title mt-3 mb-2">Savings</h4>
							<p className="card-text">A high-level view of all your current savings and investments.</p>
						</div>
					</div>
				</div>
				<p><strong>Answering each of the questions in this assessment will provide you with a clear picture of your Life Insurance needs.</strong></p>
				<div className="d-flex">
					<Button className="btn-primary px-4" onClick={() => next(false)}>Get Started</Button>
				</div>
			</div>
		</Page>
	);
	function next(skip: boolean) {
		FormCtx.setSkippedNeedsAssessment(skip);
		if (skip === true) {
			history.push(NavRoutes.QuoteSelectPolicy);
		} else {
			history.push(NavRoutes.QuoteNeedsAssessmentStep1);
		}
	}
};
export default NeedsAssessmentStep0;
