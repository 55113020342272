import { randomString, ValidationMessages } from 'common/utils';
import { Button } from 'components/buttons';
import { Divider } from 'components/cpaipw';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BeneficiaryDataContext } from '../BeneficiaryContext';
import { IIndividual } from '../BeneficiaryTypes';
import { IBeneficiariesProps } from './beneficiariesProps';

export const Individuals: React.FC<IBeneficiariesProps> = ( props: IBeneficiariesProps ) => {
	const [individualIds, setIndividualIds] = React.useState<string[]>([randomString(7)]);
	const methods = useFormContext();
	const beneficaryDataConext = React.useContext(BeneficiaryDataContext);
	const { individuals } = beneficaryDataConext.beneficiaryData;

	const { control, errors } = methods;

	React.useEffect(() => {
		if(individuals !== undefined && individuals.length > 0) {
			const newIds = individuals.map((individual: IIndividual) => {
				return individual.id || randomString(7);
			});
			setIndividualIds(newIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="m-3">
			{individualIds.map((id: string, index: number) => {
				const defaultIndividual = individuals?.find((i: IIndividual) => i.id === id);
				return (
					<React.Fragment key={`corporation-${id}-${index}`}>
						<div className="form-group">
							<label htmlFor={`individuals.${id}.firstName`}>First Name</label>
							<Controller
								name={`individuals.${id}.firstName`}
								control={control}
								rules={{
									required: ValidationMessages.required,
								}}
								defaultValue={defaultIndividual !== undefined ? defaultIndividual.firstName : ''}
								render={({ onChange, value }) =>
									<input
										name={`individuals.${id}.firstName`}
										onChange={onChange}
										value={value}
										className="form-control"
									/>
								}
							/>
							<ErrorMessage>{errors?.individuals?.[id]?.firstName?.message}</ErrorMessage>
						</div>
						<div className="form-group">
							<label htmlFor={`individuals.${id}.middleInitial`}>Middle Initial</label>
							<Controller
								name={`individuals.${id}.middleInitial`}
								control={control}
								defaultValue={defaultIndividual !== undefined ? defaultIndividual.middleInitial : ''}
								render={({ onChange, value }) =>
									<input
										name={`individuals.${id}.middleInitial`}
										onChange={onChange}
										value={value}
										className="form-control"
									/>
								}
							/>
						</div>
						<div className="form-group">
							<label htmlFor={`individuals.${id}.lastName`}>Last Name</label>
							<Controller
								name={`individuals.${id}.lastName`}
								control={control}
								rules={{
									required: ValidationMessages.required,
								}}
								defaultValue={defaultIndividual !== undefined ? defaultIndividual.lastName : ''}
								render={({ onChange, value }) =>
									<input
										name={`individuals.${id}.lastName`}
										onChange={onChange}
										value={value}
										className="form-control"
									/>
								}
							/>
							<ErrorMessage>{errors?.individuals?.[id]?.lastName?.message}</ErrorMessage>
						</div>
						<div className="form-group">
							<label htmlFor={`individuals.${id}.relationshipToInsured`}>Relationship to Insured</label>
							<Controller
								name={`individuals.${id}.relationshipToInsured`}
								control={control}
								rules={{
									required: ValidationMessages.required,
								}}
								defaultValue={defaultIndividual !== undefined ? defaultIndividual.relationship : ''}
								render={({ onChange, value }) =>
									<input
										name={`individuals.${id}.relationshipToInsured`}
										onChange={onChange}
										value={value}
										className="form-control"
									/>
								}
							/>
							<ErrorMessage>{errors?.individuals?.[id]?.relationshipToInsured?.message}</ErrorMessage>
						</div>
						{((index + 1) <= individualIds.length && individualIds.length > 1) && (
							<React.Fragment>
								<Button className="btn-outline-primary mb-2" onClick={() => handleToggle(id)}>Remove Individual -</Button>
								<Divider className="my-3" />
							</React.Fragment>
						)}
						{((index + 1) === individualIds.length || individualIds.length === 1) && (
							<Button className="btn-primary mb-3" onClick={() => handleToggle(undefined)}>Add Individual + </Button>
						)}
					</React.Fragment>
				);
			})}
		</div>

	);
	function handleToggle( id?: string ) {
		// console.log(id);
		const oldState = [...individualIds];
		if ( id === undefined ) {
			oldState.push(randomString(7));
			setIndividualIds(oldState);
		} else {
			const newState = oldState.filter((v: string) => v !== id);
			setIndividualIds(newState);
		}
	}
};
