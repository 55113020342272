import { AmountInput } from 'components/cpaipw';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import React from 'react';
import { IPolicyComponentProps } from './utils';
import classNames from 'classnames';

export const CoverageAmountField: React.FC<IPolicyComponentProps> = (props: IPolicyComponentProps) => {

	// TODO: debounce field (created race condition before, needs extra care)
	return (
		<div className="form-group">
			<label className={classNames('font-weight-normal', props.description && 'mb-1')}>What is the amount of insurance you want?</label>
			{props.description && <small className="d-block mb-1">{props.description}</small>}
			<div className="input-group mb-2">
				<div className="input-group-prepend">
					<span className="input-group-text">$</span>
				</div>
				<AmountInput value={props.value} onChange={props.onChange} />
			</div>
			<ErrorMessage>{props.error}</ErrorMessage>
		</div>
	);
};
