import React from 'react';
import { ReactComponent as IconClose } from 'assets/icons/icon-close-24.svg';

interface IProps {
	isSelected: boolean;
	onClick: () => void;
}

export const TogglePolicyButton: React.FC<IProps> = ( props: IProps ) => {

	return (
		<button className="btn btn-link mx-n3 learn-more" onClick={props.onClick}>
			<span className="btn-content">
				{props.isSelected ? (
					<>
						<span className="d-md-block d-none">Remove</span> <IconClose/>
					</>
				) : (
					'Add'
				)}
			</span>
		</button>
	);
};
