import React from 'react';
import { RadioButtonGroup } from 'components/cpaipw/RadioButtonGroup';
import { FormContext, INeedsAssessment } from 'quote/FormContext';
import { useForm, Controller } from 'react-hook-form';
import { AmountField, FormButtons, InfoBox, InfoMessage, NeedsAssessmentSidebarCard, QuoteContainer, Steps, AmountInput } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { NavRoutes, QuotePages, ValidationMessages } from 'common/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { ReactComponent as IconExpenses } from 'assets/icons/icon-expenses.svg';

interface IProps {}

const NeedsAssessmentStep5: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);
	const history = useHistory();

	const { control, handleSubmit, errors, watch } = useForm({
		defaultValues: FormCtx.needsAssessment,
	});
	const onSubmit = (values: INeedsAssessment) => {
		if ( values.annualIncomeYears !== 'other' ) {
			values.annualIncomeYearsOther = undefined;
		}

		if (!FormCtx.needsAssessmentOnly) {
			FormCtx.upsertFormValues(values);
		}

		FormCtx.setNeedsAssessment({...FormCtx.needsAssessment, ...values});

		history.push(NavRoutes.QuoteNeedsAssessmentStep6);
	};
	const watchAnnualIncomeYears = watch('annualIncomeYears');

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<QuoteContainer sidebar={<NeedsAssessmentSidebarCard />}>
			<form>
				<IconExpenses className="mb-3" />
				<div className="form-group">
					<h3>Foreseeable Expenses</h3>
					<Steps currentstep={2} totalSteps={3} />
					<InfoBox title="Annual Basic Expenses" />
					<InfoMessage>
						These expenses can be described as costs predicted to be accrued annually based on your current lifestyle.
					</InfoMessage>

					<div className="form-group">
						<label>To calculate the amount of coverage you need to pay your basic expenses, please indicate your annual income after tax (CAD) below:</label>
						<AmountField
							item={{label: 'After-Tax Annual Income'}}
							prepend="$"
						>
							<Controller
								name="annualIncome"
								control={control}
								rules={{
									required: ValidationMessages.required,
									validate: val => isNaN(val) ? ValidationMessages.number : true,
								}}
								render={({ onChange, value, name }) =>
									<AmountInput
										onChange={onChange}
										value={value}
										name={name}
									/>
								}
							/>
						</AmountField>
						<ErrorMessage>{errors?.annualIncome?.message}</ErrorMessage>
					</div>
					<div className="form-group">
						<label>How many years after you pass would you like your beneficiaries to receive this amount:</label>
						<Controller
							name="annualIncomeYears"
							control={control}
							rules={{
								required: ValidationMessages.required,
							}}
							render={({ onChange, value, name }) =>
								<RadioButtonGroup
									name={name}
									onChange={onChange}
									value={value}
									options={[
										{
											label: '5 Years',
											value: '5',
										},
										{
											label: '7 Years',
											value: '7',
										},
										{
											label: '10 Years',
											value: '10',
										},
										{
											label: 'Other (Please specify)',
											value: 'other',
										},
									]}
								/>
							}
						/>
						<ErrorMessage>{errors?.annualIncomeYears?.message}</ErrorMessage>
						{watchAnnualIncomeYears?.includes('other') && (
							<React.Fragment>
								<label htmlFor="annualIncomeYearsOther">Other:</label>
								<Controller
									name="annualIncomeYearsOther"
									control={control}
									rules={{
										required: ValidationMessages.required,
										validate: val => isNaN(val) ? ValidationMessages.number : true,
									}}
									render={({ onChange, value, name }) =>
										<div className="input-group">
											<AmountInput
												onChange={onChange}
												value={value}
												name={name}
											/>
											<div className="input-group-append">
												<span className="input-group-text text-gray-600">years</span>
											</div>
										</div>
									}
								/>
							</React.Fragment>
						)}
						<ErrorMessage>{errors?.annualIncomeYearsOther?.message}</ErrorMessage>
					</div>
				</div>
				<FormButtons
					onClickBack={() => history.push(NavRoutes.QuoteNeedsAssessmentStep4)}
					onClickNext={handleSubmit(onSubmit)}
				/>
			</form>
			<div className="d-block d-lg-none">
				<NeedsAssessmentSidebarCard />
			</div>
		</QuoteContainer>
	);
};
export default NeedsAssessmentStep5;
