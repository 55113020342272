import React, { useRef } from 'react';
import { Button } from 'components/buttons';
import { FormContext, IinitialQuestions } from 'quote/FormContext';
import { QuoteContainer } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { NavRoutes, QuotePages, ValidationMessages } from 'common/utils';
import { ReactComponent as ThumbsUp } from 'assets/img/thumbs-up.svg';
import { getNearest10k } from 'quote/policy/utils';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { Checkbox } from 'antd';
import { ReactComponent as IconLoad } from 'assets/icons/icon-load.svg';
import oliverAPI from 'api/OliverAPI';

interface IProps { }

const NeedsAssessmentStep8: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);
	const history = useHistory();
	const calculations = FormCtx.calculateTotal();
	const total = getNearest10k(calculations.total);
	const recommendation = (total > 2000000) ? 2000000 : (total <= 0) ? 10000 : total;
	const formRef = useRef<HTMLDivElement | null>(null);
	const [emailSent, setEmailSent] = React.useState(false);
	const [emailSendError, setEmailSendError] = React.useState<string>();
	const [emailError, setEmailError] = React.useState<string>();

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);

		if (!FormCtx.needsAssessmentOnly) {
			saveRecommendation(FormCtx.initialUserData?.email);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveRecommendation = async (contactEmail : string | undefined) => {
		FormCtx.setSkippedNeedsAssessment(false);
		await FormCtx.upsertFormValues({ recommended_term_life: recommendation });
		// send needs assessment tag
		await oliverAPI.postAddTagToContact({ tagName: 'Needs Assessment', email: contactEmail || '' });

		const na: NeedsAssessmentDTO = {
			email: contactEmail || '',
			recommendation: recommendation.toString(),
			debt_loc: FormCtx.needsAssessment.debts?.lineOfCredit || '',
			debt_cc: FormCtx.needsAssessment.debts?.creditCard || '',
			debt_student_loan: FormCtx.needsAssessment.debts?.studentLoans || '',
			debt_auto: FormCtx.needsAssessment.debts?.autoLoans || '',
			debt_other: FormCtx.needsAssessment.debts?.other || '',
			debt_mortgage_balance: FormCtx.needsAssessment.debts?.mortgageBalance || '',
			why_to_buy: FormCtx.needsAssessment.reasonToBuy?.toString() || '',
			why_to_buy_other: FormCtx.needsAssessment.reasonToBuyOther || '',
			annual_income: FormCtx.needsAssessment.annualIncome || '',
			annual_income_years: FormCtx.needsAssessment.annualIncomeYears || '',
			annual_income_years_other: FormCtx.needsAssessment.annualIncomeYearsOther || '',
			has_wants_num_children: FormCtx.needsAssessment.numberChildren || '',
			expense_tuition: FormCtx.needsAssessment.expenses?.tuition || '',
			expense_wedding: FormCtx.needsAssessment.expenses?.wedding || '',
			expense_legacy: FormCtx.needsAssessment.expenses?.legacy || '',
			expense_funeral: FormCtx.needsAssessment.expenses?.funeral || '',
			savings_rrsp: FormCtx.needsAssessment.valueOfSavings?.rrsp || '',
			savings_tfsa: FormCtx.needsAssessment.valueOfSavings?.tfsa || '',
			savings_chequing: FormCtx.needsAssessment.valueOfSavings?.chequingAccounts || '',
			savings_savings: FormCtx.needsAssessment.valueOfSavings?.savingsAccount || '',
			savings_other: FormCtx.needsAssessment.valueOfSavings?.other || '',
			existing_insurance_employer: FormCtx.needsAssessment.existingInsurance?.employer || '',
			existing_insurance_bank: FormCtx.needsAssessment.existingInsurance?.bank || '',
			existing_insurance_life: FormCtx.needsAssessment.existingInsurance?.life || '',
		};
		oliverAPI.postAddNeedsAssessmentToContact({ na: na });
	};

	const next = () => {
		const NAOnly = FormCtx.needsAssessmentOnly;
		FormCtx.setNeedsAssessmentOnly(NAOnly ? false : undefined);
		if (NAOnly) {
			history.push(NavRoutes.QuoteAboutYou);
		} else {
			history.push(NavRoutes.QuoteSelectPolicy, { updatePolicy: true });
		}
	};

	const { control, handleSubmit, errors } = useForm<IinitialQuestions>({
		defaultValues: {
			firstName: FormCtx.initialUserData?.firstName || '',
			lastName: FormCtx.initialUserData?.lastName || '',
			email: FormCtx.initialUserData?.email || '',
			optIn: FormCtx.initialUserData?.optIn
		},
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (values: IinitialQuestions) => {
		FormCtx.setIsLoading(true);
		if (emailSendError !== undefined) {
			setEmailSendError(undefined);
		}
		FormCtx.setInitialUserData(values);
		const result = await FormCtx.upsertFormValues(values);

		saveRecommendation(values.email);

		if (result !== undefined) {
			const is_pdf_sent = await oliverAPI.postReport({ needs_assessment_id: result.needs_assessment_id, is_needs_assessment: true });
			setEmailSent(is_pdf_sent);
			if (!is_pdf_sent) {
				setEmailSendError('There was a problem sending the email. Please verify your information and try again.');
			}
		} else {
			setEmailError(ValidationMessages.email);
		}
		FormCtx.setIsLoading(false);
	};

	const scrollToForm = () => {
		if (formRef && formRef.current) {
			formRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<QuoteContainer largeColumn={true}>
			<div className="row mb-4 border-bottom border-gray-400 pb-3">
				<div className="col">
					<ThumbsUp className="mb-3 img-fluid" style={{ minWidth: 152, height: 155, fill: 'none' }} />
				</div>
				<div className="col-12 col-md-9">
					<h2>Our Recommendation:</h2>
					<h5 className="text-primary">${recommendation.toLocaleString('en')} of Coverage</h5>
					<p>
						Based on the information you’ve provided;
						we estimate you need this amount of coverage. For a detailed and comprehensive
						analysis, we encourage you to speak with one of our advisors today!
					</p>
					<div className="mb-3">
						<Button className="btn-primary mb-2 mr-2" onClick={next}>
							{FormCtx.needsAssessmentOnly ? 'Get a Quote' : 'Add to Quote'}
						</Button>
						{FormCtx.needsAssessmentOnly && !emailSent &&
							<Button className="btn-outline-primary mb-2" onClick={scrollToForm}>
								Email me a Report
							</Button>
						}
					</div>
				</div>
			</div>
			<h4 className="line-dash-header mb-4">Details</h4>

			<div className="d-flex justify-content-between bg-gray-100 p-2 rounded-sm h5 mb-0">
				<span>Debts</span>
				<span>{calculations.debts.toLocaleString('en')}</span>
			</div>
			<div className="p-2 mb-2">
				<span className="d-block font-weight-bold">
					Current Debts
				</span>
				<span className="d-block">
					${calculations.debts.toLocaleString('en')}
				</span>
			</div>
			<div className="d-flex justify-content-between bg-gray-100 p-2 rounded-sm h5 mb-0">
				<span>Expenses</span>
				<span>{(calculations.expenses + calculations.income).toLocaleString('en')}</span>
			</div>
			<div className="p-2 mb-2">
				<span className="d-block font-weight-bold">
					Basic Expenses
				</span>
				<span className="d-block mb-2">
					${calculations.income.toLocaleString('en')}
				</span>
				<span className="d-block font-weight-bold">
					Additional Expenses
				</span>
				<span className="d-block">
					${calculations.expenses.toLocaleString('en')}
				</span>
			</div>
			<div className="d-flex justify-content-between bg-gray-100 p-2 rounded-sm h5 mb-0">
				<span>Savings and Assets</span>
				<span>{((calculations.savings + calculations.insurance) * -1).toLocaleString('en')}</span>
			</div>
			<div className="p-2 mb-2">
				<span className="d-block font-weight-bold">
					Total Savings &amp; Investments
				</span>
				<span className="d-block mb-2">
					-${calculations.savings.toLocaleString('en')}
				</span>
				<span className="d-block font-weight-bold">
					Existing Insurance
				</span>
				<span className="d-block">
					-${(calculations.insurance).toLocaleString('en')}
				</span>
			</div>
			<div className="d-flex justify-content-between border-top border-gray-400 mt-4 pt-4 mb-2">
				<h5>Our Recommendation: </h5>
				<h2>${recommendation.toLocaleString('en')}</h2>
			</div>

			<div className="d-flex justify-content-between">
				<div>
					<Button onClick={() => history.push(NavRoutes.QuoteNeedsAssessmentStep7)} type="button" className="btn-outline-primary mr-2 mb-2">Back</Button>
					<Button type="button" className="btn-primary mb-2" onClick={next}>
						{FormCtx.needsAssessmentOnly ? 'Get a Quote' : 'Add to Quote'}
					</Button>
				</div>
				<p className="small text-right">
					<i>Recommendation rounded to <br />the nearest 10,000.</i>
				</p>
			</div>

			{FormCtx.needsAssessmentOnly && !emailSent &&
				<div className="shadow-sm rounded p-3 mt-4" ref={formRef}>
					<div className="m-2 d-flex align-items-center flex-column">
						<form>
							<h4 className="mb-3">Get a copy of your needs assessment sent to your email.</h4>
							<div className="row">
								<div className="form-group col-md-6">
									<label htmlFor="firstName">First Name</label>
									<Controller
										name="firstName"
										control={control}
										rules={{ required: ValidationMessages.required }}
										render={({ onChange, value }) =>
											<input
												type="text"
												className="form-control"
												value={value}
												onChange={onChange}
												name="firstName"
												id="firstName"
											/>
										}
									/>
									<ErrorMessage>{errors.firstName?.message}</ErrorMessage>
								</div>
								<div className="form-group col-md-6">
									<label htmlFor="lastName">Last Name</label>
									<Controller
										name="lastName"
										control={control}
										rules={{ required: ValidationMessages.required }}
										render={({ onChange, value }) =>
											<input
												type="text"
												className="form-control"
												value={value}
												onChange={onChange}
												name="lastName"
												id="lastName"
											/>
										}
									/>
									<ErrorMessage>{errors.lastName?.message}</ErrorMessage>
								</div>
							</div>
							<div className="form-group">
								<label htmlFor="email">Email</label>
								<Controller
									name="email"
									control={control}
									rules={{
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: ValidationMessages.email
										},
										required: ValidationMessages.required
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											value={value}
											onChange={(event) => {
												onChange(event);
												if (emailError !== undefined) { setEmailError(undefined); }
											}}
											name="email"
											id="email"
										/>
									}
								/>
								<ErrorMessage>{errors.email?.message ?? emailError}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="optIn">Email Subscription</label>
								<div className="row mx-1">
									<Controller
										className="col"
										name="optIn"
										control={control}
										render={({ onChange, value }) =>
											<Checkbox
												value={value}
												onChange={e => onChange(e.target.checked)}
											/>
										}
									/>
									<div className="col">
										Yes, I would like to receive e-mail according to my subscription preferences. I understand I can unsubscribe at any time.
									</div>
								</div>
							</div>
							<Button
								className={'btn btn-outline-primary mb-2'}
								disabled={FormCtx.isLoading === true}
								onClick={handleSubmit(onSubmit)}
							>
								{FormCtx.isLoading === true ? 'Sending Report' : 'Send Me My Report'}
								{FormCtx.isLoading === true && <IconLoad className={'policy-spinner ml-2 spinner-grow-sm spin'} />}
							</Button>
						</form>
					</div>
				</div>
			}

			{emailSent &&
				<div className="shadow-sm rounded p-3 mt-4">
					<h3 className="mb-2">Success! A report has been sent to</h3>
					<span className="text-primary">{FormCtx.initialUserData?.email}</span>
					<p className="mb-0">Check your email to see your needs assessment report.
					</p>
				</div>
			}
		</QuoteContainer>
	);
};
export default NeedsAssessmentStep8;
