import { ApplicationDataContext } from 'application/AboutYouQuestions/ApplicationContext';
import { SubmissionContext } from 'application/SubmissionContext';
import { ApplicationPages, NavRoutes, preventDefault } from 'common/utils';
import { CheckboxButton, Divider, FormButtons, LoadContainer, QuestionContainer, QuoteContainer, Steps } from 'components/cpaipw';
import { Accordion } from 'components/cpaipw/Accordion';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { FormContext } from 'quote/FormContext';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AlcoholicBevaragesForm } from './lifestyleComponents/AlcoholicBeveragesForm';
import { DrugsOrNarcoticsForm } from './lifestyleComponents/DrugsOrNarcoticsForm';
import { EcigarettesForm } from './lifestyleComponents/EcigarettesForm';
import { TobaccoForm } from './lifestyleComponents/TobaccoForm';
import { TobaccoSubstitureForm } from './lifestyleComponents/TobaccoSubstituteForm';
import { LifestyleActionContext, LifestyleDataContext } from './LifestyleContext';


const LifestyleApplicationStep6: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);

	const appDataContext = React.useContext(ApplicationDataContext);

	const lifestyleActionContext = React.useContext(LifestyleActionContext);
	const lifestyleDataContext = React.useContext(LifestyleDataContext);
	const submissionCtx = React.useContext(SubmissionContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.lifestyle);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});

	const { control, handleSubmit, errors, watch, setError } = methods;


	const watchNa = watch(['notApplicable']);

	const watchAll = watch();
	const formHasFields = Object.keys(watchAll).filter(v => v !== 'notApplicable');

	const onValidSubmit = async (val: any) => {
		const keys = Object.keys(val);
		if (keys.includes('notApplicable') && keys.length === 1 && val['notApplicable'] === false) {
			setError('notApplicable', { message: 'If none of the situations are applicable, please select "Not Applicable"' });
			return;
		}

		lifestyleActionContext.extractLifestyleDataSection6(val, appDataContext.applicationData.children!);
		submissionCtx.setSubmitApplication(true);
		history.push(NavRoutes.ApplicationHistoryStep1);
	};

	const tobaccoUsers = lifestyleDataContext.lifestyleQuestions.section6 && lifestyleDataContext.lifestyleQuestions.section6.tobacco
		&& lifestyleDataContext.lifestyleQuestions.section6.tobacco.length > 0 ?
		lifestyleDataContext.lifestyleQuestions.section6.tobacco.map(t => t.idOfApplicantOrChildren) : [];

	const eCigUsers = lifestyleDataContext.lifestyleQuestions.section6 && lifestyleDataContext.lifestyleQuestions.section6.eCigarettes &&
		lifestyleDataContext.lifestyleQuestions.section6.eCigarettes.length > 0 ?
		lifestyleDataContext.lifestyleQuestions.section6.eCigarettes.map(t => t.idOfApplicantOrChildren) : [];

	const tobaccoSubUsers = lifestyleDataContext.lifestyleQuestions.section6 && lifestyleDataContext.lifestyleQuestions.section6.tobaccoSubstitute &&
		lifestyleDataContext.lifestyleQuestions.section6.tobaccoSubstitute.length > 0 ?
		lifestyleDataContext.lifestyleQuestions.section6.tobaccoSubstitute.map(t => t.idOfApplicantOrChildren) : [];

	const alcoholicBevyUsers = lifestyleDataContext.lifestyleQuestions.section6 && lifestyleDataContext.lifestyleQuestions.section6.alcholicBeverage &&
		lifestyleDataContext.lifestyleQuestions.section6.alcholicBeverage.length > 0 ?
		lifestyleDataContext.lifestyleQuestions.section6.alcholicBeverage.map(t => t.idOfApplicantOrChildren) : [];

	const narcoticsUsers = lifestyleDataContext.lifestyleQuestions.section6 && lifestyleDataContext.lifestyleQuestions.section6.drugsOrNarcotics &&
		lifestyleDataContext.lifestyleQuestions.section6.drugsOrNarcotics.length > 0 ?
		lifestyleDataContext.lifestyleQuestions.section6.drugsOrNarcotics.map(t => t.idOfApplicantOrChildren) : [];

	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<LoadContainer isLoading={submissionCtx.submissionLoading}>
					<h1>Lifestyle Questions</h1>
					<Steps currentstep={6} totalSteps={6} />
					<form onSubmit={preventDefault}>
						<p>
							How much of the following does the proposed insured consume? If none, indicate 0.
						</p>
						<div className="d-flex flex-column">
							<QuestionContainer index={1}>
								<p className="font-weight-bold">Tobacco</p>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={tobaccoUsers}>
									<TobaccoForm data={lifestyleDataContext.lifestyleQuestions.section6?.tobacco} />
								</Accordion>
								<Divider />
							</QuestionContainer>
							<QuestionContainer index={2}>
								<p className="font-weight-bold">E-cigarettes</p>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={eCigUsers}>
									<EcigarettesForm data={lifestyleDataContext.lifestyleQuestions.section6?.eCigarettes} />
								</Accordion>
								<Divider />
							</QuestionContainer>
							<QuestionContainer index={3}>
								<p className="font-weight-bold">Tobacco substitute</p>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={tobaccoSubUsers}>
									<TobaccoSubstitureForm data={lifestyleDataContext.lifestyleQuestions.section6?.tobaccoSubstitute} />
								</Accordion>
								<Divider />
							</QuestionContainer>
							<QuestionContainer index={4}>
								<p className="font-weight-bold">Alcoholic beverages</p>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={alcoholicBevyUsers}>
									<AlcoholicBevaragesForm data={lifestyleDataContext.lifestyleQuestions.section6?.alcholicBeverage} />
								</Accordion>
								<Divider />
							</QuestionContainer>
							<QuestionContainer index={5}>
								<p className="font-weight-bold">Drugs or narcotics (including marijuana)?</p>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={narcoticsUsers}>
									<DrugsOrNarcoticsForm data={lifestyleDataContext.lifestyleQuestions.section6?.drugsOrNarcotics} />
								</Accordion>
								<Divider />
							</QuestionContainer>
							<QuestionContainer index={6}>
								<Controller
									name="notApplicable"
									control={control}
									defaultValue={false}
									render={({ onChange, value }) =>
										<CheckboxButton
											name="notApplicable"
											isActive={value}
											label="None of the above"
											onClick={() => onChange(!value)}
											disabled={formHasFields.length > 0}
										/>
									}
								/>
								<ErrorMessage>{errors?.notApplicable?.message}</ErrorMessage>
							</QuestionContainer>
						</div>
						<FormButtons
							onClickBack={() => history.push(NavRoutes.ApplicationLifestyleStep5)}
							onClickNext={handleSubmit(onValidSubmit)}
						/>
					</form>
				</LoadContainer>
			</QuoteContainer>
		</FormProvider>
	);


};

export default LifestyleApplicationStep6;