import React from 'react';

interface IOption {
	label: string,
	value: string
}

interface IProps {
	name: string,
	options: IOption[],
	onChange: (value: string) => void;
	selected: string,
	placeholder?: string;
	disabled?: boolean;
}

export const Select: React.FC<IProps> = (props: IProps) => {

	return (
		<React.Fragment>
			<select
				id={props.name}
				name={props.name}
				className="form-control"
				value={props.selected}
				onChange={(e) => (props.onChange(e.target.value))}
				placeholder={props.placeholder}
				disabled={props.disabled}
			>
				{props.options.map((province, key) => {
					return (
						<option
							key={key}
							value={province.value}
						>
							{province.label}
						</option>
					);
				})}
			</select>
		</React.Fragment>
	);
};
