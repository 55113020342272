import React from 'react';
import { FormContext } from 'quote/FormContext';
import { ApplicationPages } from 'common/utils';
import { Page } from 'components/common';
import {ReactComponent as ThumbsUp} from 'assets/img/thumbs-up.svg';

interface IProps {

}

const ApplicationThankYou: React.FC<IProps> = () => {
	const FormCtx = React.useContext(FormContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.review);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page>
			<div className="container">
				<div className="col-lg-10 offset-lg-1">
					<ThumbsUp style={{width:152, height: 155, fill: 'none'}} />
					<h2 className="mt-3 mb-4">Woo-hoo! You’re all done!</h2>
					<h4>Next Steps</h4>
					<div className="row row-cols-1 row-cols-md-2 row-cols-lg-2">
						<div className="col mb-3 mb-md-4">
							<div className="card card-body h-100 p-md-4 shadow">
								<div className="d-md-flex align-items-center justify-content-start">
									<div className="d-block mr-3">
										<div className="badge-round d-flex flex-fill bg-primary-light text-primary mb-3">01</div>
									</div>
									<h5 className="card-title d-inline-block mb-2 mb-md-3">Your application will be reviewed.</h5>
								</div>
								<p className="card-text">
									Your application will reviewed by a member of our team.
								</p>
							</div>
						</div>
						<div className="col mb-3 mb-md-4">
							<div className="card card-body h-100 p-md-4 shadow">
								<div className="d-md-flex align-items-center justify-content-start">
									<div className="d-block mr-3">
										<div className="badge-round d-flex flex-fill bg-primary-light text-primary mb-3">02</div>
									</div>
									<h5 className="card-title d-inline-block mb-2 mb-md-3">We will contact you with next steps.</h5>
								</div>
								<p className="card-text">
									We will contact you with next steps in the next 2 business days.
									If you have any questions chat one-on-one.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default ApplicationThankYou;