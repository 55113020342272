import React from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { CheckboxButton } from './CheckboxButton';
import { AccordionUser, ApplicationDataContext } from 'application/AboutYouQuestions/ApplicationContext';

interface IProps {
	disabled?: boolean;
	defaultState?: string[];
	children: React.ReactElement;
}

export const accordionStyles = {
	firstItem: 'rounded-bottom-0 rounded-sm-top',
	lastItem: 'rounded-top-0 rounded-sm-bottom',
	centerItem: 'rounded-bottom-0 rounded-top-0',
	allItems: 'border-0 mb-0',

	firstDiv: 'border border-gray-400',
	centerDiv: 'border-left border-right border-bottom border-gray-400',
	lastDiv: 'border-left border-right border-bottom border-gray-400',
	activeDiv: 'bg-gray-100'
};

export const Accordion: React.FC<IProps> = ( props: IProps ) => {
	const [selectedUsers, setSelectedUsers] = React.useState<string[]>(props.defaultState || []);
	const [users, setUsers] = React.useState<AccordionUser[]>([]);

	const appDataContext = React.useContext(ApplicationDataContext);

	React.useEffect(() => {
		const newUsers = appDataContext.getAccordionUsers();
		setUsers(newUsers);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if ( props.disabled === true ) {
			setSelectedUsers([]);
		}
	}, [props.disabled]);

	return (
		<div className="rounded-sm mb-4">
			{users.map((user: AccordionUser, index: number) => {
				return (
					<div
						key={`expendable-${index}`}
						className={
							classNames(
								index === 0 && accordionStyles.firstDiv,
								index === 0 && accordionStyles.firstItem,
								(index !== 0 && index !== (users.length - 1)) && accordionStyles.centerDiv,
								(index !== 0 && index !== (users.length - 1)) && accordionStyles.centerItem,
								index === (users.length - 1) && accordionStyles.lastItem,
								index === (users.length - 1) && accordionStyles.lastDiv,
								selectedUsers.includes(user.userId) && accordionStyles.activeDiv
							)
						}
					>
						<CheckboxButton
							label={user.displayName}
							disabled={props.disabled}
							className={
								classNames(
									index === 0 && accordionStyles.firstItem,
									(index !== 0 && index !== (users.length - 1)) && accordionStyles.centerItem,
									index === (users.length - 1) && accordionStyles.lastItem,
									accordionStyles.allItems
								)
							}
							isActive={selectedUsers.includes(user.userId)}
							onClick={() => handleSelect(user.userId)}
							value=""
						/>
						<AnimateHeight duration={350} height={selectedUsers.includes(user.userId) ? 'auto' : 0}>
							{selectedUsers.includes(user.userId) && (
								<React.Fragment>
									{React.cloneElement(props.children, { user: user.userId })}
								</React.Fragment>
							)}
						</AnimateHeight>
					</div>
				);
			})
			}
		</div>
	);

	function handleSelect(user: string) {
		const currentState = [...selectedUsers];
		if ( selectedUsers.includes(user) ) {
			setSelectedUsers(currentState.filter((v: string) => v !== user));
		} else {
			currentState.push(user);
			setSelectedUsers(currentState);
		}
	}
};
