import { ApplicationDataContext, IChild } from 'application/AboutYouQuestions/ApplicationContext';
import { getChildrenDetails } from 'application/utils';
import React from 'react';
import { HealthQuestionaire, IHealthQuestion, IHealthQuestionWithUserName } from './HealthTypes';

const defaultHealthQuestionaire = {} as HealthQuestionaire;

interface IHealthQuestionDataContext {
	healthQuestionaireData: HealthQuestionaire;
}

interface IHealthQuestionDataActionContext {
	setHealthQuestionaireData: React.Dispatch<React.SetStateAction<HealthQuestionaire>>;
	extractHealthData: (vals: any, children?: IChild[]) => IHealthQuestionWithUserName[];
}

const HealthDataContext = React.createContext<IHealthQuestionDataContext>({
	healthQuestionaireData: {},
});

const HealthActionContext = React.createContext<IHealthQuestionDataActionContext>({
	setHealthQuestionaireData: () => { },
	extractHealthData: () => []
});

const HealthProvider = (props: any) => {
	const [healthQuestionaireData, setHealthQuestionaireData] = React.useState<HealthQuestionaire>(defaultHealthQuestionaire);
	const applicationCtx = React.useContext(ApplicationDataContext);

	const extractHealthData = (vals: any, children?: IChild[]) => {
		if (vals === null || vals === undefined ) {
			return [] as IHealthQuestionWithUserName[];
		}
		const keys = Object.keys(vals);
		const result = keys.map(id => {
			return {
				nameOfApplicantOrChildren: id !== 'Applicant' ? getChildrenDetails(id, children) : applicationCtx.applicationData.firstName, // add first name, last name
				idOfApplicantOrChildren: id,
				healthQuestionsOfApplicantOrChildren: {
					dateOfIllness: vals[id].dateOfIllness,
					lengthOfHospitalization: vals[id].lengthOfHospitalization.value,
					lengthOfHospitalizationMeasurement: vals[id].lengthOfHospitalization.unit,
					lengthOfIllness: vals[id].lengthOfIllness.value,
					lengthOfIllnessMeasurement: vals[id].lengthOfIllness.unit,
					nameAndAddressOfPhysiciansOrHospitals: vals[id].nameAndAddressOfPhysiciansOrHospitals,
					natureOfIllness: vals[id].natureOfIllness,
				} as IHealthQuestion,
			} as IHealthQuestionWithUserName;
		});
		return result;
	};

	return (
		<HealthActionContext.Provider
			value={{
				setHealthQuestionaireData,
				extractHealthData
			}}
		>
			<HealthDataContext.Provider
				value={{
					healthQuestionaireData
				}}
			>
				{props.children}
			</HealthDataContext.Provider>
		</HealthActionContext.Provider>
	);
};

export {
	HealthActionContext,
	HealthDataContext,
};

export default HealthProvider;