import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import withTracker from './withTracker';
import Routes from './Routes';

import './App.css';
import FormProvider from 'quote/FormContext';
import PolicyProvider from 'quote/policy/PolicyContext';
import ApplicationProvider from 'application/AboutYouQuestions/ApplicationContext';
import HistoryProvider from 'application/HistoryQuestions/HistoryContext';
import HealthProvider from 'application/HealthQuestions/HealthContext';
import LifestyleProvider from 'application/LifestyleQuestions/LifestyleContext';
import BeneficiaryProvider from 'application/BeneficiaryQuestions/BeneficiaryContext';
import SubmissionProvider from 'application/SubmissionContext';

interface IProps {

}

const App: React.FC<IProps> = (props: IProps) => {
	return (
		<Router>
			<FormProvider>
				<PolicyProvider>
					<ApplicationProvider>
						<BeneficiaryProvider>
							<HealthProvider>
								<LifestyleProvider>
									<HistoryProvider>
										<SubmissionProvider>
											<Route component={withTracker(Routes)} />
										</SubmissionProvider>
									</HistoryProvider>
								</LifestyleProvider>
							</HealthProvider>
						</BeneficiaryProvider>
					</ApplicationProvider>
				</PolicyProvider>
			</FormProvider>
		</Router>
	);
};

export default App;
