import React from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { CheckboxButton } from './CheckboxButton';
import { accordionStyles } from './Accordion';
import { BeneficiaryDataContext } from 'application/BeneficiaryQuestions/BeneficiaryContext';
import { useFormContext } from 'react-hook-form';

interface IProps {
	disabled?: boolean;
	children: React.ReactElement[];
}

export const BeneficiaryAccordion: React.FC<IProps> = ( props: IProps ) => {
	const [selected, setSelected] = React.useState<number[]>([]);
	const beneficiaryDataContext = React.useContext(BeneficiaryDataContext);
	const beneficaryData = beneficiaryDataContext.beneficiaryData;

	const methods = useFormContext();

	const {clearErrors } = methods;


	React.useEffect(() => {
		if ( props.disabled === true ) {
			setSelected([]);
		} else {
			const haveData = [];
			if (beneficaryData.individuals !== undefined && beneficaryData.individuals.length > 0) {
				haveData.push(0);
			}

			if (beneficaryData.corporations !== undefined && beneficaryData.corporations.length > 0) {
				haveData.push(1);
			}

			if (beneficaryData.estate !== undefined) {
				haveData.push(2);
			}

			setSelected(haveData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.disabled]);

	// const users = props.users;

	return (
		<div className="rounded-sm mb-4">
			{props.children.map((child: React.ReactElement, index: number) => {
				return (
					<div
						key={`expendable-${index}`}
						className={
							classNames(
								index === 0 && accordionStyles.firstDiv,
								index === 0 && accordionStyles.firstItem,
								(index !== 0 && index !== (props.children.length - 1)) && accordionStyles.centerDiv,
								(index !== 0 && index !== (props.children.length - 1)) && accordionStyles.centerItem,
								index === (props.children.length - 1) && accordionStyles.lastItem,
								index === (props.children.length - 1) && accordionStyles.lastDiv,
								selected.includes(index) && accordionStyles.activeDiv
							)
						}
					>
						<CheckboxButton
							label={child.props?.displayName || ''}
							disabled={props.disabled}
							className={
								classNames(
									index === 0 && accordionStyles.firstItem,
									(index !== 0 && index !== (props.children.length - 1)) && accordionStyles.centerItem,
									index === (props.children.length - 1) && accordionStyles.lastItem,
									accordionStyles.allItems
								)
							}
							isActive={selected.includes(index)}
							onClick={() => handleSelect(index)}
							value=""
						/>
						<AnimateHeight duration={350} height={selected.includes(index) ? 'auto' : 0}>
							{selected.includes(index) && (
								<React.Fragment>
									{React.cloneElement(child)}
								</React.Fragment>
							)}
						</AnimateHeight>
					</div>
				);
			})
			}
		</div>
	);

	function handleSelect(index: number) {
		const currentState = [...selected];
		if ( selected.includes(index) ) {
			setSelected(currentState.filter((v: number) => v !== index));
		} else {
			currentState.push(index);
			setSelected(currentState);
			clearErrors('hasBeneficiary');
		}
	}
};
