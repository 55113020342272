import React from 'react';
import classNames from 'classnames';
import {ReactComponent as Done} from 'assets/icons/icon-done.svg';

interface IProps {
	name?: string;
	label?: string;
	value?: string;
	isActive: boolean;
	isToggle?: boolean;
	onClick: () => void;
	className?: string;
	disabled?: boolean;
}

export const RadioButton: React.FC<IProps> = (props: IProps) => {
	const btnToggleClassName = 'form-btn-toggle';
	const btnRadioClassName = 'form-btn-radio mb-3 d-flex align-items-start';

	return (
		<label className={classNames(
			'form-btn-input bg-white text-left',
			props.isToggle && btnToggleClassName,
			!props.isToggle && btnRadioClassName,
			props.isActive && 'active',
			props.className
		)}>
			<input
				type="radio"
				name={props.name}
				checked={props.isActive}
				onClick={props.onClick}
				onChange={() => true}
			/>
			{props.isToggle && <Done className="text-primary" />}
			<span>{props.label}</span>
		</label>
	);
};