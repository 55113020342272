import React from 'react';
import { CheckboxButton, Divider, FormButtons, QuestionContainer, QuoteContainer, Steps } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { ApplicationPages, NavRoutes, preventDefault } from 'common/utils';
import { FormContext } from 'quote/FormContext';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { Accordion } from 'components/cpaipw/Accordion';
import { HealthAccordion } from 'components/cpaipw/HealthAccordion';
import { HealthActionContext, HealthDataContext } from './HealthContext';
import { IHealthAbnormalities, IHealthQuestionWithUserName } from './HealthTypes';
import { ApplicationDataContext } from 'application/AboutYouQuestions/ApplicationContext';
import { SubmissionContext } from 'application/SubmissionContext';

const HealthApplicationStep5: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const submissionCtx = React.useContext(SubmissionContext);

	const healthActionContext = React.useContext(HealthActionContext);
	const healthDataContext = React.useContext(HealthDataContext);
	const appDataContext = React.useContext(ApplicationDataContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.health);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const healthData = healthDataContext.healthQuestionaireData?.section5Questions;

	const healthDataKeys = healthData && Object.keys(healthData);

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			notApplicable: healthDataKeys !== undefined && healthDataKeys?.length === 0 ? true : false
		}
	});
	const { control, watch, setError, errors } = methods;

	const watchNa = watch(['notApplicable']);

	const watchAll = watch();
	const formHasFields = Object.keys(watchAll).filter(v => v !== 'notApplicable');

	const defaultselectedUsers: any = {};

	if (healthDataKeys !== undefined) {
		healthDataKeys?.forEach((key: string) => {
			const data = healthData?.[key as keyof IHealthAbnormalities];
			const users = data?.map(item => {
				return item.idOfApplicantOrChildren;
			});
			defaultselectedUsers[key] = users;
		});
	}

	const onHandleSubmit = async (val: any) => {
		const keys = Object.keys(val);
		if (keys.includes('notApplicable') && keys.length === 1 && val['notApplicable'] === false) {
			setError('notApplicable', { message: 'If none of the situations are applicable, please select "Not Applicable"' });
			return;
		}

		const section5Questions = val.section5Questions !== undefined ? val.section5Questions : {};
		const contentKeys = Object.keys(section5Questions);
		const section5Data: IHealthAbnormalities = {};
		contentKeys.forEach(key => {
			const data = healthActionContext.extractHealthData(val.section5Questions[key], appDataContext.applicationData.children) as IHealthQuestionWithUserName[];
			section5Data[key as keyof IHealthAbnormalities] = data;
		});

		healthActionContext.setHealthQuestionaireData({ ...healthDataContext.healthQuestionaireData, section5Questions: section5Data });
		submissionCtx.setSubmitApplication(true);
		history.push(NavRoutes.ApplicationLifestyleStep1);
	};

	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<h1>Health</h1>
				<Steps currentstep={5} totalSteps={5} />
				<form onSubmit={preventDefault}>
					<p>
						<b>In the last 5 years,</b> has the proposed insured consulted a health professional, been diagnosed, recieved treatment or
						undergone surger for any of the following:
					</p>
					<div className="flex-column flex-fill">
						<QuestionContainer index={1}>
							<div className="form-group">
								<label htmlFor="question5-1">
									<b>Abnormality of the immune system, including AIDS or a positive HIV test or other immunological infection or disorder. </b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.abnormalitiesIncludingAidsOrHiv}>
									<HealthAccordion name="section5Questions.abnormalitiesIncludingAidsOrHiv" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={2}>
							<div className="form-group">
								<label htmlFor="question5-2">
									<b>Cancer, tumor, polyp or other malignant disease.</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.cancer}>
									<HealthAccordion name="section5Questions.cancer" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={3}>
							<div className="form-group">
								<label htmlFor="question5-3">
									<b>Endocrine system disorders, including diabetes, thyroid disease or other endocrine problems.</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.endocrineSystem}>
									<HealthAccordion name="section5Questions.endocrineSystem" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={4}>
							<div className="form-group">
								<label htmlFor="question5-4">
									<b>Lung disorders, including asthma, emphysema, pulmonary fibrosis, tuberculosis, sleep apnea or other chronic lung or respiratory problems</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.lungDisorders}>
									<HealthAccordion name="section5Questions.lungDisorders" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={5}>
							<div className="form-group">
								<label htmlFor="question5-5">
									<b>Cystic fibrosis</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.cysticFibrosis}>
									<HealthAccordion name="section5Questions.cysticFibrosis" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={6}>
							<div className="form-group">
								<label htmlFor="question5-6">
									<b>Physical disorder, malformation or infirmity</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.physicalDisorder}>
									<HealthAccordion name="section5Questions.physicalDisorder" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={7}>
							<div className="form-group">
								<label htmlFor="question5-7">
									<b>Heart disease or problems with the circulatory system, including hypertension, infarct, argina, stoke, transient ischemic attack (TIA) or
									other heart, blood vessel or circulatory problems.
									</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.heartDisease}>
									<HealthAccordion name="section5Questions.heartDisease" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={8}>
							<div className="form-group">
								<label htmlFor="question5-8">
									<b>Gastrointestinal disorders, including Crohn's disease and ulcerative colitis, hepatitis, hidden hepatitis, cirrhosis or other
									liver, pancreas, stomach, or intestinal problems.
									</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.gastroIntestinal}>
									<HealthAccordion name="section5Questions.gastroIntestinal" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={9}>
							<div className="form-group">
								<label htmlFor="question5-9">
									<b>Blood disorders, including anemia, leukemia, hemophilia or other blood problems</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.bloodDisorders}>
									<HealthAccordion name="section5Questions.bloodDisorders" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={10}>
							<div className="form-group">
								<label htmlFor="question5-10">
									<b>Cerebral, neurological or psychological disorders, including epilepsy, convulsions, dizziness, loss of consciousness, coma, depression
									anxiety, eating disorders, job-related burnout, paralysis, multiple sclerosis, motor neuron disorders, Alzheimer's disease,
									Parkinson's disease or other cerebral, nervous or psychological problems.
									</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.cerebral}>
									<HealthAccordion name="section5Questions.cerebral" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={11}>
							<div className="form-group">
								<label htmlFor="question5-11">
									<b>Neurological impairment, including autism spectrum disorder, Rett syndrome, cerebral palsy, muscular dystrophy, hyperactivity,
									attention deficit disorder, delayed maturation, intellectual disability.
									</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.neurologicalImpairment}>
									<HealthAccordion name="section5Questions.neurologicalImpairment" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={12}>
							<div className="form-group">
								<label htmlFor="question5-12">
									<b>Problems with kidneys, urinary tract, bladder, prostate, breasts (including abnormal mammogram or ultrasound)
									or genitals (including abnormal PAP test) or presence of sugar, blood or protein in the urine.
									</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.problemsWithKidneys}>
									<HealthAccordion name="section5Questions.problemsWithKidneys" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={13}>
							<div className="form-group">
								<label htmlFor="question5-13">
									<b>Muscle, joint, and bone conditions, including chronic fatigue, fibromyalgia, arthristis, all forms of lupus, back or neck pain
									or other musculoskeleteal problems.
									</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.muscleJointsAndBone}>
									<HealthAccordion name="section5Questions.muscleJointsAndBone" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={14}>
							<div className="form-group">
								<label htmlFor="question5-14">
									<b>Ear nose and throat conditions (not including otitis) or eye problems (not including myopia, presbyopia, hyperopia and astigmatism)</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.earNoseAndThroat}>
									<HealthAccordion name="section5Questions.earNoseAndThroat" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={15}>
							<div className="form-group">
								<label htmlFor="question5-15">
									<b>Other illnesses or medical problems not listed above.</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers.other}>
									<HealthAccordion name="section5Questions.other" section="section5" />
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={16}>
							<Controller
								name="notApplicable"
								control={control}
								defaultValue={false}
								render={({ onChange, value }) =>
									<CheckboxButton
										name="notApplicable"
										isActive={value}
										disabled={formHasFields.length > 0}
										label="Not Applicable"
										onClick={() => onChange(!value)}
									/>
								}
							/>
							<ErrorMessage>{errors?.notApplicable?.message}</ErrorMessage>
						</QuestionContainer>
					</div>
					<FormButtons
						onClickBack={() => history.push(NavRoutes.ApplicationHealthStep4)}
						onClickNext={methods.handleSubmit(onHandleSubmit)}
					/>
				</form>
			</QuoteContainer>
		</FormProvider>
	);
};

export default HealthApplicationStep5;