import classNames from 'classnames';
import React from 'react';

interface IProps {
	item: {
		label: string;
		description?: string;
		name?: any;
	}
	prepend?: string;
	append?: string;
	index?: number;
}

export function AmountField( props: React.PropsWithChildren<IProps> ) {
	const { item, index, children, prepend, append } = props;
	let bgColour = '';
	if ( index === undefined || ( index !== undefined && index % 2 === 0 ) ) {
		bgColour = 'bg-gray-100';
	}
	return (
		<div className={classNames('rounded px-3 py-2', bgColour)}>
			<div className="align-items-center row">
				<div className="col-md-6 mb-2 mb-md-0">
					<label className="d-block font-weight-normal mb-0">{item.label}</label>
					{item.description !== undefined && (
						<small>{item.description}</small>
					)}
				</div>
				<div className="col-md-6 mb-2 mb-md-0">
					<div className="input-group">
						{prepend && (
							<div className="input-group-prepend">
								<div className="input-group-text text-gray-600">{prepend}</div>
							</div>
						)}
						{children}
						{append && (
							<div className="input-group-append">
								<div className="input-group-text text-gray-600">{append}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}