
import React from 'react';
import HelloSign from 'hellosign-embedded';
import oliverAPI from 'api/OliverAPI';
import { ReactComponent as ThumbsUp } from 'assets/img/thumbs-up.svg';
import { useParams } from 'react-router';
import { Page } from 'components/common';
import { FormContext } from 'quote/FormContext';
import { useHistory } from 'react-router-dom';
import { ApplicationPages, NavRoutes } from 'common/utils';
import { LoadContainer } from 'components/cpaipw';
import { Button } from 'components/buttons';

const Sign: React.FC = () => {
	const FormCtx = React.useContext(FormContext);

	const [signatureDataLoading, setSignatureDataLoading] = React.useState<boolean>(true);
	const [clientHasSigned, setClientHasSigned] = React.useState<boolean>(false);
	const [redirectUser, setRedirectUser] = React.useState<boolean>(false);
	const [signatureData, setSignatureData] = React.useState<SignatureDTO>({});

	const history = useHistory();
	const { code } = useParams<{code: string}>();
	const client = new HelloSign({clientId: signatureData.client_id});

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.review);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		async function getSignature() {
			try {
				const sign = await oliverAPI.getSignatureRequest({ code });
				if ( sign.status_code === 'signed' ) {
					history.replace(NavRoutes.ApplicationThankYou);
					return;
				}
				setSignatureData(sign);
				setSignatureDataLoading(false);
			} catch (error) {
				return alert('The signature link seems to be invalid.');
			}
		}
		getSignature();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (clientHasSigned) {
			setRedirectUser(true);
		}
		return () => {
			setSignatureDataLoading(false);
			// setClientHasSigned(false);
		};
	}, [clientHasSigned]);

	React.useEffect(() => {
		if (redirectUser) {
			history.push(NavRoutes.ApplicationThankYou);
		}
		return () => {
			setSignatureDataLoading(false);
			setRedirectUser(false);
			setClientHasSigned(false);
		};
	}, [history, redirectUser]);

	client.on('sign', () => {
		setClientHasSigned(true);
	});

	client.on('finish', () => {
		setRedirectUser(true);
	});
	client.once('sign', () => {
		setClientHasSigned(true);
	});

	client.on('close', () => {
		setRedirectUser(true);
	});

	return (
		<Page>
			<LoadContainer isLoading={signatureDataLoading}>
				<div className="container">
					<div className="d-flex align-items-center mb-5">
						<ThumbsUp style={{ width: 152, height: 155, fill: 'none' }} />
						<div className="ml-4">
							<h2>Woo-hoo! You’re almost there!</h2>
							<p>Click the "Sign Now" button to provide your digital signature.</p>
							<Button className="btn-primary" disabled={signatureData.client_id === undefined} onClick={sign}>Sign Now</Button>
						</div>
					</div>
					<h4>Next Steps</h4>
					<div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3">
						<div className="col mb-3 mb-md-4">
							<div className="card card-body h-100 p-md-4 shadow">
								<div className="d-md-flex align-items-center justify-content-start">
									<div className="d-block mr-3">
										<div className="badge-round d-flex flex-fill bg-primary-light text-primary mb-3">01</div>
									</div>
									<h5 className="card-title d-inline-block mb-2 mb-md-3">Provide a digital signature</h5>
								</div>
								<p className="card-text">Provide a digital signature now by clicking “sign now”.</p>
							</div>
						</div>
						<div className="col mb-3 mb-md-4">
							<div className="card card-body h-100 p-md-4 shadow">
								<div className="d-md-flex align-items-center justify-content-start">
									<div className="d-block mr-3">
										<div className="badge-round d-flex flex-fill bg-primary-light text-primary mb-3">02</div>
									</div>
									<h5 className="card-title d-inline-block mb-2 mb-md-3">Your application will be reviewed</h5>
								</div>
								<p className="card-text">
									Your application will reviewed by a member of our team.
								</p>
							</div>
						</div>
						<div className="col mb-3 mb-md-4">
							<div className="card card-body h-100 p-md-4 shadow">
								<div className="d-md-flex align-items-center justify-content-start">
									<div className="d-block mr-3">
										<div className="badge-round d-flex flex-fill bg-primary-light text-primary mb-3">03</div>
									</div>
									<h5 className="card-title d-inline-block mb-2 mb-md-3">We will contact you with next steps</h5>
								</div>
								<p className="card-text">
									We will contact you with next steps in the next 2 business days.
									If you have any questions chat one-on-one.
								</p>
							</div>
						</div>
					</div>
				</div>
			</LoadContainer>
		</Page>
	);
	function sign() {
		client.open(signatureData.sign_url!, {
			testMode: true,
			skipDomainVerification: true,
			allowCancel: false,
		});
	}
};

export default Sign;