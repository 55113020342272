import React from 'react';
import { ApplicationPages, NavRoutes, preventDefault } from 'common/utils';
import { CheckboxButton, Divider, FormButtons, QuoteContainer, Steps } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { QuestionContainer } from 'components/cpaipw/QuestionContainer';
import { FormContext } from 'quote/FormContext';
import { HealthAccordion } from 'components/cpaipw/HealthAccordion';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { Accordion } from 'components/cpaipw/Accordion';
import { HealthActionContext, HealthDataContext } from './HealthContext';
import { IHealthQuestionWithUserName } from './HealthTypes';
import { ApplicationDataContext } from 'application/AboutYouQuestions/ApplicationContext';

const HealthApplicationStep1: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);

	const healthActionContext = React.useContext(HealthActionContext);
	const healthDataContext = React.useContext(HealthDataContext);
	const appDataContext = React.useContext(ApplicationDataContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.health);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const healthData = healthDataContext.healthQuestionaireData?.section1Questions;

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			notApplicable: (Array.isArray(healthData) && healthData.length === 0) ? true : false
		}
	});

	const { control, watch, setError, errors } = methods;

	const watchNa = watch(['notApplicable']);

	const watchAll = watch();
	const formHasFields = Object.keys(watchAll).filter(v => v !== 'notApplicable');

	const defaultselectedUsers = healthData !== undefined ? healthData?.map((item: IHealthQuestionWithUserName) => {
		return item.idOfApplicantOrChildren;
	}) : undefined;

	const onHandleSubmit = (val: any) => {
		const keys = Object.keys(val);
		if (keys.includes('notApplicable') && keys.length === 1 && val['notApplicable'] === false) {
			setError('notApplicable', { message: 'If none of the situations are applicable, please select "Not Applicable"' });
			return;
		}

		const section1Data = healthActionContext.extractHealthData(val.section1Questions, appDataContext.applicationData.children!) as IHealthQuestionWithUserName[];
		healthActionContext.setHealthQuestionaireData({ ...healthDataContext.healthQuestionaireData, section1Questions: section1Data });
		history.push(NavRoutes.ApplicationHealthStep2);
	};

	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<h1>Health</h1>
				<Steps currentstep={1} totalSteps={5} />
				<form onSubmit={preventDefault}>
					<div className="flex-column flex-fill">
						<QuestionContainer index={1}>
							<div className="form-group">
								<label className="font-weight-normal" htmlFor="section1Question"><b>In the last 2 years,</b> has the proposed insured taken medication
									(not including contraceptives, vitamins and natural products) prescribed by a doctor for <b>more than 4 consecutive weeks</b>
								</label>
								<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers}>
									<HealthAccordion
										name="section1Questions"
										section="section1"
									/>
								</Accordion>
							</div>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={2}>
							<Controller
								name="notApplicable"
								control={control}
								render={({ onChange, value }) =>
									<CheckboxButton
										name="notApplicable"
										isActive={value}
										disabled={formHasFields.length > 0}
										label="Not Applicable"
										onClick={() => onChange(!value)}
									/>
								}
							/>
							<ErrorMessage>{errors?.notApplicable?.message}</ErrorMessage>
						</QuestionContainer>
					</div>
					<FormButtons
						onClickBack={() => history.push(NavRoutes.ApplicationBeneficiariesStep3)}
						onClickNext={methods.handleSubmit(onHandleSubmit)}
					/>
				</form>
			</QuoteContainer>
		</FormProvider>
	);
};

export default HealthApplicationStep1;