import React from 'react';
import { ApplicationPages, NavRoutes, preventDefault, ValidationMessages } from 'common/utils';
import { Divider, FormButtons, QuoteContainer, Steps } from 'components/cpaipw';
import { FormContext } from 'quote/FormContext';
import { useHistory } from 'react-router-dom';
import { provinces } from '../utils';
import { Select } from 'components/cpaipw/Select';
import { Controller, useForm } from 'react-hook-form';
import { HeightInput } from 'components/cpaipw/HeightInput';
import { WeightInput } from 'components/cpaipw/WeightInput';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { ApplicationActionContext, ApplicationDataContext, IAboutYouApplication } from 'application/AboutYouQuestions/ApplicationContext';
import { PolicyDataContext } from 'quote/policy/PolicyContext';

const AboutYouApplication1: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const PolicyCtx = React.useContext(PolicyDataContext);

	const appDataContext = React.useContext(ApplicationDataContext);
	const appActionContext = React.useContext(ApplicationActionContext);
	const showChildren = PolicyCtx.hasChildDL || PolicyCtx.hasChildCI;
	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.aboutYou);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//TODO: Add validation and set context
	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			email: appDataContext.applicationData.email || '',
			cpaNumber: appDataContext.applicationData.cpaNumber || '',
			address: appDataContext.applicationData.address || '',
			city: appDataContext.applicationData.city || '',
			province: appDataContext.applicationData.city || provinces[0].value,
			postalCode: appDataContext.applicationData.postalCode || '',
			homePhone: appDataContext.applicationData.homePhone || '',
			birthCountry: appDataContext.applicationData.birthCountry || '',
			birthProvinceOrState: appDataContext.applicationData.birthProvinceOrState || '',
			height: appDataContext.applicationData.height || '',
			weight: appDataContext.applicationData.weight || '',
			weightOneYearAgo: appDataContext.applicationData.weightOneYearAgo || '',
			weightChangeReason: appDataContext.applicationData.weightChangeReason || '',
		}
	});

	const { control, handleSubmit, errors } = methods;

	const onValidSubmit = (vals: IAboutYouApplication) => {
		appActionContext.setApplicationData({
			...appDataContext.applicationData,
			...vals,
			firstName: FormCtx.initialUserData?.firstName || '',
			lastName: FormCtx.initialUserData?.lastName || '',
			email: FormCtx.initialUserData?.email || '',
			applicantId: FormCtx.applicantId,
			quoteId: PolicyCtx.quoteData.quote_id,
		});
		history.push(NavRoutes.ApplicationAboutYouStep2);
	};
	return (
		//TODO: Change out QuoteContainer component
		<QuoteContainer>
			<h1>About you</h1>
			<Steps currentstep={1} totalSteps={showChildren ? 3 : 2} />
			<form onSubmit={preventDefault}>
				<div className="form-group">
					<label htmlFor="email">Email Address</label>
					<Controller
						name="email"
						control={control}
						rules={{
							required: ValidationMessages.required,
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: ValidationMessages.email
							}
						}}
						render={({ onChange, value }) =>
							<input
								type="text"
								className="form-control"
								id="email"
								name="email"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.email?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label htmlFor="cpaNumber">CPA Number</label>
					<Controller
						name="cpaNumber"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value }) =>
							<input
								type="text"
								className="form-control"
								id="cpaNumber"
								name="cpaNumber"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.cpaNumber?.message}</ErrorMessage>
				</div>
				<Divider />
				<div className="form-group">
					<label htmlFor="address">Address</label>
					<Controller
						name="address"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value }) =>
							<input
								type="text"
								className="form-control"
								id="address"
								name="address"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.address?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label htmlFor="city">City</label>
					<Controller
						name="city"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value }) =>
							<input
								type="text"
								className="form-control"
								id="city"
								name="city"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.city?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label htmlFor="postalCode">Province</label>
					<Controller
						name="province"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value }) =>
							<Select
								name="province"
								options={provinces}
								onChange={onChange}
								selected={value}
							/>
						}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="postalCode">Postal Code</label>
					<Controller
						name="postalCode"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value }) =>
							<input
								type="text"
								className="form-control"
								id="postalCode"
								name="postalCode"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.postalCode?.message}</ErrorMessage>
				</div>
				<Divider />
				<div className="form-group">
					<label htmlFor="homePhone">Home (Area Code + No.):</label>
					<Controller
						name="homePhone"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value }) =>
							<input
								type="text"
								className="form-control"
								id="homePhone"
								name="homePhone"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.homePhone?.message}</ErrorMessage>
				</div>
				<Divider />
				<h6 className="mb-4">Place of Birth</h6>
				<div className="form-group">
					<label htmlFor="birthCountry">Country</label>
					<Controller
						name="birthCountry"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value }) =>
							<input
								type="text"
								className="form-control"
								id="birthCountry"
								name="birthCountry"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.birthCountry?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label htmlFor="birthProvinceOrState">Province/State</label>
					<Controller
						name="birthProvinceOrState"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value }) =>
							<input
								type="text"
								className="form-control"
								id="birthProvinceOrState"
								name="birthProvinceOrState"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.birthProvinceOrState?.message}</ErrorMessage>
				</div>
				<Divider />
				<div className="form-group">
					<label>Height</label>
					<Controller
						name="height"
						control={control}
						rules={{
							required: ValidationMessages.required,
							min: {
								value: 0,
								message: ValidationMessages.number
							},
							max: {
								value: 999,
								message: ValidationMessages.number
							},
							validate: value => isNaN(parseFloat(value)) ? ValidationMessages.number : undefined
						}}
						render={({ onChange, value }) =>
							<HeightInput
								name="height"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.height?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label>Weight</label>
					<Controller
						name="weight"
						control={control}
						rules={{
							required: ValidationMessages.required,
							min: {
								value: 0,
								message: ValidationMessages.number
							},
							max: {
								value: 999,
								message: ValidationMessages.number
							},
							validate: value => isNaN(parseFloat(value)) ? ValidationMessages.number : undefined
						}}
						render={({ onChange, value }) =>
							<WeightInput
								name="weight"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.weight?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label>Weight One Year Ago</label>
					<Controller
						name="weightOneYearAgo"
						control={control}
						rules={{
							required: ValidationMessages.required,
							min: {
								value: 0,
								message: ValidationMessages.number
							},
							max: {
								value: 999,
								message: ValidationMessages.number
							},
							validate: value => isNaN(parseFloat(value)) ? ValidationMessages.number : undefined
						}}
						render={({ onChange, value }) =>
							<WeightInput
								name="weightOneYearAgo"
								value={value}
								onChange={onChange}
							/>
						}
					/>
					<ErrorMessage>{errors?.weightOneYearAgo?.message}</ErrorMessage>
				</div>
				<div className="form-group">
					<label htmlFor="weightChangeReason">Reason for change in weight (if applicable):</label>
					<Controller
						name="weightChangeReason"
						control={control}
						render={({ onChange, value }) =>
							<input
								type="text"
								className="form-control"
								id="weightChangeReason"
								name="weightChangeReason"
								value={value}
								onChange={onChange}
							/>
						}
					/>
				</div>
				<FormButtons
					backLabel="Back to Quote"
					onClickBack={() => history.push(NavRoutes.QuoteReviewPolicy)}
					onClickNext={handleSubmit(onValidSubmit)}
				/>
			</form>
		</QuoteContainer>
	);
};

export default AboutYouApplication1;
