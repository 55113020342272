import { ValidationMessages } from 'common/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ILifestyleFormProps } from './lifestyleProps';

export const DrugsOrNarcoticsForm: React.FC<ILifestyleFormProps> = ( props: ILifestyleFormProps ) => {
	const user = props.user || '';
	const methods = useFormContext();

	const { control, errors } = methods;
	const lifestyleData = props.data?.find(d => d.idOfApplicantOrChildren === user);
	return (
		<div className="m-3">
			<div className="form-group">
				<label htmlFor={`drugsOrNarcotics.${user}.amount`}>Number of grams per week</label>
				<Controller
					name={`drugsOrNarcotics.${user}.amount`}
					control={control}
					rules={{
						required: ValidationMessages.required,
					}}
					defaultValue={lifestyleData !== undefined ? lifestyleData.consumptionAmount : ''}
					render={({ onChange, value }) =>
						<input
							name={`drugsOrNarcotics.${user}.amount`}
							onChange={onChange}
							value={value}
							className="form-control"
						/>
					}
				/>
				<ErrorMessage>{errors?.drugsOrNarcotics?.[user]?.numberGramsPerWeek?.message}</ErrorMessage>
			</div>
		</div>

	);
};