import * as React from 'react';
import { Page } from 'components/common';

class FourOhFourPage extends React.Component<{}, {}> {

	public render() {
		return (
			<Page title="404">
				<section className="features-1">
					<h1 className="display-1">404</h1>
					<h2>Page not found</h2>
					<p className="mt-4">Sorry, I'm not sure how you got here but this place doesn't exist.</p>
				</section>
			</Page>
		);
	}
}

export default FourOhFourPage;
