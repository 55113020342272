import React from 'react';
import classNames from 'classnames';
import {ReactComponent as Done} from 'assets/icons/icon-done.svg';

interface IProps {
	name?: string;
	label?: string;
	value?: string;
	isActive: boolean;
	isToggle?: boolean;
	onClick: () => void;
	className?: string;
	disabled?: boolean;
	hideBorder?: boolean;
}

export const CheckboxButton: React.FC<IProps> = (props: IProps) => {
	const btnToggleClassName = 'form-btn-toggle align-items-center';
	const btnCheckboxClassName = 'form-btn-checkbox d-flex align-items-start';

	return (
		<React.Fragment>
			<label className={classNames(
				props.hideBorder ? 'font-weight-light cursor-pointer' : 'form-btn-input',
				btnCheckboxClassName,
				props.isToggle && btnToggleClassName,
				props.isActive && 'active',
				props.className,
				props.disabled && 'disabled',
			)}>
				<input
					type="checkbox"
					name={props.name}
					value={props.value}
					onClick={() => props.onClick()}
					checked={props.isActive}
					onChange={() => false}
					disabled={props.disabled}
				/>
				{props.isActive && <Done className="text-primary" /> }
				<span>{props.label}</span>
			</label>
		</React.Fragment>
	);

};