import * as React from 'react';
import { RadioButton } from './RadioButton';
import classNames from 'classnames';

export interface RadioOption {
	label: string;
	value: string;
}

interface IProps {
	name: string;
	options: RadioOption[];
	onChange: (val: any) => void;
	className?: string;
	value?: string;
	disabled?: boolean;
	list?: boolean; // force show as list even if there are only two options
	multipleBtns?: boolean;
}

export const RadioButtonGroup: React.FC<IProps> = (props: IProps) => {
	const optionCount = props.options.length;
	return (
		<React.Fragment>
			<div
				className={classNames(((optionCount === 2 && props.list !== true) || props.multipleBtns) && 'btn-group d-flex', props.className)}
				data-toggle="buttons"
			>
				{props.options.map((option: RadioOption, key) => {
					return (
						<RadioButton
							key={key}
							isToggle={(optionCount === 2 && props.list !== true) || props.multipleBtns}
							name={props.name}
							label={option.label}
							value={option.value}
							isActive={props.value === option.value}
							onClick={() => props.onChange(option.value)}
							disabled={props.disabled}
						/>
					);
				})}
			</div>
		</React.Fragment>
	);
};