import React from 'react';
import { Button } from 'components/buttons';
import classNames from 'classnames';
import { Divider } from './Divider';

interface IProps {
	onClickBack?: () => void;
	onClickNext?: (() => void) | ((data: any) => Promise<void>);
	className?: string;
	backLabel?: string;
	nextLabel?: string;
	type?: 'button' | 'reset' | 'submit';
}

export const FormButtons: React.FC<IProps> = ( props: IProps ) => {

	return (
		<React.Fragment>
			<Divider />
			<div className={classNames('d-flex', props.className)}>
				{props.onClickBack && (
					<Button
						className={classNames('btn-outline-primary', props.onClickNext ? 'mr-2 flex-fill' : 'w-50 flex-grow-0')}
						onClick={props.onClickBack}
						type={props.type}
					>
						{props.backLabel ? props.backLabel : 'Back'}
					</Button>
				)}
				{props.onClickNext && (
					<Button
						className={classNames('btn-primary', props.onClickBack ? 'ml-2 flex-fill' : 'w-50 flex-grow-0')}
						onClick={props.onClickNext}
						type={props.type}
					>
						{props.nextLabel ? props.nextLabel : 'Next'}
					</Button>
				)}
			</div>
		</React.Fragment>
	);
};
