import { ValidationMessages } from 'common/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ILifestyleFormProps } from './lifestyleProps';

export const AlcoholicBevaragesForm: React.FC<ILifestyleFormProps> = ( props: ILifestyleFormProps ) => {
	const user = props.user || '';
	const methods = useFormContext();

	const { control, errors } = methods;
	const lifestyleData = props.data?.find(d => d.idOfApplicantOrChildren === user);

	return (
		<div className="m-3">
			<div className="form-group">
				<label htmlFor={`alcholicBeverage.${user}.amount`} className="mb-2">Number of servings</label>
				<p className="text-italics mb-1">Number of servings per week</p>
				<p className="small mb-1">1 serving =</p>
				<p className="small mb-1">1 bottle of beer (8 ounces)</p>
				<p className="small mb-1">1 glass of wine (4 ounces)</p>
				<p className="small mb-2">2 ounces of spirits</p>
				<Controller
					name={`alcholicBeverage.${user}.amount`}
					control={control}
					rules={{
						required: ValidationMessages.required,
					}}
					defaultValue={lifestyleData !== undefined ? lifestyleData.consumptionAmount : ''}
					render={({ onChange, value }) =>
						<input
							name={`alcholicBeverage.${user}.amount`}
							onChange={onChange}
							value={value}
							className="form-control"
						/>
					}
				/>
				<ErrorMessage>{errors?.alcholicBeverage?.[user]?.amount?.message}</ErrorMessage>
			</div>
		</div>

	);
};