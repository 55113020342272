import classNames from 'classnames';
import React from 'react';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
}

export function InfoBox( props: React.PropsWithChildren<IProps> ) {
	const { title, className, children, ...otherProps } = props;
	return (
		<div className={classNames('p-3 bg-gray-100 rounded mb-3', className)} {...otherProps}>
			{title !== undefined && (
				<h5 className={classNames('text-primary', children === undefined ? 'mb-0' : 'mb-2')}>{title}</h5>
			)}
			{children !== undefined && <p className="mb-0">{children}</p>}
		</div>
	);
}