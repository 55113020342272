import React from 'react';
import { RadioButtonGroup } from 'components/cpaipw';
import { RadioOption } from 'components/cpaipw/RadioButtonGroup';
import { IPolicyComponentProps } from './utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';

interface IProps extends IPolicyComponentProps {
	options: RadioOption[]
}

export const ApplyToChildrenToggle: React.FC<IProps> = ( props: IProps ) => {

	return (
		<div className="form-group">
			<label className="font-weight-normal">Do you have children you wish to get critical illness coverage for?</label>
			<span className="d-block">- Flat $10,000 benefit available.</span>
			<span className="d-block mb-2">- Will apply to all children.</span>
			<RadioButtonGroup
				name="ApplyToChildrenToggle"
				onChange={props.onChange}
				options={props.options}
				value={props.value}
			/>
			<ErrorMessage>{props.error}</ErrorMessage>
		</div>
	);
};