import * as React from 'react';
import { Link } from 'react-router-dom';

const PageFooter: React.FC = (): JSX.Element => {
	return (
		<footer className="page-footer">
			<div className="bg-primary-dark">
				<div className="container">
					<div className="d-flex flex-column flex-md-row py-3 justify-content-md-between">
						<span className="small mb-2 mb-md-0 text-white">&copy; Copyright 2020 CPA Insurance Plans West, All Rights Reserved.</span>
						<Link to="#" className="small text-white text-decoration-underline">Privacy Policy</Link>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default PageFooter;
