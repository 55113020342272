import React, { useState } from 'react';
import { Button } from 'components/buttons';
import { Controller, useFormContext } from 'react-hook-form';
import { HeightInput } from './HeightInput';
import { WeightInput } from './WeightInput';
import { randomString, validateDate, ValidationMessages } from 'common/utils';
import { Divider, RadioButtonGroup } from 'components/cpaipw';
import { ErrorMessage } from './ErrorMessage';
import { ApplicationDataContext, IChild } from 'application/AboutYouQuestions/ApplicationContext';
interface IProps {

}

export const AddRemoveChildContainer: React.FC<IProps> = (props: IProps) => {
	const [childIds, setChildIds] = useState<string[]>([randomString(8)]);

	//TODO: Add validation and set context
	const methods = useFormContext();

	const appDataContext = React.useContext(ApplicationDataContext);
	const { children } = appDataContext.applicationData;

	React.useEffect(() => {
		if (children !== undefined && children.length > 0) {
			const newChildIds = children.map((child: IChild) => {
				return child.id || randomString(8);
			});
			setChildIds(newChildIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { control, errors } = methods;

	const addChild = () => {
		const newIdArray = [...childIds];
		newIdArray.push(randomString(8));
		setChildIds(newIdArray);
	};

	const currentChildren = appDataContext.applicationData.children;

	const removeChild = (id: string) => {
		const newArray = childIds.filter(c => c !== id);
		setChildIds(newArray);
	};

	return (
		<React.Fragment>
			{childIds.map((id: string, index: number) => {
				const defaultValuesId = appDataContext.applicationData.children?.findIndex((v: IChild) => v.id === id);
				return (
					<div key={`child-${id}`}>
						{index !== 0 && <Divider />}
						<React.Fragment>
							<div className="form-group">
								<label htmlFor={'firstName-' + id}>First Name</label>
								<Controller
									name={`children[${id}].firstName`}
									control={control}
									defaultValue={(defaultValuesId !== undefined && currentChildren !== undefined) ? currentChildren?.[defaultValuesId]?.firstName : ''}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id={'firstName-' + id}
											name={'firstName-' + id}
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.children?.[id]?.firstName?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor={`children[${id}].middleInitial`}>Middle Initial</label>
								<Controller
									name={`children[${id}].middleInitial`}
									control={control}
									defaultValue={(defaultValuesId !== undefined && currentChildren !== undefined) ? currentChildren?.[defaultValuesId]?.middleInitial : ''}
									render={({ onChange, value }) =>
										<input
											id={'middleInitial-' + id}
											name={'middleInitial-' + id}
											onChange={onChange}
											value={value}
											className="form-control"
										/>
									}
								/>
							</div>
							<div className="form-group">
								<label htmlFor={'lastName-' + id}>Last Name</label>
								<Controller
									name={`children[${id}].lastName`}
									control={control}
									defaultValue={(defaultValuesId !== undefined && currentChildren !== undefined) ? currentChildren?.[defaultValuesId]?.lastName : ''}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id={'lastName-' + id}
											name={'lastName-' + id}
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.children?.[id]?.lastName?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor={`children[${id}].gender`}>What is their gender?</label>
								<Controller
									name={`children[${id}].gender`}
									control={control}
									defaultValue={(defaultValuesId !== undefined && currentChildren !== undefined) ? currentChildren?.[defaultValuesId]?.gender : ''}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<RadioButtonGroup
											name={'gender-' + id}
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Male',
													value: 'male',
												},
												{
													label: 'Female',
													value: 'female',
												},
											]}
										/>
									}
								/>
								<ErrorMessage>{errors.children?.[id]?.gender?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor={`children[${id}].dateOfBirth`}>When were they born?</label>
								<Controller
									name={`children[${id}].dateOfBirth`}
									control={control}
									defaultValue={(defaultValuesId !== undefined && currentChildren !== undefined) ? currentChildren?.[defaultValuesId]?.dateOfBirth : ''}
									rules={{
										required: ValidationMessages.required,
										validate: date => validateDate(date) ? ValidationMessages.date : true,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											value={value}
											onChange={onChange}
											name={'dateOfBirth-' + id}
											id={'dateOfBirth-' + id}
											placeholder="yyyy/mm/dd"
										/>
									}
								/>
								<ErrorMessage>{errors.children?.[id]?.dateOfBirth?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label>Height</label>
								<Controller
									name={`children[${id}].height`}
									control={control}
									defaultValue={(defaultValuesId !== undefined && currentChildren !== undefined) ? currentChildren?.[defaultValuesId]?.height : ''}
									rules={{
										required: ValidationMessages.required,
										min: {
											value: 0,
											message: ValidationMessages.number,
										},
										max: {
											value: 999,
											message: ValidationMessages.number,
										},
										validate: value => isNaN(parseFloat(value)) ? ValidationMessages.number : undefined
									}}
									render={({ onChange, value }) =>
										<HeightInput
											name={'height-' + id}
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.children?.[id]?.height?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label>Weight</label>
								<Controller
									name={`children[${id}].weight`}
									defaultValue={(defaultValuesId !== undefined && currentChildren !== undefined) ? currentChildren?.[defaultValuesId]?.weight : ''}
									control={control}
									rules={{
										required: ValidationMessages.required,
										min: {
											value: 0,
											message: ValidationMessages.number,
										},
										max: {
											value: 999,
											message: ValidationMessages.number,
										},
										validate: value => isNaN(parseFloat(value)) ? ValidationMessages.number : undefined
									}}
									render={({ onChange, value }) =>
										<WeightInput
											name={'weight-' + id}
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.children?.[id]?.weight?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label>Weight One Year Ago</label>
								<Controller
									name={`children[${id}].weightOneYearAgo`}
									control={control}
									defaultValue={(defaultValuesId !== undefined && currentChildren !== undefined) ? currentChildren?.[defaultValuesId]?.weightOneYearAgo : ''}
									rules={{
										required: ValidationMessages.required,
										min: {
											value: 0,
											message: ValidationMessages.number,
										},
										max: {
											value: 999,
											message: ValidationMessages.number,
										},
										validate: value => isNaN(parseFloat(value)) ? ValidationMessages.number : undefined
									}}
									render={({ onChange, value }) =>
										<WeightInput
											name={'weightOneYearAgo-' + id}
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.children?.[id]?.weightOneYearAgo?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor={'weightChangeReason-' + id}>Reason for change in weight (if applicable): </label>
								<Controller
									name={`children[${id}].weightChangeReason`}
									control={control}
									defaultValue={(defaultValuesId !== undefined && currentChildren !== undefined) ? currentChildren?.[defaultValuesId]?.weightChangeReason : ''}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id={'weightChangeReason-' + id}
											name={'weightChangeReason-' + id}
											value={value}
											onChange={onChange}
										/>
									}
								/>
							</div>
						</React.Fragment>
						<Button
							key={`remove-button-${id}`}
							className="btn-outline-primary mb-4"
							onClick={() => removeChild(id)}
							disabled={index === 0 && childIds.length === 1}
						>
							Remove Child -
						</Button>
					</div>
				);
			})}
			<Button
				className="btn-primary mb-4"
				onClick={addChild}
			>
				Add Child +
			</Button>
		</React.Fragment>
	);
};