import React from 'react';
import { RadioButtonGroup } from './RadioButtonGroup';

export interface IUnit {
	label: string,
	value: Units
}

interface IProps {
	name: string;
	units: IUnit[];
	selectedUnit: string;
	value: string;
	id?: string;
	multipleBtns?: boolean;
	onChange: (value: string) => void;
	onUnitChange: (unit: Units) => void;
	onBlur?: () => void;
}

export enum Units {
	ftin = 'ft in',
	cm = 'cm',
	kg = 'kg',
	lbs = 'lbs',
}

export const UnitsTypeInput: React.FC<IProps> = (props: IProps) => {
	return (
		<React.Fragment>
			{props.selectedUnit !== Units.ftin && (
				<div className="input-group mb-1">
					<input
						type="text"
						className="form-control"
						value={props.value}
						name={props.name}
						id={props.id}
						onChange={(e) => props.onChange(e.target.value)}
						onBlur={(e) => {
							if (props.onBlur) {props.onBlur();};
						}}
					/>
					<div className="input-group-append">
						<span className="input-group-text">{props.selectedUnit}</span>
					</div>
				</div>
			)}
			<RadioButtonGroup
				name="units"
				options={props.units}
				onChange={props.onUnitChange}
				value={props.selectedUnit}
				multipleBtns={props.multipleBtns}
			/>
		</React.Fragment>
	);
};