import React from 'react';
import { RadioButtonGroup } from 'components/cpaipw/RadioButtonGroup';
import { FormContext, IExistingInsurance, INeedsAssessment, ISavings } from 'quote/FormContext';
import { Controller, useForm } from 'react-hook-form';
import { AmountField, FormButtons, LoadContainer, NeedsAssessmentSidebarCard, QuoteContainer, Steps, AmountInput } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { NavRoutes, QuotePages, ValidationMessages } from 'common/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { ReactComponent as IconSavings } from 'assets/icons/icon-savings.svg';

interface IProps {
	location: any;
}

const NeedsAssessmentStep7: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);
	const history = useHistory();

	const { control, handleSubmit, errors, watch } = useForm({
		defaultValues: FormCtx.needsAssessment,
	});
	const onSubmit = async (values: INeedsAssessment) => {
		if (values.valueOfSavings === undefined) {
			values.valueOfSavings = {};
		}

		savingsFields.forEach((item) => {
			if (values.valueOfSavings !== undefined && (values.valueOfSavings[item.name] === undefined || values.valueOfSavings[item.name] === '')) {
				values.valueOfSavings[item.name] = '0';
			}
		});
		if (values.existingInsurance === undefined) {
			values.existingInsurance = {};
		}

		existingInsuranceFields.forEach((item) => {
			if (values.existingInsurance !== undefined && (values.existingInsurance[item.name] === undefined || values.existingInsurance[item.name] === '')) {
				values.existingInsurance[item.name] = '0';
			}
		});
		FormCtx.setIsLoading(true);
		if (!FormCtx.needsAssessmentOnly) {
			await FormCtx.upsertFormValues(values);
		}

		values.step = 5;

		FormCtx.setNeedsAssessment({ ...FormCtx.needsAssessment, ...values });

		FormCtx.setIsLoading(false);
		history.push(NavRoutes.QuoteNeedsAssessmentStep8);
	};

	const watchHasSavings = watch('hasSavings');
	const watchHasInsurance = watch('hasInsurance');

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<QuoteContainer sidebar={<NeedsAssessmentSidebarCard />}>
			<form>
				<IconSavings className="mb-3" />
				<h3>Savings &amp; Assets</h3>
				<Steps currentstep={3} totalSteps={3} />

				<LoadContainer isLoading={FormCtx.isLoading}>
					<p>Now it’s time to calculate your savings!</p>
					<div className="form-group">
						<label>Do you have any savings or investments?</label>
						<Controller
							name="hasSavings"
							control={control}
							rules={{
								required: ValidationMessages.required,
							}}
							render={({ onChange, value, name }) =>
								<RadioButtonGroup
									onChange={onChange}
									value={value}
									name={name}
									options={[
										{
											label: 'Yes',
											value: 'yes',
										},
										{
											label: 'No',
											value: 'no',
										},
									]}
								/>
							}
						/>
						<ErrorMessage>{errors?.hasSavings?.message}</ErrorMessage>
					</div>
					{watchHasSavings === 'yes' && (
						<React.Fragment>
							<div className="form-group">
								<label className="mb-0">What is the total value of your savings?</label>
								<p className="font-italic">(Specify amounts in CAD)</p>
								{savingsFields.map((item, index: number) => {
									return (
										<React.Fragment key={`${item.name}-${index}`}>
											<AmountField
												item={item}
												index={index}
												prepend="$"
											>
												<Controller
													name={`valueOfSavings.${item.name}`}
													control={control}
													rules={{
														validate: val => ((val !== undefined && val !== null && val !== '') && isNaN(val)) ? ValidationMessages.number : true,
													}}
													render={({ onChange, value, name }) =>
														<AmountInput
															onChange={onChange}
															value={value}
															name={name}
														/>
													}
												/>
											</AmountField>
											<ErrorMessage>{errors?.valueOfSavings?.[item.name]?.message}</ErrorMessage>
										</React.Fragment>
									);
								})}
							</div>
						</React.Fragment>
					)}
					<div className="form-group">
						<label>Do you currently have a Life Insurance policy?</label>
						<Controller
							name="hasInsurance"
							control={control}
							rules={{
								required: ValidationMessages.required,
							}}
							render={({ onChange, value, name }) =>
								<RadioButtonGroup
									onChange={onChange}
									value={value}
									name={name}
									options={[
										{
											label: 'Yes',
											value: 'yes',
										},
										{
											label: 'No',
											value: 'no',
										},
									]}
								/>
							}
						/>
						<ErrorMessage>{errors?.hasInsurance?.message}</ErrorMessage>
					</div>
					{watchHasInsurance === 'yes' && existingInsuranceFields.map((item, index: number) => {
						return (
							<React.Fragment key={`${item.name}-${index}`}>
								<AmountField
									item={item}
									index={index}
									prepend="$"
								>
									<Controller
										name={`existingInsurance.${item.name}`}
										control={control}
										rules={{
											validate: val => ((val !== undefined && val !== null && val !== '') && isNaN(val)) ? ValidationMessages.number : true,
										}}
										render={({ onChange, value, name }) =>
											<AmountInput
												onChange={onChange}
												value={value}
												name={name}
											/>
										}
									/>
								</AmountField>
								<ErrorMessage>{errors?.existingInsurance?.[item.name]?.message}</ErrorMessage>
							</React.Fragment>
						);
					})}
					<FormButtons
						onClickBack={() => history.push(NavRoutes.QuoteNeedsAssessmentStep6)}
						onClickNext={handleSubmit(onSubmit)}
					/>
				</LoadContainer>
			</form>
			<div className="d-block d-lg-none">
				<NeedsAssessmentSidebarCard />
			</div>
		</QuoteContainer>
	);
};

const savingsFields = [
	{
		label: 'RRSP',
		name: 'rrsp',
	},
	{
		label: 'TFSA',
		name: 'tfsa',
	},
	{
		label: 'Chequing Accounts',
		name: 'chequingAccounts',
	},
	{
		label: 'Savings Accounts',
		name: 'savingsAccount',
	},
	{
		label: 'Other Investments',
		name: 'other',
		description: 'sum of all other additional investments not listed',
	}
] as {
	label: string;
	name: keyof ISavings;
	description?: string;
}[];

const existingInsuranceFields = [
	{
		label: 'Life Insurance through your employer',
		name: 'employer',
	},
	{
		label: 'Mortgage Insurance through a bank',
		name: 'bank',
	},
	{
		label: 'Existing Individual Life Insurance Policy',
		name: 'life',
	}
] as {
	label: string;
	name: keyof IExistingInsurance;
	description?: string;
}[];

export default NeedsAssessmentStep7;
