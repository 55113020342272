import React from 'react';
import { ApplicationPages, NavRoutes, preventDefault } from 'common/utils';
import { CheckboxButton, CheckboxGroup, Divider, FormButtons, QuestionContainer, QuoteContainer, Steps } from 'components/cpaipw';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { FormContext } from 'quote/FormContext';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ApplicationDataContext, AccordionUser } from 'application/AboutYouQuestions/ApplicationContext';
import { CheckboxOption } from 'components/cpaipw/CheckboxGroup';
import { lifestyleValidation } from 'application/utils';
import { LifestyleDataContext, LifestyleActionContext } from './LifestyleContext';


const HealthApplicationStep4: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const appDataContext = React.useContext(ApplicationDataContext);

	const lifestyleDataContext = React.useContext(LifestyleDataContext);
	const lifestyleActionContext = React.useContext(LifestyleActionContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.lifestyle);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});

	const { control, handleSubmit, errors, watch, setError } = methods;

	const watchQ1 = watch(['usedTobacco']);

	const watchNa = watch(['notApplicable']);

	const onValidSubmit = (val: any) => {
		const keys = Object.keys(val);
		if (keys.includes('notApplicable') && keys.length === 1 && val['notApplicable'] === false) {
			setError('notApplicable', { message: 'If none of the situations are applicable, please select "Not Applicable"' });
			return;
		}

		lifestyleActionContext.extractLifestyleData(val, 'usedTobacco', appDataContext.applicationData.children!, 'section4');
		history.push(NavRoutes.ApplicationLifestyleStep5);
	};

	const users = appDataContext.getAccordionUsers();
	const checkboxOptions = users.map((user: AccordionUser) => {
		return {
			label: user.displayName,
			value: user.userId,
		} as CheckboxOption;
	});

	const lifestyleUserData = lifestyleDataContext.lifestyleQuestions.section4 && lifestyleDataContext.lifestyleQuestions.section4.length > 0 ?
		lifestyleDataContext.lifestyleQuestions.section4.map(data => data.idOfApplicantOrChildren) : [];


	return (
		<QuoteContainer>
			<h1>Lifestyle Questions</h1>
			<Steps currentstep={4} totalSteps={6} />
			<form onSubmit={preventDefault}>
				<div className="d-flex flex-column mb-2">
					<QuestionContainer index={1}>
						<p>
							In the <b>last 12 months</b>, has the proposed insured used any form of tobacco, including e-cigarettes or other tobacco substitutes?
						</p>
						<div className="form-group">
							<Controller
								name="usedTobacco"
								control={control}
								defaultValue={lifestyleUserData}
								rules={{
									validate: watchNa.notApplicable === false ? ((val: string[]) => lifestyleValidation(val, watchNa.notApplicable)) : undefined
								}}
								render={({ onChange, value }) =>
									<CheckboxGroup
										name="usedTobacco"
										onChange={onChange}
										value={value}
										disabled={watchNa.notApplicable === true && lifestyleUserData.length === 0}
										options={checkboxOptions}
									/>
								}
							/>
							<ErrorMessage>{errors?.usedTobacco?.message}</ErrorMessage>
						</div>
						<Divider />
					</QuestionContainer>
					<QuestionContainer index={2}>
						<Controller
							name="notApplicable"
							control={control}
							defaultValue={lifestyleDataContext.lifestyleQuestions.section4 !== undefined && lifestyleDataContext.lifestyleQuestions.section4.length === 0}
							render={({ onChange, value }) =>
								<CheckboxButton
									name="notApplicable"
									isActive={value}
									label="Not Applicable"
									onClick={() => onChange(!value)}
									disabled={Array.isArray(watchQ1.usedTobacco) && watchQ1.usedTobacco.length > 0 && lifestyleUserData.length === 0}
								/>
							}
						/>
					</QuestionContainer>
				</div>
				<FormButtons
					onClickBack={() => history.push(NavRoutes.ApplicationLifestyleStep3)}
					onClickNext={handleSubmit(onValidSubmit)}
				/>
			</form>
		</QuoteContainer>
	);
};

export default HealthApplicationStep4;