import React from 'react';
import { ApplicationPages, NavRoutes, preventDefault } from 'common/utils';
import { FormButtons, InfoMessage, LoadContainer, QuoteContainer, Steps } from 'components/cpaipw';
import { BeneficiaryAccordion } from 'components/cpaipw/BeneficiaryAccordion';
import { FormContext } from 'quote/FormContext';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Corporations, Estate, Individuals } from './beneficiariesComponents';
import { BeneficiaryActionContext } from './BeneficiaryContext';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { PolicyDataContext } from 'quote/policy/PolicyContext';
import { SubmissionContext } from 'application/SubmissionContext';


const BeneficiaryApplicationStep1: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const policyCtx = React.useContext(PolicyDataContext);
	const showChildren = policyCtx.hasChildDL || policyCtx.hasChildCI;
	const beneficiaryActionContext = React.useContext(BeneficiaryActionContext);
	const submissionCtx = React.useContext(SubmissionContext);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.beneficiaries);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});

	const { handleSubmit, setError, errors } = methods;

	const onValidSubmit = (val: any) => {
		if (Object.keys(val).length === 0) {
			setError('hasBeneficiary', { message: 'At least one beneficiary is required.' });
			return;
		}

		beneficiaryActionContext.extractBeneficiaryNames(val);
		history.push(NavRoutes.ApplicationBeneficiariesStep2);
	};

	const navigateBack = () => {
		if (showChildren) {
			history.push(NavRoutes.ApplicationAboutYouStep3);
		} else {
			history.push(NavRoutes.ApplicationAboutYouStep2);
		}
	};

	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<LoadContainer isLoading={submissionCtx.submissionLoading}>
					<h1>Beneficiaries</h1>
					<Steps currentstep={1} totalSteps={3} />
					<form onSubmit={preventDefault}>
						<p className="font-weight-bold">
							Select the type of beneficiaries you want to add:
						</p>
						<InfoMessage>
							Designating a beneficiary ensures the coverage amount can be claimed by and paid directly how you want upon your death.
						</InfoMessage>
						<BeneficiaryAccordion>
							<Individuals displayName="Individual(s)" disabled={false} />
							<Corporations displayName="Corporation(s)" disabled={false} />
							<Estate displayName="Estate" disabled={false} />
						</BeneficiaryAccordion>
						<ErrorMessage>{errors?.hasBeneficiary?.message}</ErrorMessage>
						<FormButtons
							onClickBack={navigateBack}
							onClickNext={handleSubmit(onValidSubmit)}
						/>
					</form>
				</LoadContainer>
			</QuoteContainer>
		</FormProvider>
	);
};

export default BeneficiaryApplicationStep1;