import React from 'react';
import { RadioButtonGroup } from 'components/cpaipw';
import { RadioOption } from 'components/cpaipw/RadioButtonGroup';
import { IPolicyComponentProps } from './utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';

interface IProps extends IPolicyComponentProps {
	options: RadioOption[]
}

export const CoverageAmountRadio: React.FC<IProps> = ( props: IProps ) => {
	return (
		<div className="form-group">
			<label className="font-weight-normal">What is the amount of insurance you want?</label>
			<RadioButtonGroup
				name="CoverageAmountRadio"
				onChange={props.onChange}
				value={props.value}
				options={props.options}
			/>
			<ErrorMessage>{props.error}</ErrorMessage>
		</div>
	);
};
