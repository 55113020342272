import React from 'react';
import { ApplicationPages, NavRoutes, preventDefault } from 'common/utils';
import { FormButtons, InfoMessage, QuoteContainer, Steps } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { FormContext } from 'quote/FormContext';
import { AddRemoveChildContainer } from 'components/cpaipw/AddRemoveChildContainer';
import { FormProvider, useForm } from 'react-hook-form';
import { ApplicationActionContext } from 'application/AboutYouQuestions/ApplicationContext';
import { SubmissionContext } from 'application/SubmissionContext';

const AboutYouApplication3: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const submissionCtx = React.useContext(SubmissionContext);

	const appActionContext = React.useContext(ApplicationActionContext);

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	const { handleSubmit } = methods;


	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.aboutYou);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onValidSubmit = async (val: any) => {
		appActionContext.extractChildData(val.children);
		submissionCtx.setSubmitApplication(true);
		history.push(NavRoutes.ApplicationBeneficiariesStep1);
	};


	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<h1>About you</h1>
				<Steps currentstep={3} totalSteps={3} />
				<form onSubmit={preventDefault} >
					<h5>Child Information</h5>
					<InfoMessage className="mb-4">
						<p className="mb-1">List the <b>children</b> and/or <b>students</b> that are eligible to be <b>proposed</b> in your insurance application.</p>
						<p className="mb-1">In this application:</p>
						<ul className="bullet-dash pl-3 mb-0">
							<li className="mb-1">A <b>child</b> refers to those <b>under 23</b>.</li>
							<li className="mb-1">A <b>student</b> refers to those <b>under 25</b> and is a <b>full-time post-secondary</b> student.</li>
						</ul>
						<p className="mb-0">This information is required for Dependent Life and/or Child Critical Illness.</p>
					</InfoMessage>
					<AddRemoveChildContainer />
					<FormButtons
						onClickBack={() => history.push(NavRoutes.ApplicationAboutYouStep2)}
						onClickNext={handleSubmit(onValidSubmit)}
					/>
				</form>
			</QuoteContainer>
		</FormProvider>
	);
};

export default AboutYouApplication3;