import React from 'react';
import { IBeneficiaryData, ICorporation, IEstate, IIndividual, ITrustee } from './BeneficiaryTypes';

interface IBeneficiaryDataContext {
	beneficiaryData: IBeneficiaryData;
	isUnder18: string;

}

interface IBeneficiaryDataActionContext {
	setBeneficiaryData: React.Dispatch<React.SetStateAction<IBeneficiaryData>>;
	extractBeneficiaryNames: (value: any)  => void,
	extractBeneficiaryAllocations: (value: any, isEqually: boolean) => void,
	extractTrustee: (value: any) => void,
	getIndividuals: () => IAssignedBeneficiary[] | undefined,
	getCorporations: () => IAssignedBeneficiary[] | undefined,
	getEstate: () => IAssignedBeneficiary | undefined,
	setIsUnder18: React.Dispatch<React.SetStateAction<string>>;
}

const BeneficiaryDataContext = React.createContext<IBeneficiaryDataContext>({
	beneficiaryData: {} as IBeneficiaryData,
	isUnder18: ''
});

const defaultData: IBeneficiaryData = {
	division: undefined,
	individuals: undefined,
	corporations: undefined,
	estate: undefined,
	trustee: undefined
};

const BeneficiaryActionContext = React.createContext<IBeneficiaryDataActionContext>({
	setBeneficiaryData: () => defaultData,
	extractBeneficiaryNames: () => undefined,
	extractBeneficiaryAllocations: () => undefined,
	extractTrustee: () => undefined,
	getIndividuals: () => undefined,
	getCorporations: () => undefined,
	getEstate: () => undefined,
	setIsUnder18: () => undefined
});

export type IAssignedBeneficiary = {
	id: string;
	displayName: string;
}

const BeneficiaryProvider = (props: any) => {
	const [beneficiaryData, setBeneficiaryData] = React.useState<IBeneficiaryData>({} as IBeneficiaryData);
	const [isUnder18, setIsUnder18] = React.useState<string>('');

	const getIndividuals = () => {
		var beneficiaries = undefined;

		if (beneficiaryData.individuals !== undefined && beneficiaryData.individuals.length > 0) {
			beneficiaries = [] as IAssignedBeneficiary[];
			const individuals = beneficiaryData.individuals.map<IAssignedBeneficiary>((user) => {
				return {
					id: user.id,
					displayName: `${user.firstName} ${user.lastName}`
				} as IAssignedBeneficiary;
			});

			if (individuals && individuals.length > 0) {
				beneficiaries.push(...individuals);
			}
		}

		return beneficiaries;
	};


	const getCorporations = () => {
		var beneficiaries = undefined;

		if (beneficiaryData.corporations !== undefined && beneficiaryData.corporations.length > 0) {
			beneficiaries = [] as IAssignedBeneficiary[];
			const corporations = beneficiaryData.corporations.map((user) => {
				return {
					id: user.id,
					displayName: user.name
				};
			});

			if (corporations && corporations.length > 0) {
				beneficiaries.push(...corporations);
			}
		}

		return beneficiaries;

	};

	const getEstate = () => {
		var estate = undefined;

		if(beneficiaryData.estate !== undefined) {
			estate = {
				id: 'estate',
				displayName: beneficiaryData.estate.name
			} as IAssignedBeneficiary;
		}

		return estate;
	};

	const  extractBeneficiaryNames = (val: any) => {
		const filterKeys = Object.keys(val);
		var bData = beneficiaryData;

		filterKeys.forEach(type => {
			const beneficiaryKey = Object.keys(val[type]);

			if(type === 'estate') {
				if (bData.estate === undefined) {
					bData.estate = {
						name: val[type][beneficiaryKey[0]]
					} as IEstate;
				}
			} else if(type === 'corporations') {
				var corporations = [] as ICorporation[];
				beneficiaryKey.forEach(id => {
					const found = bData.corporations !== undefined ? bData.corporations?.find((i) => i.id === id) : undefined;
					if (found) {
						found.name = val[type][id]['nameOfCorporation'];
						corporations.push(found);
					} else{
						corporations.push(
							{
								id: id,
								name: val[type][id]['nameOfCorporation']
							} as ICorporation
						);
					}
				});
				bData.corporations = corporations;
			} else {
				var individuals = [] as IIndividual[];
				beneficiaryKey.forEach(id => {
					const found = bData.individuals !== undefined ? bData.individuals?.find((i) => i.id === id) : undefined;
					if (found) {
						found.firstName = val[type][id]['firstName'];
						found.middleInitial = val[type][id]['middleInitial'];
						found.lastName = val[type][id]['lastName'];
						found.relationship = val[type][id]['relationshipToInsured'];
						individuals.push(found);
					} else{
						individuals.push(
							{
								id: id,
								firstName: val[type][id]['firstName'],
								middleInitial: val[type][id]['middleInitial'],
								lastName: val[type][id]['lastName'],
								relationship: val[type][id]['relationshipToInsured'],
							} as IIndividual
						);
					}
				});
				bData.individuals = individuals;
			}
		});

		setBeneficiaryData(bData);
	};

	const extractBeneficiaryAllocations = (val: any, isEqually: boolean) => {
		const filterKeys = Object.keys(val);
		const data = beneficiaryData;
		data.division = isEqually ? 'EQUAL_SHARES' : 'PER_ALLOCATION';

		filterKeys.forEach(type => {

			if(type === 'estate' && data.estate) {
				data.estate.allocation = val[type];
			} else {
				var foundIndex = -1;
				if(data.individuals !== undefined && data.individuals.length > 0) {
					foundIndex = data.individuals.findIndex(i => i.id === type);

					if (foundIndex !== -1) {
						data.individuals[foundIndex].allocation = val[type];
					}
				}

				if (foundIndex === -1 && data.corporations !== undefined && data.corporations.length > 0) {
					foundIndex = data.corporations.findIndex(i => i.id === type);

					if (foundIndex !== -1) {
						data.corporations[foundIndex].allocation = val[type];
					}
				}
			}
		});

		setBeneficiaryData(data);
	};

	const extractTrustee = (val: any) => {
		var trustee;
		if (val['designateTrustee'] === 'yes') {
			trustee = {
				firstName: val['firstName'],
				middleInitial: val['middleInitial'],
				lastName: val['lastName'],
				phoneNumber: val['phoneNumber'],
				email: val['email'],
				relationship: val['relationshipToInsured'],
				confirmedUnderstanding: val['iUnderstand']
			} as ITrustee;
		}

		const data = beneficiaryData;
		data.trustee = trustee;

		setBeneficiaryData(data);
	};

	return (
		<BeneficiaryDataContext.Provider value={{
			beneficiaryData,
			isUnder18
		}}>
			<BeneficiaryActionContext.Provider value={{
				setBeneficiaryData,
				setIsUnder18,
				extractBeneficiaryNames,
				extractBeneficiaryAllocations,
				extractTrustee,
				getIndividuals,
				getCorporations,
				getEstate
			}}>
				{props.children}
			</BeneficiaryActionContext.Provider>
		</BeneficiaryDataContext.Provider>
	);
};

export {
	BeneficiaryActionContext,
	BeneficiaryDataContext
};

export default BeneficiaryProvider;