import React from 'react';
import { ValidationMessages } from 'common/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { useFormContext, Controller } from 'react-hook-form';
import { IBeneficiariesProps } from './beneficiariesProps';
import { BeneficiaryDataContext } from '../BeneficiaryContext';

export const Estate: React.FC<IBeneficiariesProps> = ( props: IBeneficiariesProps ) => {
	const beneficaryDataConext = React.useContext(BeneficiaryDataContext);
	const { estate } = beneficaryDataConext.beneficiaryData;

	const methods = useFormContext();

	const { control, errors } = methods;
	return (
		<div className="m-3">
			<div className="form-group">
				<label htmlFor="estate.nameOfEstate">Name of Estate</label>
				<Controller
					name="estate.nameOfEstate"
					control={control}
					rules={{
						required: ValidationMessages.required,
					}}
					defaultValue={estate !== undefined ? estate.name : ''}
					render={({ onChange, value }) =>
						<input
							name="estate.nameOfEstate"
							onChange={onChange}
							value={value}
							className="form-control"
						/>
					}
				/>
				<ErrorMessage>{errors?.estate?.nameOfEstate?.message}</ErrorMessage>
			</div>
		</div>
	);
};
