import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import '../node_modules/antd/dist/antd.css';
import 'index.scss';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

// service workers need more work before using in an app. (ie notification of a new version)
// registerServiceWorker();
unregister();
