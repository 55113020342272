import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { ReactComponent as IconError } from 'assets/icons/icon-error.svg';

interface IProps {
	children?: string | ReactElement | ReactElement[];
	className?: string;
}

export const InfoMessage: React.FC<IProps> = ( props: IProps ) => {
	if ( !props.children ) {
		return <React.Fragment />;
	}
	return (
		<div className={classNames('alert alert-info d-flex', props.className)}>
			<IconError className="text-info mr-2 icon-error" />
			{typeof props.children === 'string' ? (
				<p className="mb-0">
					{props.children}
				</p>
			) : (
				<div>
					{props.children}
				</div>
			)}
		</div>
	);
};