import React from 'react';
import { MyPolicy, sortProducts } from './utils';
import { PolicyCard } from './PolicyCard';
import { FormContext } from 'quote/FormContext';
import { PolicyActionContext } from './PolicyContext';
import PolicyList from './PolicyList';

interface CustomerProps {
	availableProducts: OliverProductResponseDTO[];
	handleMenuClick: (e: any) => void;
	isDropdownVisible: boolean;
	setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
	products: OliverProductResponseDTO[] | undefined;
	selectedPolicies: MyPolicy[];
	debouncedRequest: any;
	passValueToDispatch: (data: any) => void;
	isSpouse?: boolean;
	spouseChildrenCI?: boolean;
	cpaChildrenCI?: boolean;
	spouseDependentLife?: boolean;
	cpaDependentLife?: boolean;
	loadPolicies?: () => void;
}

export const CustomerPolicyCard: React.FC<CustomerProps> = (props) => {
	const FormCtx = React.useContext(FormContext);
	const PolicyAction = React.useContext(PolicyActionContext);
	const summaryTitle = props.isSpouse
		? `${FormCtx.spouseData?.spouseFirstName || ''} ${FormCtx.spouseData?.spouseLastName || ''}`
		: `${FormCtx.initialUserData?.firstName || ''} ${FormCtx.initialUserData?.lastName || ''}`;

	const productOrder = [
		'Term Life',
		'Long Term Disability',
		'Critical Illness',
		'Accidental Death and Dismemberment',
		'Dependent Life',
		'Office Overhead'
	];

	const validProducts = props.availableProducts.filter(product => {
		const title = props.isSpouse ? PolicyAction.spouseProductTitle(product.id) : PolicyAction.productTitle(product.id);
		return title;
	});

	validProducts.sort((a, b) => {
		const aTitle = props.isSpouse ? PolicyAction.spouseProductTitle(a.id) : PolicyAction.productTitle(a.id);
		const bTitle = props.isSpouse ? PolicyAction.spouseProductTitle(b.id) : PolicyAction.productTitle(b.id);
		return productOrder.indexOf(aTitle) - productOrder.indexOf(bTitle);
	});
	return (
		<div className="border border-gray-400 rounded flex-fill position-relative">
			<h3 style={{ borderRadius: '.5rem .5rem 0 0' }} className="bg-gray-200 border-bottom p-3 mb-0">{summaryTitle}'s Policy</h3>
			{props.products && props.products.length > 0 && sortProducts(props.products).map((item) => {
				return <PolicyCard
					index={item.index!}
					product={item.product}
					settings={item.settings!}
					onClick={props.passValueToDispatch}
					key={`policy-card-${item.index}`}
					selectedPolicies={props.selectedPolicies}
					debouncedRequest={props.debouncedRequest}
					isSpouse={props.isSpouse}
					spouseChildrenCI={props.spouseChildrenCI}
					cpaChildrenCI={props.cpaChildrenCI}
					loadPolicies={props.loadPolicies}
				/>;
			})}
			<div className="p-3">
				<h4>Add Coverage</h4>
				{props.isSpouse ?
					<p>Add another product to your spouse’s policy until it’s just right for their needs.</p>
					:
					<p>Add another product to personalize your policy until it’s just right for your needs.</p>
				}
				<PolicyList
					policyList={validProducts}
					isSpouse={props.isSpouse}
					spouseDependentLife={props.spouseDependentLife}
					cpaDependentLife={props.cpaDependentLife}
					onClick={props.handleMenuClick} />
			</div>
		</div >
	);
};
