import React from 'react';
import IconHelpChat from 'assets/img/icon-help-chat.svg';
interface IProps {
}

export const HaveAQuestion: React.FC<IProps> = ( props: IProps ) => {

	return (
		<div className="shadow-sm rounded p-3">
			<div className="m-2 d-flex align-items-center flex-column">
				<img src={IconHelpChat} className="mb-2" alt="Talk to an expert" />
				<h4 className="mb-1">Have a question?</h4>
				<span className="d-block mb-3">Talk to an expert!</span>
				{/* <button className="btn btn-outline-primary mb-2 align-self-stretch liveweb-call-trigger">1:1 Video Chat</button>
				<button className="btn btn-outline-primary mb-2 align-self-stretch liveweb-talk-trigger">Live Chat</button> */}
				<a className="btn btn-outline-primary mb-2 align-self-stretch" href="https://www.cpaipw.ca/contact-us/" target="_blank" rel="noopener noreferrer">Contact us</a>
			</div>
		</div>
	);
};