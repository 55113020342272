import React from 'react';
import { RadioButtonGroup } from 'components/cpaipw/RadioButtonGroup';
import { FormContext, IDebts, INeedsAssessment } from 'quote/FormContext';
import { useForm, Controller } from 'react-hook-form';
import { AmountField, FormButtons, NeedsAssessmentSidebarCard, QuoteContainer, Steps, AmountInput } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { NavRoutes, QuotePages, ValidationMessages } from 'common/utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { ReactComponent as IconDebts } from 'assets/icons/icon-expenses.svg';

interface IProps {
	location: any;
}

const NeedsAssessmentStep3: React.FC<IProps> = (props: IProps, index: number) => {

	const FormCtx = React.useContext(FormContext);
	const history = useHistory();

	const { control, handleSubmit, watch, errors } = useForm({
		defaultValues: FormCtx.needsAssessment,
	});
	const onSubmit = (values: INeedsAssessment) => {
		if ( values.debts === undefined ) {
			values.debts = {};
		}
		debtsFields.forEach((item) => {
			if ( values.debts !== undefined && (values.debts[item.name] === undefined || values.debts[item.name] === '')) {
				values.debts[item.name] = '0';
			}
		});
		values.step = 3;
		if (!FormCtx.needsAssessmentOnly) {
			FormCtx.upsertFormValues(values);
		}
		FormCtx.setNeedsAssessment({...FormCtx.needsAssessment, ...values});
		history.push(NavRoutes.QuoteNeedsAssessmentStep4);
	};

	const watchHasMortgage = watch().hasMortgage;
	const watchHasDebts = watch().hasDebts;

	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.personalizePolicy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<QuoteContainer sidebar={<NeedsAssessmentSidebarCard />}>
			<form>

				<IconDebts className="mb-3" />
				<h3>Financial Standing – Debts and Expenses</h3>
				<Steps currentstep={2} totalSteps={3} />
				<p>As a general rule, in the case of a claim, you would want your coverage and savings to pay back all your debt and foreseeable expenses.</p>
				<div className="form-group">
					<label>Do you have a mortgage?</label>
					<Controller
						name="hasMortgage"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value, name }) =>
							<RadioButtonGroup
								name={name}
								onChange={onChange}
								value={value}
								options={[
									{
										label: 'Yes',
										value: 'yes',
									},
									{
										label: 'No',
										value: 'no',
									},
								]}
							/>
						}
					/>
					<ErrorMessage>{errors?.hasMortgage?.message}</ErrorMessage>
				</div>

				{watchHasMortgage === 'yes' && (
					<div className="form-group">
						<label>What is your current mortgage balance?</label>
						<AmountField
							prepend="$"
							item={{
								label: 'Balance',
							}}
						>
							<Controller
								name="debts.mortgageBalance"
								control={control}
								rules={{
									required: ValidationMessages.required,
									validate: val => isNaN(val) ? ValidationMessages.number : true,
								}}
								render={({ onChange, value, name }) =>
									<AmountInput
										onChange={onChange}
										value={value}
										name={name}
									/>
								}
							/>
						</AmountField>
						<ErrorMessage>{errors?.debts?.mortgageBalance?.message}</ErrorMessage>
					</div>
				)}

				<div className="form-group">
					<label>Do you have any current debts?</label>
					<p>Line of Credit, Credit Card, Student Loans, Auto Loans, etc.</p>
					<Controller
						name="hasDebts"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value, name }) =>
							<RadioButtonGroup
								name={name}
								onChange={onChange}
								value={value}
								options={[
									{
										label: 'Yes',
										value: 'yes',
									},
									{
										label: 'No',
										value: 'no',
									},
								]}
							/>
						}
					/>
					<ErrorMessage>{errors?.hasDebts?.message}</ErrorMessage>
				</div>
				{watchHasDebts === 'yes' && (
					<div className="form-group">
						<label className="mb-0">Your current debts</label>
						<span className="d-block mb-2 font-italic">(Specify amounts in CAD)</span>
						{debtsFields.filter(v => v.name !== 'mortgageBalance').map((item, index: number) => {
							return (
								<React.Fragment key={`${item.name}-${index}`}>
									<AmountField
										prepend="$"
										item={item}
										index={index}
									>
										<Controller
											name={`debts.${item.name}`}
											control={control}
											rules={{
												validate: val => ((val !== undefined && val !== null && val !== '') && isNaN(val)) ? ValidationMessages.number : true,
											}}
											render={({ onChange, value, name }) =>
												<AmountInput
													onChange={onChange}
													value={value}
													name={name}
												/>
											}
										/>
									</AmountField>
									<ErrorMessage>{errors?.debts?.[item.name]?.message}</ErrorMessage>
								</React.Fragment>
							);
						})}
					</div>
				)}
				<FormButtons
					onClickBack={() => history.push(NavRoutes.QuoteNeedsAssessmentStep2)}
					onClickNext={handleSubmit(onSubmit)}
				/>
			</form>
			<div className="d-block d-lg-none">
				<NeedsAssessmentSidebarCard />
			</div>
		</QuoteContainer>
	);
};

const debtsFields = [
	{
		label: 'Line of Credit',
		name: 'lineOfCredit',
	},
	{
		label: 'Credit Card',
		name: 'creditCard',
	},
	{
		label: 'Student Loans',
		name: 'studentLoans',
	},
	{
		label: 'Auto Loans',
		name: 'autoLoans',
	},
	{
		label: 'Other Debts',
		name: 'other',
		description: '(sum of all other additional debts not listed)',
	},
	{
		label: 'What is your current mortgage balance?',
		name: 'mortgageBalance',
	}
] as {
	label: string;
	name: keyof IDebts;
	description?: string;
}[];
export default NeedsAssessmentStep3;
