import { IChild } from 'application/AboutYouQuestions/ApplicationContext';
import React from 'react';
import { LifestyleQuestionaire, LifestyleSingleQuestion, LifestyleQuestionWithUserName } from './LifestyleTypes';

interface ILifestyleDataContext {
	lifestyleQuestions: LifestyleQuestionaire;
}

interface ILifestyleActionContext {
	setLifestyleQuestions: React.Dispatch<React.SetStateAction<LifestyleQuestionaire>>;
	extractLifestyleData: (vals: any, section: string, children: IChild[], lifestyleSection: string) => void;
	extractLifestyleDataSection6: (vals: any, children: IChild[]) => boolean;
	extractLifestyleDataSection1: (vals: any, section: string) => boolean;
}

const LifestyleActionContext = React.createContext<ILifestyleActionContext>({
	setLifestyleQuestions: () => null,
	extractLifestyleData: () => false,
	extractLifestyleDataSection6: () => false,
	extractLifestyleDataSection1: () => false,
});

const LifestyleDataContext = React.createContext<ILifestyleDataContext>({
	lifestyleQuestions: {} as LifestyleQuestionaire,
});

const LifestyleProvider = (props: any) => {
	const [lifestyleQuestions, setLifestyleQuestions] = React.useState<LifestyleQuestionaire>({});

	return (
		<LifestyleActionContext.Provider
			value={{
				setLifestyleQuestions,
				extractLifestyleDataSection6: extractSection6LifestyleData,
				extractLifestyleData: extractSimpleLifestyleData,
				extractLifestyleDataSection1,
			}}
		>
			<LifestyleDataContext.Provider
				value={{
					lifestyleQuestions,
				}}
			>
				{props.children}
			</LifestyleDataContext.Provider>
		</LifestyleActionContext.Provider>
	);

	function extractLifestyleDataSection1(vals: any, section: string) {
		let queriedLifestyleData = [] as LifestyleQuestionWithUserName[];
		if (vals === null || vals === undefined) {
			return false;
		}

		if (vals.notApplicable) {
			setLifestyleQuestions({ ...lifestyleQuestions, section1: [] });
			return true;
		}

		const userLifeStyleData = Object.keys(vals[section]);

		userLifeStyleData.forEach(key => {
			const lifestyleData = vals[section][key] as LifestyleQuestionaire;
			const data = {
				idOfApplicantOrChildren: key,
				data: lifestyleData!,
			} as LifestyleQuestionWithUserName;
			queriedLifestyleData.push(data);
		});

		setLifestyleQuestions({ ...lifestyleQuestions, section1: queriedLifestyleData });
		return true;
	}

	function extractSection6LifestyleData(vals: any) {
		let queriedLifestyleData = {};
		if (vals === null || vals === undefined) {
			return false;
		}

		if (vals.notApplicable) {
			setLifestyleQuestions({ ...lifestyleQuestions, section6: {} });
			return true;
		}

		const section6Questions = Object.keys(vals).filter(v => v !== 'notApplicable');
		section6Questions.forEach(question => {
			const questionDataList = [] as LifestyleConsumptionQuestionWithUserName[];
			const userQuestionDataKeys = Object.keys(vals[question]);
			userQuestionDataKeys.forEach(user => {
				const data = {
					idOfApplicantOrChildren: user,
					consumptionAmount: vals[question][user].amount,
				} as LifestyleConsumptionQuestionWithUserName;

				questionDataList.push(data);
				(queriedLifestyleData as any)[question] = questionDataList;
			});
		});

		setLifestyleQuestions({ ...lifestyleQuestions, section6: queriedLifestyleData });
		return true;
	}


	function extractSimpleLifestyleData(vals: any, section: string, children: IChild[], lifeStyleSection: string) {
		const data = { ...lifestyleQuestions };
		if (vals === null || vals === undefined) {
			return;
		}

		if (vals.notApplicable) {
			(data as any)[lifeStyleSection] = [];
			setLifestyleQuestions(data);
			return;
		}

		const lifestyleData = vals[section].filter((key: any) => key !== '' && key !== undefined);
		const userLifeStyleData = lifestyleData.map((sec: any) => {
			let data = {
				suspended: true,
				idOfApplicantOrChildren: sec,
				name: sec !== 'Applicant' ? getChildrenDetails(sec, children) : 'Applicant'
			} as LifestyleSingleQuestion;
			return data;

		});

		(data as any)[lifeStyleSection] = userLifeStyleData;

		setLifestyleQuestions(data);
	}

	function getChildrenDetails(id: string, childrenData?: IChild[]) {
		if (childrenData === undefined) { return undefined; }

		const foundChildData = childrenData.find(child => child.id === id) as IChild;
		if (foundChildData !== undefined) {
			return `${foundChildData.firstName} ${foundChildData.lastName}`;
		} else {
			return '';
		}

	};

};

export {
	LifestyleActionContext,
	LifestyleDataContext
};

export default LifestyleProvider;