import React from 'react';
import { RadioButtonGroup } from 'components/cpaipw';
import { RadioOption } from 'components/cpaipw/RadioButtonGroup';
import { IPolicyComponentProps } from './utils';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';

interface IProps extends IPolicyComponentProps {
	options: RadioOption[]
}

export const WaitingPeriodRadio: React.FC<IProps> = ( props: IProps ) => {
	return (
		<div className="form-group">
			<label className="font-weight-normal mb-1">Waiting period</label>
			<span className="d-block small mb-2">This is the time before benefits begin if disabled.</span>
			<RadioButtonGroup
				name="WaitingPeriod"
				onChange={props.onChange}
				options={props.options}
				value={props.value}
			/>
			<ErrorMessage>{props.error}</ErrorMessage>
		</div>
	);
};
