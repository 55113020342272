import { DayMonthYear } from 'application/HealthQuestions/HealthTypes';
import React from 'react';
import { RadioButtonGroup } from './RadioButtonGroup';

interface IProps {
	name: string;
	id?: string;
	value: string | DayMonthYear;
	onChange: (value: string | DayMonthYear) => void;
}


export const DayMonthYearInput: React.FC<IProps> = (props: IProps) => {
	const previousUnitValue = getUnitValue(props.value);
	const [unit, setUnit] = React.useState<string>(previousUnitValue);

	const units = [
		{
			label: 'Days',
			value: 'days'
		},
		{
			label: 'Months',
			value: 'months'
		},
		{
			label: 'Years',
			value: 'years'
		}
	];

	const getValue = ( val: string | DayMonthYear ) => {
		if ( typeof val === 'object' && val !== null ) {
			return val.value;
		} else {
			return val;
		};
 	};

	return (
		<React.Fragment>
			<input
				type="text"
				className="form-control mb-1"
				value={getValue(props.value)}
				name={props.name}
				id={props.id}
				onChange={(e) => onValueChange(e.target.value)}
			/>
			<RadioButtonGroup
				name="units"
				options={units}
				onChange={onUnitChange}
				value={unit}
				multipleBtns={true}
			/>
		</React.Fragment>
	);
	function onUnitChange(val: string) {
		setUnit(val);
		props.onChange({
			value: getValue(props.value),
			unit: val,
		});
	}
	function onValueChange(val: string) {
		if ( unit === '') {
			props.onChange(val);
		} else {
			props.onChange({
				value: val,
				unit: unit,
			});
		}
	};

	function getUnitValue(val: string | DayMonthYear){
		if ( typeof val === 'object' && val !== null ) {
			return val.unit;
		} else {
			return val;
		};
	}
};
