import React from 'react';
import { ApplicationPages, NavRoutes, preventDefault, ValidationMessages } from 'common/utils';
import { Divider, FormButtons, QuoteContainer, RadioButtonGroup, Steps } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { FormContext } from 'quote/FormContext';
import { Select } from 'components/cpaipw/Select';
import { provinces } from 'application/utils';
import { Controller, useForm } from 'react-hook-form';
import AnimateHeight from 'react-animate-height';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { IAboutYouApplication, ApplicationActionContext, ApplicationDataContext } from './ApplicationContext';
import { PolicyDataContext } from 'quote/policy/PolicyContext';
import { SubmissionContext } from 'application/SubmissionContext';

const AboutYouApplication2: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const policyCtx = React.useContext(PolicyDataContext);
	const showChildren = policyCtx.hasChildDL || policyCtx.hasChildCI;
	const submissionCtx = React.useContext(SubmissionContext);

	const appDataContext = React.useContext(ApplicationDataContext);
	const appActionContext = React.useContext(ApplicationActionContext);

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			isWorking: appDataContext.applicationData.isWorking || '',
			reasonForNotWorking: appDataContext.applicationData.reasonForNotWorking || '',
			occupation: appDataContext.applicationData.occupation || '',
			workPhone: appDataContext.applicationData.workPhone || '',
			workedHours: appDataContext.applicationData.workedHours || '',
			employer: {
				name: appDataContext.applicationData.employer?.name || '',
				address: appDataContext.applicationData.employer?.address || '',
				city: appDataContext.applicationData.employer?.city || '',
				province: appDataContext.applicationData.employer?.province || provinces[0].value,
				postalCode: appDataContext.applicationData.employer?.postalCode || '',
			}
		}
	});

	const { control, watch, handleSubmit, errors } = methods;

	const watchIsWorking = watch(['isWorking']);

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.aboutYou);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onValidSubmit = async ( data: IAboutYouApplication ) => {
		appActionContext.setApplicationData({
			...appDataContext.applicationData,
			...data
		});

		if(showChildren) {
			history.push(NavRoutes.ApplicationAboutYouStep3);
		} else {
			submissionCtx.setSubmitApplication(true);
			history.push(NavRoutes.ApplicationBeneficiariesStep1);
		}
	};

	return (
		<QuoteContainer>
			<h1>About you</h1>
			<Steps currentstep={2} totalSteps={showChildren ? 3 : 2} />
			<form onSubmit={preventDefault}>
				<div className="form-group">
					<label htmlFor="isWorking">Are you presently working?</label>
					<Controller
						name="isWorking"
						control={control}
						rules={{
							required: ValidationMessages.required,
						}}
						render={({ onChange, value }) =>
							<RadioButtonGroup
								name="isWorking"
								onChange={onChange}
								value={value}
								options={[
									{
										label: 'Yes',
										value: 'yes',
									},
									{
										label: 'No',
										value: 'no',
									},
								]}
							/>
						}
					/>
					<ErrorMessage>{errors?.isWorking?.message}</ErrorMessage>
				</div>
				<AnimateHeight duration={400} height={watchIsWorking.isWorking !== 'no' ? 0 : 'auto'}>
					{watchIsWorking.isWorking === 'no' && (
						<React.Fragment>
							<div className="form-group">
								<label htmlFor="reasonForNotWorking">Reason</label>
								<Controller
									name="reasonForNotWorking"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<textarea
											className="form-control"
											id="reasonForNotWorking"
											name="reasonForNotWorking"
											rows={3}
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.reasonForNotWorking?.message}</ErrorMessage>
							</div>
						</React.Fragment>
					)}
				</AnimateHeight>
				<AnimateHeight duration={400} height={watchIsWorking.isWorking !== 'yes' ? 0 : 'auto'}>


					{watchIsWorking.isWorking === 'yes' && (
						<React.Fragment>
							<Divider />
							<div className="form-group">
								<label htmlFor="occupation">Occupation</label>
								<Controller
									name="occupation"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id="occupation"
											name="occupation"
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.occupation?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="employerName">Name of Employer</label>
								<Controller
									name="employer.name"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id="employerName"
											name="employerName"
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.employer?.name?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="employerAddress">Address</label>
								<Controller
									name="employer.address"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id="employerAddress"
											name="employerAddress"
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.employer?.address?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="employerCity">City</label>
								<Controller
									name="employer.city"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id="employerCity"
											name="employerCity"
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.employer?.city?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="province">Province</label>
								<Controller
									name="employer.province"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<Select
											name="province"
											options={provinces}
											onChange={onChange}
											selected={value}
										/>
									}
								/>
								<ErrorMessage>{errors?.employer?.province?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="employerPostalCode">Postal Code</label>
								<Controller
									name="employer.postalCode"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id="employerPostalCode"
											name="employerPostalCode"
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.employer?.postalCode?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="workPhone">Work (Area Code + No.):</label>
								<Controller
									name="workPhone"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id="workPhone"
											name="workPhone"
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.workPhone?.message}</ErrorMessage>
							</div>
							<div className="form-group">
								<label htmlFor="workedHours">How many hours worked:</label>
								<Controller
									name="workedHours"
									control={control}
									rules={{
										required: ValidationMessages.required,
									}}
									render={({ onChange, value }) =>
										<input
											type="text"
											className="form-control"
											id="workedHours"
											name="workedHours"
											value={value}
											onChange={onChange}
										/>
									}
								/>
								<ErrorMessage>{errors?.workedHours?.message}</ErrorMessage>
							</div>
						</React.Fragment>
					)}
				</AnimateHeight>
				<FormButtons
					onClickBack={() => history.push(NavRoutes.ApplicationAboutYouStep1)}
					onClickNext={handleSubmit(onValidSubmit)}
				/>
			</form>
		</QuoteContainer>
	);
};

export default AboutYouApplication2;