import * as React from 'react';
import classNames from 'classnames';
import Logo from 'assets/img/cpaipw-2020-logo-colour.svg';
import IconLockShield from 'assets/icons/icon-lock-shield.svg';
import IconHelpChat from 'assets/icons/icon-help-chat.svg';
import IconClose from 'assets/icons/icon-close.svg';
import { HaveAQuestion } from 'components/cpaipw';
import { FormContext } from 'quote/FormContext';
import { ApplicationPages, NavRoutes, QuotePages } from 'common/utils';
import { useHistory } from 'react-router-dom';

interface IProps {
	className?: string;
	title?: string;
}

const PageHero: React.FC<IProps> = ( props: IProps ) => {
	const quoteMenuItems = [
		QuotePages.yourDetails,
		QuotePages.personalizePolicy,
		QuotePages.applyForCoverage,
	];
	const applicationsMenuItems = [
		ApplicationPages.aboutYou,
		ApplicationPages.beneficiaries,
		ApplicationPages.health,
		ApplicationPages.lifestyle,
		ApplicationPages.history,
		ApplicationPages.review,
	];
	const hideTabsPages: string[] = [
		NavRoutes.QuoteAboutYou,
		NavRoutes.QuoteSelectInsuranceType,
	];

	const [navOpen, setNavOpen] = React.useState<boolean>(false);
	const FormCtx = React.useContext(FormContext);
	const { currentPage } = FormCtx;

	const history = useHistory();
	const menuItems = history.location.pathname.includes('/application/') ? applicationsMenuItems : quoteMenuItems;

	React.useEffect(() => {
		if (navOpen) {
			document.body.classList.add('navbar-expanded');
		} else {
			setTimeout(() =>
				document.body.classList.remove('navbar-expanded'), 200
			);
		}
	}, [navOpen]);

	React.useEffect(() => {
		window.scrollTo({top: 0});
	}, [history.location.pathname]);

	return (
		<header className="navbar-wrapper position-sticky shadow-sm bg-white">
			<div className="container">
				<div className="navbar-expand-lg px-0 py-1 py-lg-2">
					<div className="d-flex flex-row justify-content-between align-items-center">
						<a href="https://www.cpaipw.ca/" target="_blank" rel="noopener noreferrer">
							<img src={Logo} alt="CPAIPW" className="navbar-brand-img" />
						</a>
						<div className="d-none d-lg-flex flex-row align-items-center">
							<img src={IconLockShield} alt="Your information is secure with us." className="mr-1" />
							<span>Your information is secure with us.</span>
						</div>
						<button onClick={() => setNavOpen(true)} className="navbar-toggler text-primary ml-auto" type="button">
							<img src={IconHelpChat} alt="get help" />
						</button>
					</div>
				</div>
			</div>
			{(!FormCtx.needsAssessmentOnly && !hideTabsPages.includes(window.location.pathname)) && (
				<nav className="container">
					<HeroTabs
						currentPage={currentPage}
						menuItems={menuItems}
					/>
				</nav>
			)}
			<div className={classNames('mobile-nav', navOpen && 'show')} id="navbar-menu">
				<div className="navbar-backdrop"></div>
				<div className="navbar-nav align-items-end">
					<div className="d-flex flex-fill flex-column navbar-mobile p-3">
						<div className="d-flex flex-row align-items-end justify-content-between mb-3">
							<img src={Logo} alt="CPAIPW" className="navbar-brand-img" />
							<button onClick={() => setNavOpen(false)} className="navbar-toggler text-primary ml-auto pr-0" type="button">
								<img src={IconClose} alt="get help" className="text-primary" />
							</button>
						</div>
						<HaveAQuestion />
					</div>
				</div>
			</div>
		</header>
	);
};

interface IHeroTabsProps {
	menuItems: string[];
	currentPage: string;
}

const HeroTabs: React.FC<IHeroTabsProps> = ( props: IHeroTabsProps ) => {

	return (
		<ul className="navbar-steps">
			{props.menuItems.map((item, index) => {
				return (
					<li className={classNames(item === props.currentPage && 'active', index <= props.menuItems.findIndex(val => val === props.currentPage) && 'underline')} key={`menu-item${index}`}>
						<span className="d-none d-lg-inline">{item}</span>
						<span className="d-inline d-lg-none">{index + 1}</span>
					</li>
				);
			})}
		</ul>
	);
};


export default PageHero;