import React from 'react';
import { Spinner } from 'components/common';

interface IProps {
	children: any;
	isLoading?: boolean;
}

export const LoadContainer: React.FC<IProps> = ( props: IProps ) => {
	if (props.isLoading) {
		return (
			<div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
				<Spinner className="spinner-large text-primary" />
			</div>
		);
	}
	return props.children;
};
