import { ApplicationPages, NavRoutes, preventDefault, ValidationMessages } from 'common/utils';
import { CheckboxButton, Divider, FormButtons, QuestionContainer, QuoteContainer, Steps } from 'components/cpaipw';
import { Accordion } from 'components/cpaipw/Accordion';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import { FormContext } from 'quote/FormContext';
import React from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { LifestyleActionContext, LifestyleDataContext } from './LifestyleContext';
import { LifestyleQuestionWithUserName } from './LifestyleTypes';

const LifestyleApplicationStep1: React.FC = () => {
	const history = useHistory();
	const FormCtx = React.useContext(FormContext);
	const lifestyleActionContext = React.useContext(LifestyleActionContext);
	const lifestyleDataContext = React.useContext(LifestyleDataContext);

	const lifestyleData = lifestyleDataContext.lifestyleQuestions.section1;

	const defaultselectedUsers = lifestyleData === undefined ? [] : lifestyleData.map((values: LifestyleQuestionWithUserName) => { return values.idOfApplicantOrChildren; });

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.lifestyle);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const methods = useForm({
		defaultValues: {
			section1: lifestyleData || [],
			notApplicable: lifestyleData !== undefined && lifestyleData.length === 0
		},
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});

	const { control, handleSubmit, errors, watch, setError } = methods;

	const watchNa = watch(['notApplicable']);

	const onValidSubmit = (val: any) => {
		const keys = Object.keys(val);
		if (keys.includes('notApplicable') && keys.length === 1 && val['notApplicable'] === false) {
			setError('notApplicable', { message: 'If none of the situations are applicable, please select "Not Applicable"' });
			return;
		}

		lifestyleActionContext.extractLifestyleDataSection1(val, 'section1');
		history.push(NavRoutes.ApplicationLifestyleStep2);
	};

	return (
		<FormProvider {...methods}>
			<QuoteContainer>
				<h1>Lifestyle Questions</h1>
				<Steps currentstep={1} totalSteps={6} />
				<form onSubmit={preventDefault}>
					<div className="d-flex flex-column mb-2">
						<QuestionContainer index={1}>
							<p>
								<b>In the last 10 years</b> has the proposed insured has an application for insurance declined or modified,
								or appoved with an exclusion or extra premium
							</p>
							<Accordion disabled={watchNa.notApplicable === true} defaultState={defaultselectedUsers}>
								<ReasonForm name="section1" defaultValues={lifestyleData} />
							</Accordion>
							<Divider />
						</QuestionContainer>
						<QuestionContainer index={2}>
							<Controller
								name="notApplicable"
								control={control}
								render={({ onChange, value }) =>
									<CheckboxButton
										name="notApplicable"
										isActive={value}
										// disabled={(lifestyleData !== undefined && lifestyleData.length > 0) || watch}
										label="Not Applicable"
										onClick={() => onChange(!value)}
									/>
								}
							/>
							<ErrorMessage>{errors?.notApplicable?.message}</ErrorMessage>
						</QuestionContainer>
					</div>
					<FormButtons
						onClickBack={() => history.push(NavRoutes.ApplicationHealthStep5)}
						onClickNext={handleSubmit(onValidSubmit)}
					/>
				</form>
			</QuoteContainer>
		</FormProvider>
	);
};


interface IReasonFormProps {
	name: string;
	user?: string;
	defaultValues?: LifestyleQuestionWithUserName[]
}

const ReasonForm: React.FC<IReasonFormProps> = (props: IReasonFormProps) => {
	const user = props.user || '';
	const methods = useFormContext();

	const { control, errors } = methods;

	const defaultValuesForUser = props.defaultValues !== undefined && props.defaultValues.length > 0 ?
		props.defaultValues.find(val => val.idOfApplicantOrChildren === props.user) : undefined;
	return (
		<div className="m-3">
			<div className="form-group">
				<label htmlFor="Reason">Reason</label>
				<Controller
					name={`${props.name}.${user}.reason`}
					control={control}
					rules={{
						required: ValidationMessages.required,
					}}
					defaultValue={defaultValuesForUser !== undefined ? defaultValuesForUser?.data?.reason : ''}
					render={({ onChange, value }) =>
						<input
							onChange={onChange}
							value={value}
							className="form-control"
						/>
					}
				/>
				<ErrorMessage>{errors?.[props.name]?.[user]?.reason?.message}</ErrorMessage>
			</div>
			<div className="form-group">
				<label htmlFor="dates">Dates</label>
				<Controller
					name={`${props.name}.${user}.dates`}
					control={control}
					rules={{
						required: ValidationMessages.required,
						// NOTE : this should not be validated as date!
					}}
					defaultValue={defaultValuesForUser?.data?.dates || ''}
					render={({ onChange, value }) =>
						<input
							onChange={onChange}
							value={value}
							className="form-control"
						/>
					}
				/>
				<ErrorMessage>{errors?.[props.name]?.[user]?.dates?.message}</ErrorMessage>
			</div>
		</div>

	);
};

export default LifestyleApplicationStep1;
