import React from 'react';
import { NumericFormat } from 'react-number-format';

interface IProps {
	name?: string;
	value?: number | string;
	onChange: (value: string) => void;
}

export const AmountInput: React.FC<IProps> = (props: IProps) => {
	const { name, onChange, value } = props;

	return (
		<NumericFormat
			thousandSeparator={true}
			name={name}
			onValueChange={({ value }) => {
				onChange(value);
			}}
			defaultValue={value}
			className="form-control"
			type="text"
		/>
	);
};